import React from "react";
import ProductInfo from "./ProductInfo";

const BuyBox = ({shopify, product, hero}) => {

    return (
        <>
        { (hero) ? (
            <section className="bg--section" id="order-now">
                <div className="container">
                    <ProductInfo popup={false} shopify={shopify} product={product} order="1"/>
                </div>
            </section>
        ) : (
            <section className="container border-bottom-1  border-light-gray">
                <ProductInfo popup={false} shopify={shopify} product={product} order="2"/>
            </section>
        )}
        </>
    )
}
export default BuyBox
