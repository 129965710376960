import React, {useEffect, useState} from "react";
import Slider from "../components/Slider"

const SamplePopup = ({open, setOpen, popupData} ) => {

    const [popup, setPopup] = useState(false)

    useEffect(() => {
        setPopup(true)
    })

    useEffect(() => {
        if (typeof window !== 'undefined' && open) {
            document.body.classList.add('overflow--hidden');
        }
    }, [open])

    const closePopup = () => {
        document.body.classList.remove('overflow--hidden');
        setPopup(false)
        setOpen(false)
    }

    return (
        <>
            { open ? (
                <span
                    role="button"
                    onKeyDown={closePopup}
                    onClick={closePopup}
                    tabIndex={0}
                    aria-label="Close Popup."
                    className="overlay"/>
            ) : ''}
            <div id="popup" className={`popup ${ popupData[0].video ? 'popup--lg' : '' }`} style={ open ? { display: 'block'} : { display: 'none'}} >
                <button onClick={closePopup} className="popup__close"><i className="fal fa-times"/></button>

                <div>

                    { popupData[0].video ? (
                        <div className="video video--16-9">
                            <iframe src={popupData[0].src} width={popupData[0].width} height={popupData[0].height}/>
                        </div>
                    ) : (
                        <Slider
                            modifierClass="sample-carousel"
                            showIndicators={false}
                            showStatus={false}
                            autoPlay={true}
                            showThumbs={false}
                        >
                            {
                                popupData.map((img) => (
                                    <img src={img.src} alt="" width={img.width} height={img.height} className="no-shadow radius-0"/>
                                ))
                            }
                        </Slider>
                    ) }

                </div>

            </div>
        </>

    )
}
export default SamplePopup
