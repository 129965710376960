import React, {useEffect, useState} from "react"
import Faqs from "../../components/Faqs";
import Tabs from "../../components/Tabs"
import {Helmet} from "react-helmet";
import AccordionItem from "../AccordionItem";

const WholeSleep = ({updateActiveTab}) => {
    const [activeTab, setActiveTab] = useState('about')

    const [activeImg, setActiveImg] = useState('sight')

    const [readMore, setReadMore] = useState(false)

    const handleActive = (el) => {
        setActiveTab(el);
    }

    useEffect(() => {
        setActiveTab(updateActiveTab)
    },[updateActiveTab])

    const handleActiveEl = (img) => {
        setActiveImg(img)
    }

    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Whole Sleep"/>
                <meta property="og:description" content="Support your body’s natural sleep cycle with Whole Sleep: to help you fall asleep, stay asleep, and wake up refreshed!"/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/WS-FB.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Whole Sleep." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Whole Sleep"/>
                <meta name="twitter:description" content="Support your body’s natural sleep cycle with Whole Sleep: to help you fall asleep, stay asleep, and wake up refreshed!"/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/WS-FB.jpg"/>
                <meta name="twitter:image:alt" content="Image of Whole Sleep." />
                <meta name="twitter:card" content="summary_large_image"/>
                <title>Whole Sleep</title>
            </Helmet>

            {/*tab menu*/}
            <section className="pt--70 pt--md-30 pb--0">

                <Tabs active={activeTab} handleActive={handleActive}/>

                {/*tab content*/}
                 <div className="tab-content">
                     {/*tab content about*/}
                    { (activeTab === "about") &&
                    <div id="about">
                        {/*Wake up Refreshed*/}
                        <section className="container section--padding-lg overflow--hidden">
                            <h2 className="mb--20 h2--supplements mb--md-20 mb--xxs-10">Wake up Refreshed</h2>
                            <h4 className="txt--center txt--purpleblue font--oswald txt--24 txt--xxs-20 mb--60 mb--md-30">Upgrade Your Sleep for a Strong Body, Healthy Mind and Rocking Life!!!</h4>

                            <div className="d-flex align-items-center flex-column flex-lg-row">
                                <img src="/images/supplements/whole-sleep/wake-up-refreshed-new.png" width="334" height="334" alt="Refreshed woman waking up." className="refreshed__img refreshed__img--left no-shadow radius-0" />

                                <div className="refreshed__content">
                                    <p className="p--supplements m--0"><span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep</strong></span> is a blend of herbs and organic compounds that support your body’s natural sleep cycle. <strong className="fw--bold">It will help you fall asleep</strong> - and stay asleep - so you wake up refreshed and energized, and ready to ROCK your day without the after effects commonly associated with sleeping pills.</p>
                                </div>

                                <img src="/images/supplements/whole-sleep/sleep-bottles.png" width="467" height="313" alt="Two bottles of whole sleep." className="refreshed__img refreshed__img--right no-shadow radius-0"/>
                            </div>
                        </section>

                        {/*Is Poor Quality Sleep Affecting You?*/}
                        <div className="bg--lightpurple">
                            <div className="container container--mxl">
                                <div className="bg--supplements-img-up py--60 py--md-30">
                                    <h2 className="h2--supplements txt--capitalize txt--center mb--60 mb--md-30"><strong className="fw--bold">Is poor quality sleep affecting you? <span className="txt--purpleblue d-inline-block">do you experience:</span></strong></h2>

                                     <div className="row m--0">
                                        <div className="col-md-6 col-lg-4">
                                            <ul className="checkmark--supplements checkmark--supplements-purple mb--15">
                                                <li>Lack of focus, energy and alertness</li>
                                                 <li>Slow recovery after excercising</li>
                                                 <li>Stubborn body fat, or struggle to put on lean muscle</li>
                                            </ul>
                                         </div>

                                         <div className="col-md-6 col-lg-4">
                                            <ul className="checkmark--supplements checkmark--supplements-purple mb--15">
                                                <li>Trouble falling asleep</li>
                                                <li>Trouble staying asleep</li>
                                                <li>Grogginess or regular tiredness during the daytime</li>
                                            </ul>
                                        </div>

                                        <div className="col-md-6 col-lg-4">
                                            <ul className="checkmark--supplements checkmark--supplements-purple mb--15">
                                                <li>Poor memory and recall</li>
                                                <li>Slower results - even when following a balanced diet and exercise plan</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Sometimes, our sleep suffers*/}
                        <section className="container section--padding-lg">
                             <h2 className="mb--50 h2--supplements mb--xxs-10">Sometimes, our <span className="txt--purpleblue"> sleep suffers because</span> <span className="d-inline-block"> of things like...</span></h2>

                            <div className="row align-items-center justify-content-center flex-wrap">
                                <div className="col-12 col-md-6 txt--right txt--sm-center mb--sm-20">
                                    <img src="/images/supplements/whole-sleep/sleep-suffers.jpg" width="470" height="395" alt="Image of woman unable to fall asleep." className="no-shadow radius-0"/>
                                </div>

                                 <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
                                     <ul className="ordered-purpleblue ordered-text">
                                         <li>Anxiety, stress or racing thoughts</li>
                                         <li>Overexposure to light, after dark</li>
                                        <li>Physical or Mental Overstimulation Close to Bedtime</li>
                                        <li>Aches and Pains</li>
                                        <li>Eating or drinking too close to Bedtime</li>
                                        <li>Irregular Bedtimes</li>
                                        <li>Noise or Disruption</li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        {/*Tune into your 5 senses*/}
                        <section className="bg--lightpurple section--padding-lg">
                            <div className="container">
                                <h2 className="mb--30 h2--supplements mb--md-20 mb--xxs-10">Tune into your <span className="txt--purpleblue">5 senses</span> to create better sleep, naturally.</h2>
                                <h6 className="h6--supplements txt--center mb--70 mb--xs-30i"><strong className="fw--medium">And when that’s not enough or simply not possible, <span className="txt--purpleblue">Whole Sleep</span> can help get you back on track with <br className="d-none d-lg-block"/>natural herbs and organic compounds that work WITH your body.</strong></h6>

                                <div className="row">
                                    <div className="col-sm-6 accordion__images">
                                        <img src="/images/supplements/whole-sleep/5-senses-sleep.jpg" className="no-shadow" width="570" height="570" alt="Sight." style={{ opacity: (activeImg === 'sight') ? 1 : 0 }}/>
                                        <img src="/images/supplements/whole-sleep/5-senses-sound.png" className="no-shadow" width="570" height="570" alt="Sound." style={{ opacity: (activeImg === 'sound') ? 1 : 0 }}/>
                                        <img src="/images/supplements/whole-sleep/5-senses-smell.png" className="no-shadow" width="570" height="570" alt="Smell." style={{ opacity: (activeImg === 'smell') ? 1 : 0 }}/>
                                        <img src="/images/supplements/whole-sleep/5-senses-touch.png" className="no-shadow" width="570" height="570" alt="Touch." style={{ opacity: (activeImg === 'touch') ? 1 : 0 }}/>
                                        <img src="/images/supplements/whole-sleep/5-senses-taste.png" className="no-shadow" width="570" height="570" alt="Taste." style={{ opacity: (activeImg === 'taste') ? 1 : 0 }}/>
                                    </div>

                                    <div className="col-md-6">

                                        <AccordionItem title="SIGHT" counter={false} icon="angle" open={true}  img="sight" handleActiveEl={handleActiveEl}>
                                            <p className="p--supplements">Our eyes and optic nerve directly impact our circadian rhythm, and are governed by the light cycle.</p>
                                            <p className="p--supplements">Prioritize natural light exposure on waking and limit artificial light exposure  (especially from TV’s, computers and phones) before bed.</p>
                                            <img src="/images/supplements/whole-sleep/5-senses-sleep.jpg" width="570" height="570" alt="Image of sight." className="d-block d-md-none mx--auto mt--20"/>
                                        </AccordionItem>

                                        <AccordionItem title="SOUND" counter={false} icon="angle" img="sound" handleActiveEl={handleActiveEl}>
                                            <p className="p--supplements">Noise disturbance can drastically impact the quality and depth of our sleep.</p>
                                            <p className="p--supplements">Use noise cancelling devices like fans, white noise machines, or earplugs to help reduce noise disruption.</p>
                                            <img src="/images/supplements/whole-sleep/5-senses-sound.png" width="570" height="570" alt="Image of sound." className="d-block d-md-none mx--auto mt--20"/>
                                        </AccordionItem>

                                        <AccordionItem title="SMELL" counter={false} icon="angle" img="smell" handleActiveEl={handleActiveEl}>
                                            <p className="p--supplements">Some smells are very calming and can help you relax and drift off to sleep like lavender, bergamot and clary sage. Try essential oils in a diffuser or scented candles.</p>
                                            <img src="/images/supplements/whole-sleep/5-senses-smell.png" width="570" height="570" alt="Image of smell." className="d-block d-md-none mx--auto mt--20"/>
                                        </AccordionItem>

                                        <AccordionItem title="TOUCH" counter={false} icon="angle" img="touch" handleActiveEl={handleActiveEl}>
                                            <p className="p--supplements">Creating a comfortable space with textures and a temperature that’s comfortable can help you fall asleep and stay asleep better. Try keeping your room cool, try out a weighted blanket, soothing fabrics, and consider using your own blanket if your partner steals your covers.</p>
                                            <img src="/images/supplements/whole-sleep/5-senses-touch.png" width="570" height="570" alt="Image of touch." className="d-block d-md-none mx--auto mt--20"/>
                                        </AccordionItem>

                                        <AccordionItem title="SMELL" counter={false} icon="angle" img="taste" handleActiveEl={handleActiveEl}>
                                            <p className="p--supplements">Avoid eating a few hours before bed and limit fluid consumption - especially caffeine and alcohol. Cut back on sugary foods and refined carbs for better digestion and sleep. Eating optimal amounts of protein can help reduce common cravings at bedtime.</p>
                                            <img src="/images/supplements/whole-sleep/5-senses-taste.png" width="570" height="570" alt="Image of taste." className="d-block d-md-none mx--auto mt--20"/>
                                        </AccordionItem>

                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*Whole Sleep Contains 9 Herbs*/}
                        <section className="container section--padding-lg section-organic">

                                <h2 className="mb--80 mb--xs-30i h2--supplements mb--xxs-10"><span className="txt--purpleblue">Whole Sleep</span> Contains 9 Herbs and Organic Compounds to<br className="d-none d-md-block"/> Support Your Body’s Natural Sleep Cycle</h2>

                                <div className="row flex-wrap justify-content-center mb--20 mb--xs-0">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-valerian-root"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Valerian Root</strong></h6>
                                            <p className="txt--14 txt--xs-16">Known as nature’s sleep aid. Improves response to physical and psychological stress by maintaining levels of serotonin, a brain chemical involved in mood regulation.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-passion-flower"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Passion Flower</strong></h6>
                                            <p className="txt--14 txt--xs-16">Passion Flower has been shown to improve sleep quality and reduce stress and anxiety. It can be a great alternative for hormone therapy in menopause and perimenopause.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-lemon-balm"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Lemon Balm</strong></h6>
                                            <p className="txt--14 txt--xs-16">A perennial herb from the mint family. Contains a compound with potent antioxidant and antimicrobial properties. Improves mood, cognitive performance, sleep and restlessness.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-chamomile"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Chamomile</strong></h6>
                                            <p className="txt--14 txt--xs-16">A natural relaxant that promotes sleepiness and reduces insomnia. It has also been shown to promote restful sleep and reduce night time awakening.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row--flex">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-b6"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">B6</strong></h6>
                                            <p className="txt--14 txt--xs-16">Has a calming effect on the body, reducing anxiety. When scarce, the body preferentially uses B6 for metabolic functions, but it’s also needed for mood regulation. It helps the body convert tryptophan into serotonin, which assists in production of Melatonin - essential for feeling sleepy and falling asleep.</p>
                                        </div>
                                    </div>

                                     <div className="col--custom txt--center">
                                        <div className="mb--20 organic-5-htp"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">5 HTP</strong></h6>
                                            <p className="txt--14 txt--xs-16">An amino acid your body uses to produce serotonin (low levels are associated with depression, anxiety, sleep disorders and more), which can be converted into the hormone melatonin. It may improve sleep quality and increase sleep time and quality. It makes you happy and relaxed.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-l-theanine"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">L-Theanine</strong></h6>
                                            <p className="txt--14 txt--xs-16">Found naturally in green tea, black tea, and certain types of mushrooms. It can affect the levels of certain chemicals in the brain, including serotonin and dopamine, which influence mood, sleep, emotion, and cortisol, helping the body deal with stress.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-melatonin"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Melatonin</strong></h6>
                                            <p className="txt--14 txt--xs-16">Melatonin is a hormone produced naturally by the pineal gland in your brain that regulates when you get tired, and helps improve sleep time and quality. It should rise in the evening, but factors like light exposure, stress and erratic schedule can disrupt it - so a small amount can help reset you and help you fall - and stay asleep.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-gaba"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Gaba</strong></h6>
                                            <p className="txt--14 txt--xs-16">GABA (gamma-aminobutyric acid) is a chemical messenger (neurotransmitter) that helps regulate nerve impulses in your brain and nervous system. By inhibiting neural activity, GABA facilitates sleep, reduces mental and physical stress, lowers anxiety, and creates a calm state.</p>
                                        </div>
                                    </div>
                                </div>
                        </section>

                        {/*bonus*/}
                         <div className="bg--supplements-bgp-paint">
                             <div className="container">
                                 <div className="bg--moon py--30">
                                     <div className="row m--0 flex-wrap align-items-center justify-content-center">
                                        <div className="col-md-6 col-lg-5 col-xl-4">
                                            <h2><strong className="fw--bold">bonus!</strong></h2>
                                            <h3><strong className="fw--bold">Better Sleep Guid</strong>e</h3>
                                            <h4>Included with your first order!</h4>
                                            <h5 className="mb--30"><strong className="fw--medium">Upgrade Your Sleep for a strong body,</strong><br /> <span>healthy mind and rocking life!</span></h5>
                                        </div>

                                        <div className="col-md-6 col-lg-4 txt--center">
                                            <img src="/images/supplements/whole-sleep/better-sleep-guide.png" alt="Bonus Image" className="no-shadow radius-0" width="277" height="335"/>
                                        </div>
                                     </div>
                                 </div>
                            </div>
                        </div>

                        {/*more about whole sleep*/}
                        <section className="container section--padding-lg overflow--hidden">
                            <h2 className="h2--supplements txt--left txt--center-md mb--30">More About <span className="txt--purpleblue">Whole Sleep</span></h2>
                            <img src="/images/supplements/whole-sleep/whole-sleep-bottle.png" width="584" height="415" alt="Whole Sleep Bottle." className="img--right img--behind no-shadow radius-0 mb--xs-20"/>

                             <p className={`toggeable-text p--supplements ${ !readMore ? 'toggeable-text--hidden' : '' }`}>
                                <span className="d-block mb--30 mb--xs-15"><span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep</strong></span> contains valerian root (Valeriana officinalis), <i>passion flower</i> (Passiflora incarnata), and <i>lemon balm</i> (Melissa officinalis), all safe nervine botanicals known for their relaxant properties and ability to reduce tension and promote sleep, without causing morning grogginess.</span>
                                <span className="d-block mb--30 mb--xs-15"><i>Valerian root</i> has demonstrated sedative effects due to its ability to induce the release of GABA from brain tissue. It has been suggested that the <i>passion flower</i> and <i>German chamomile</i> (Matricaria recubita) component apigenin binds to central benzodiazepine receptors, possibly causing anxiety reducing effects without impairing memory, diminishing motor skills, or causing drowsiness upon awakening. </span>
                                <span className="d-block mb--30 mb--xs-15"><i>Lemon balm</i> has been suggested to improve calmness via the inhibitory action of GABA, similar to benzodiazepine, but without the overt side-effects of these medications. Direct stimulation of Gamma aminobutyric acid (GABA), the main calming neurotransmitter in the body, is provided with a new form of GABA known as <i>PharmaGABA</i>, a proprietary material naturally manufactured via a fermentation process and considered more effective than other traditional, chemically produced synthetic forms. In addition, support of calming neurotransmitter production is provided with the inclusion of <i>L-theanine</i>, clinically proven to reduce stress and improve the quality of sleep.</span>
                                <span className="d-block mb--30 mb--xs-15"><span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep</strong></span> also includes <i>melatonin</i>, a multifunctional hormone whose main role lies in its involvement in the control of the circadian (day/night) biological rhythms. Melatonin mediates the body's response to variations of natural light availability from indoor/outdoor exposure or due to seasons (winter/summer). Darkness into the eye tells the brain to make melatonin so the body can prepare for sleep mode. Its production should peak at night and it is instrumental for maintaining quality sleep patterns. Melatonin production declines significantly with age, often causing sleep difficulties associated with aging. Supplemental melatonin was shown in studies to help with falling asleep when taken about 30 minutes before the desired sleep time. The most common dose found effective for sleep was 3mg - which is exactly what we've included in <span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep.</strong></span></span>
                                <span className="d-block"><i>5-HTP</i> can be used in conjunction with melatonin, as a precursor to serotonin, which can further support endogenous melatonin production during the night to help with staying asleep. When using 5-HTP for enhancing serotonin and melatonin production to promote healthy mood and sleep, the addition of the synergistic nutrient pyridoxal-5-phosphate, an activated form of vitamin B6, is suggested to catalyze the conversion of 5-HTP to serotonin (5-HT).</span>
                            </p>

                            <button onClick={() => setReadMore(!readMore)} className="btn btn--md btn--sm-center btn--purpleblue btn-hide txt--uppercase"><strong className="fw--bold">{ !readMore ? 'read-more' : 'read less' }</strong></button>
                        </section>

                        {/*facts*/}
                        <section className="bg--supplements-section py--30">
                            <div className="container">
                                <img src="/images/supplements/whole-sleep/supplements-facts-sleep.png" className="no-shadow radius-0 img--right mt--30 ml--20 mb--30 d-none d-md-block" alt="Supplements table." width="373" height="525" />
                                <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Suggested Dosage:</strong></span><br />Take 2 capsules, 30 to 60 minutes prior to bedtime.</p>
                                <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Who should take Whole Sleep:</strong></span><br />Whole Sleep is ideal for those experiencing difficulty getting to sleep, or staying asleep through the night.</p>
                                <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Caution with GABA:</strong></span><br />Due to the inclusion of GABA, this product is not recommended for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</p>
                                <p className="p--supplements mb--0"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Conditions for which melatonin is not recommended:</strong></span></p>
                                <ul className="checkmark--supplements-disc mb--15">
                                    <li>Autoimmune conditions such as lupus or arthritis, because the immune stimulatory effect of melatonin may exacerbate the action of certain types of lymphocytes or B-cells involved in the pathogenic course of these diseases</li>
                                    <li>Immune-related cancers such as lymphoma and leukemia</li>
                                    <li>Pregnancy, lactation or during the time where fertility is desired</li>
                                </ul>
                                <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right"/><strong className="fw--bold">Interactions with drug therapy:</strong></span><br />Melatonin and 5-HTP may not be suitable to administer along with SSRI and MAOI medications and corticosteroid therapy. Please discuss this with your health care provider before using Whole Sleep with these medication classes.</p>
                                <img src="/images/supplements/whole-sleep/supplements-facts-sleep.png" className="no-shadow radius-0 img--right mt--20 d-block d-md-none" alt="Supplements table." width="373" height="525" />
                            </div>
                        </section>

                        {/*quality support*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--40" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="img--lg no-shadow radius-0 mb--20" width="800" height="132"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>
                    </div>}

                    {/*tab content nutrition*/}
                    { (activeTab === "nutrition") &&
                    <div id="nutrition">
                        {/*facts*/}
                        <section className="container section--padding-lg">

                                <div className="row">

                                    <div className="col-xs-12 col-lg-5 txt--center">
                                        <img src="/images/supplements/whole-sleep/nutrition-table-sleep.jpg" className="no-shadow radius-0 mb--50 mb--xs-20 supplements--border supplements--border-purpleblue" alt="Supplements table" width="462" height="656"/>
                                        <div className="note note--purpleblue note--sm mb--20">
                                            <p className="p--supplements"><strong className="fw--bold"><span className="txt--purpleblue">Recommended Use:</span></strong> As a dietary supplement, take two capsules per day 30-60 minutes before bedtime.</p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-lg-7 accordion-counter-reset">

                                        <AccordionItem title="2 Capsules; 30 Servings" counter={true} icon="plus" modifierClasses="accordion--gray" open={true}>
                                            <p className="p--supplements">With 30 servings per bottle, you’ll have enough to support an entire month of quality sleep.</p>
                                            <p className="p--supplements">We offer Whole Sleep in capsule form because they are much easier for the body to absorb and digest than tablets, which often pass through the system whole. We carefully formulate these capsules using natural, plant-based binding and flowing agents (see “other ingredients”) to ensure you get the exact dose of each ingredient in every serving.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Daily Value of B6" counter={true} icon="plus" modifierClasses="accordion--gray">
                                            <p className="p--supplements">One of the interesting things about Vitamin B6 is how the body uses. it. B6 is needed for many important metabolic functions, and only once it's been distributed to the body for them is it also shared in mood function. Because of its function as a catalyst in converting tryptophan to melatonin, as well as its regulatory affect on mood in reducing anxiety and promoting calm.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Other Ingredients" counter={true} icon="plus" modifierClasses="accordion--gray">
                                            <p className="p--supplements"><strong className="fw--bold">Cellulose</strong> is the basic structural component of plant cell walls and is an insoluble fiber that supports gut health. We use it to encapsulate the ingredients as it provides an even release of the ingredients once ingested.</p>
                                            <p className="p--supplements"><strong className="fw--bold">Vegetable stearate</strong> is an 18 carbon long saturated fat, common in our food supply. It is readily metabolized to oleic acid (the monounsaturated fat found in olive oil). Stearic acid is unique among all saturated fatty acids because it does not raise LDL cholesterol levels. Thanks to its natural lubrication properties, we use it in tiny amounts to help all the ingredients flow into the capsule and not stick, bind or clump - which could affect how well your body would absorb them.</p>
                                            <p className="p--supplements"><strong className="fw--bold">Silicon dioxide</strong>  is a natural compound made of two of the earth’s most abundant materials: silicon (Si) and oxygen (O2). It’s found naturally in water, plants (such as leafy green vegetables, oats, brown rice to name a few), animals, the earth and in the tissues of the human body. Though it’s unclear what role it plays, it’s thought to be an essential nutrient our bodies need. We use it to prevent “caking” or sticking of the ingredients in their powdered form.</p>
                                        </AccordionItem>

                                    </div>
                                </div>
                        </section>

                        {/*more about whole sleep*/}
                        <section className="container section--padding-lg overflow--hidden section-border--top">
                            <h2 className="h2--supplements txt--left txt--center-md mb--30">More About <span className="txt--purpleblue">Whole Sleep</span></h2>
                            <img src="/images/supplements/whole-sleep/whole-sleep-bottle.png" width="584" height="415" alt="Whole Sleep Bottle." className="img--right img--behind no-shadow radius-0 mb--xs-20"/>

                            <p className={`toggeable-text p--supplements ${ !readMore ? 'toggeable-text--hidden' : '' }`}>
                                <span className="d-block mb--30 mb--xs-15"><span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep</strong></span> contains valerian root (Valeriana officinalis), <i>passion flower</i> (Passiflora incarnata), and <i>lemon balm</i> (Melissa officinalis), all safe nervine botanicals known for their relaxant properties and ability to reduce tension and promote sleep, without causing morning grogginess.</span>
                                <span className="d-block mb--30 mb--xs-15"><i>Valerian root</i> has demonstrated sedative effects due to its ability to induce the release of GABA from brain tissue. It has been suggested that the <i>passion flower</i> and <i>German chamomile</i> (Matricaria recubita) component apigenin binds to central benzodiazepine receptors, possibly causing anxiety reducing effects without impairing memory, diminishing motor skills, or causing drowsiness upon awakening. </span>
                                <span className="d-block mb--30 mb--xs-15"><i>Lemon balm</i> has been suggested to improve calmness via the inhibitory action of GABA, similar to benzodiazepine, but without the overt side-effects of these medications. Direct stimulation of Gamma aminobutyric acid (GABA), the main calming neurotransmitter in the body, is provided with a new form of GABA known as <i>PharmaGABA</i>, a proprietary material naturally manufactured via a fermentation process and considered more effective than other traditional, chemically produced synthetic forms. In addition, support of calming neurotransmitter production is provided with the inclusion of <i>L-theanine</i>, clinically proven to reduce stress and improve the quality of sleep.</span>
                                <span className="d-block mb--30 mb--xs-15"><span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep</strong></span> also includes <i>melatonin</i>, a multifunctional hormone whose main role lies in its involvement in the control of the circadian (day/night) biological rhythms. Melatonin mediates the body's response to variations of natural light availability from indoor/outdoor exposure or due to seasons (winter/summer). Darkness into the eye tells the brain to make melatonin so the body can prepare for sleep mode. Its production should peak at night and it is instrumental for maintaining quality sleep patterns. Melatonin production declines significantly with age, often causing sleep difficulties associated with aging. Supplemental melatonin was shown in studies to help with falling asleep when taken about 30 minutes before the desired sleep time. The most common dose found effective for sleep was 3mg - which is exactly what we've included in <span className="txt--purpleblue"><strong className="fw--bold">Whole Sleep.</strong></span></span>
                                <span className="d-block"><i>5-HTP</i> can be used in conjunction with melatonin, as a precursor to serotonin, which can further support endogenous melatonin production during the night to help with staying asleep. When using 5-HTP for enhancing serotonin and melatonin production to promote healthy mood and sleep, the addition of the synergistic nutrient pyridoxal-5-phosphate, an activated form of vitamin B6, is suggested to catalyze the conversion of 5-HTP to serotonin (5-HT).</span>
                            </p>

                            <button onClick={()=>setReadMore(!readMore)} className="btn btn--md btn--sm-center btn--purpleblue btn-hide txt--uppercase js-read"><strong className="fw--bold">{ !readMore ? 'read-more' : 'read less' }</strong></button>
                        </section>

                        {/*dosage*/}
                        <section className="bg--supplements-section py--30">
                            <div className="container">
                                <div className="row row-sm-reverse">
                                    <div className="col-12 col-md-4">
                                        <img src="/images/supplements/whole-sleep/sleep-dosage-bottle.jpg" className="no-shadow supplements--border supplements--border-purpleblue" alt="Whole sleep dosage." width="365" height="517" />
                                    </div>

                                    <div className="col-12 col-md-8">
                                        <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Suggested Dosage:</strong></span><br />Take 2 capsules, 30 to 60 minutes prior to bedtime.</p>
                                        <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Who should take Whole Sleep:</strong></span><br />Whole Sleep is ideal for those experiencing difficulty getting to sleep, or staying asleep through the night.</p>
                                        <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Caution with GABA:</strong></span><br />Due to the inclusion of GABA, this product is not recommended for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</p>
                                        <p className="p--supplements mb--0"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right fw--regular"/><strong className="fw--bold">Conditions for which melatonin is not recommended:</strong></span></p>
                                        <ul className="checkmark--supplements-disc mb--15">
                                            <li>Autoimmune conditions such as lupus or arthritis, because the immune stimulatory effect of melatonin may exacerbate the action of certain types of lymphocytes or B-cells involved in the pathogenic course of these diseases</li>
                                            <li>Immune-related cancers such as lymphoma and leukemia</li>
                                            <li>Pregnancy, lactation or during the time where fertility is desired</li>
                                        </ul>
                                        <p className="p--supplements mb--15"><span className="txt--purpleblue font--oswald"><i className="d-inline-block mr--10 fa fa-angle-double-right"/><strong className="fw--bold">Interactions with drug therapy:</strong></span><br />Melatonin and 5-HTP may not be suitable to administer along with SSRI and MAOI medications and corticosteroid therapy. Please discuss this with your health care provider before using Whole Sleep with these medication classes.</p>
                                    </div>
                                </div>

                            </div>
                        </section>

                        {/*Whole Sleep Contains 9 Herbs*/}
                        <section className="container section--padding-lg section-organic">

                                <h2 className="mb--80 mb--xs-30i h2--supplements mb--xxs-10"><span className="txt--purpleblue">Whole Sleep</span> Contains 9 Herbs and Organic Compounds to<br className="d-none d-md-block"/> Support Your Body’s Natural Sleep Cycle</h2>

                                <div className="row flex-wrap justify-content-center mb--20 mb--xs-0">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-valerian-root"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Valerian Root</strong></h6>
                                            <p className="txt--14 txt--xs-16">Known as nature’s sleep aid. Improves response to physical and psychological stress by maintaining levels of serotonin, a brain chemical involved in mood regulation.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-passion-flower"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Passion Flower</strong></h6>
                                            <p className="txt--14 txt--xs-16">Passion Flower has been shown to improve sleep quality and reduce stress and anxiety. It can be a great alternative for hormone therapy in menopause and perimenopause.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-lemon-balm"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Lemon Balm</strong></h6>
                                            <p className="txt--14 txt--xs-16">A perennial herb from the mint family. Contains a compound with potent antioxidant and antimicrobial properties. Improves mood, cognitive performance, sleep and restlessness.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-chamomile"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Chamomile</strong></h6>
                                            <p className="txt--14 txt--xs-16">A natural relaxant that promotes sleepiness and reduces insomnia. It has also been shown to promote restful sleep and reduce night time awakening.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row--flex">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-b6"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">B6</strong></h6>
                                            <p className="txt--14 txt--xs-16">Has a calming effect on the body, reducing anxiety. When scarce, the body preferentially uses B6 for metabolic functions, but it’s also needed for mood regulation. It helps the body convert tryptophan into serotonin, which assists in production of Melatonin - essential for feeling sleepy and falling asleep.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-5-htp"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">5 HTP</strong></h6>
                                            <p className="txt--14 txt--xs-16">An amino acid your body uses to produce serotonin (low levels are associated with depression, anxiety, sleep disorders and more), which can be converted into the hormone melatonin. It may improve sleep quality and increase sleep time and quality. It makes you happy and relaxed.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-l-theanine"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">L-Theanine</strong></h6>
                                            <p className="txt--14 txt--xs-16">Found naturally in green tea, black tea, and certain types of mushrooms. It can affect the levels of certain chemicals in the brain, including serotonin and dopamine, which influence mood, sleep, emotion, and cortisol, helping the body deal with stress.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-melatonin"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Melatonin</strong></h6>
                                            <p className="txt--14 txt--xs-16">Melatonin is a hormone produced naturally by the pineal gland in your brain that regulates when you get tired, and helps improve sleep time and quality. It should rise in the evening, but factors like light exposure, stress and erratic schedule can disrupt it - so a small amount can help reset you and help you fall - and stay asleep.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 organic-gaba"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--purpleblue"><strong className="fw--regular">Gaba</strong></h6>
                                            <p className="txt--14 txt--xs-16">GABA (gamma-aminobutyric acid) is a chemical messenger (neurotransmitter) that helps regulate nerve impulses in your brain and nervous system. By inhibiting neural activity, GABA facilitates sleep, reduces mental and physical stress, lowers anxiety, and creates a calm state.</p>
                                        </div>
                                    </div>
                                </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint">
                            <div className="container">
                                <div className="bg--moon py--30">
                                    <div className="row flex-wrap align-items-center justify-content-center">
                                        <div className="col-md-6 col-lg-5 col-xl-4">
                                            <h2><strong className="fw--bold">bonus!</strong></h2>
                                            <h3><strong className="fw--bold">Better Sleep Guid</strong>e</h3>
                                            <h4>Included with your first order!</h4>
                                            <h5 className="mb--30"><strong className="fw--medium">Upgrade Your Sleep for a strong body,</strong><br /> <span>healthy mind and rocking life!</span></h5>
                                        </div>

                                        <div className="col-md-6 col-lg-4 txt--center">
                                            <img src="/images/supplements/whole-sleep/better-sleep-guide.png" alt="Bonus Image" className="no-shadow radius-0" width="277" height="335"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*quality support*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--40" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                         <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="img--lg no-shadow radius-0 mb--20" width="800" height="132"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>
                    </div>}

                    {/*tab content whybuy*/}
                    { (activeTab === "quality") &&
                    <div id="quality">
                        {/*quality support*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--40" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="img--lg no-shadow radius-0 mb--20" width="800" height="132"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint">
                            <div className="container">
                                <div className="bg--moon py--30">
                                    <div className="row flex-wrap align-items-center justify-content-center">
                                        <div className="col-md-6 col-lg-5 col-xl-4">
                                            <h2><strong className="fw--bold">bonus!</strong></h2>
                                            <h3><strong className="fw--bold">Better Sleep Guid</strong>e</h3>
                                            <h4>Included with your first order!</h4>
                                            <h5 className="mb--30"><strong className="fw--medium">Upgrade Your Sleep for a strong body,</strong><br /> <span>healthy mind and rocking life!</span></h5>
                                        </div>

                                        <div className="col-md-6 col-lg-4 txt--center">
                                            <img src="/images/supplements/whole-sleep/better-sleep-guide.png" alt="Bonus Image" className="no-shadow radius-0" width="277" height="335"/>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>

                    </div>}

                    {/*tab content faq*/}
                    { (activeTab === "faq") &&
                    <div id="faq">
                        {/*faq*/}
                        <section className="section--padding-xl bg--supplements-section" id="faq">
                            <div className="container container--xs">
                                <h2 className="h2--supplements mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                                <Faqs productName='ws'/>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint">
                            <div className="container">
                                <div className="bg--moon py--30">
                                    <div className="row flex-wrap align-items-center justify-content-center">
                                        <div className="col-md-6 col-lg-5 col-xl-4">
                                            <h2><strong className="fw--bold">bonus!</strong></h2>
                                            <h3><strong className="fw--bold">Better Sleep Guid</strong>e</h3>
                                            <h4>Included with your first order!</h4>
                                            <h5 className="mb--30"><strong className="fw--medium">Upgrade Your Sleep for a strong body,</strong><br /> <span>healthy mind and rocking life!</span></h5>
                                        </div>

                                        <div className="col-md-6 col-lg-4 txt--center">
                                            <img src="/images/supplements/whole-sleep/better-sleep-guide.png" alt="Bonus Image" className="no-shadow radius-0" width="277" height="335"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>}
                </div>
            </section>

            {/*what our rockstars are saying*/}
            <section className="bg--supplements-section section--padding-xl">
                <div className="container">
                    <h2 className="mb--80 mb--md-20 h2--supplements">What Our Rockstars are Saying About<br className="d-none d-md-block"/> <span className="d-inline-block txt--purpleblue">Whole Sleep...</span></h2>

                    <div className="row">

                        <div className="col-xs-12 col-md-6 mb--40 mb--xs-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Amy H." src="https://player.vimeo.com/video/396799245" frameBorder="0"/>
                            </div>

                            <div className="quote quote--purple">
                                <p><i>“The biggest thing I've noticed is the difference Whole Sleep is making in my sleep temperature.”</i><span className="d-block">- <strong className="fw--bold">Amy H.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--40 mb--xs-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Shannon H." src="https://player.vimeo.com/video/396799280" frameBorder="0"/>
                            </div>

                            <div className="quote quote--purple">
                                <p><i>“I'm now getting 8 hours of uninterrupted sleep.”</i><span className="d-block">- <strong className="fw--bold">Shannon H.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-12 mb--40 mb--xs-0">
                            <div className="row">
                                <div className="col-md-3 txt--right txt--center-md mb--20">
                                    <img src="/images/supplements/whole-sleep/liz.png" width="170" height="170" alt="Image of Liz." className="no-shadow radius-0" />
                                </div>

                                <div className="col-md-8">
                                    <div className="quote quote--purple">
                                        <p className="m--0"><i>"Before I started Whole Sleep I had a brain that wandered FAR at night, battled insomnia, had problems with waking and getting back to sleep, and only slept 4-7 hours.</i></p>
                                        <p className="m--0"><i>I noticed an immediate difference. From night one to day one, I felt so much better in how I was sleeping through the night, and how I felt the next day. My body feels different, my sleep feels different and overall I feel better! I plan to continue to use this product in the future and would recommend it to anyone who is having any questions about their sleep. "</i></p>
                                        <span className="d-block txt--16">- <strong>Liz H.</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb--40 mb--xs-0">
                            <div className="row">
                                <div className="col-md-3 txt--right txt--center-md mb--20">
                                    <img src="/images/supplements/whole-sleep/marci.png" width="170" height="170" alt="Image of Marci." className="no-shadow radius-0" />
                                </div>

                                <div className="col-md-8">
                                    <div className="quote quote--purple">
                                        <p className="m--0"><i>"I would definitely recommend the product. I find that when I get up I am more wide awake right away, ready to start the day.</i></p>
                                        <p className="m--0"><i>I feel like I really don’t need coffee right away anymore. I feel like I wake up less in the night and I sleep more deeply."</i></p>
                                        <span className="d-block txt--16">- <strong>Marci K.</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--40 mb--xs-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Sherri J." src="https://player.vimeo.com/video/396799297" frameBorder="0"/>
                            </div>

                            <div className="quote quote--purple">
                                <p><i>“Taking this product I was able to get to sleep no problem, I stayed asleep and I didn't wake up throughout the night like I usually do.”</i><span className="d-block">- <strong className="fw--bold">Sherri J.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--40 mb--xs-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Carrie Ann H." src="https://player.vimeo.com/video/396799261" frameBorder="0"/>
                            </div>

                            <div className="quote quote--purple">
                                <p><i>"I wake up feeling refreshed every morning - full of energy."</i><span className="d-block">- <strong className="fw--bold">Carrie Ann H.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-12 mb--20">
                            <div className="row">
                                <div className="col-md-3 txt--right txt--center-md mb--20">
                                    <img src="/images/supplements/whole-sleep/karey.png" width="170" height="170" alt="Image of Karey." className="no-shadow radius-0" />
                                </div>

                                <div className="col-md-8">
                                    <div className="quote quote--purple">
                                        <p className="m--0"><i>"I couldn’t wait for my little package to arrive. I have been prescribed sleep aids and have used over the counter ones before this. They all made me feel awful even if I was able to get a full 8 hours of sleep. I tried straight melatonin as well. It helped me fall asleep quicker but the quality of keep I was getting did not change. A typical night, without sleep aides...5-7 hours of tossing and turning, waking up and staying awake.</i></p>
                                        <p className="m--0"><i>Since I started taking Whole Sleep, I find myself falling asleep easier, quicker. Once I fall asleep I stay asleep. I haven’t been tossing and turning. The best part, I feel rested when I wake. It has worked wonderfully for me."</i></p>
                                        <span className="d-block txt--16">- <strong>Karey H.</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb--40 mb--xs-0">
                            <div className="row">
                                <div className="col-md-3 txt--right txt--center-md mb--20">
                                    <img src="/images/supplements/whole-sleep/nancy.png" width="170" height="170" alt="Image of Nancy." className="no-shadow radius-0" />
                                </div>

                                <div className="col-md-8">
                                    <div className="quote quote--purple">
                                        <p className="m--0"><i>"I feel like it has improved the quality of my sleep. I have been waking up only once....about 3 AM... and pretty much falling back to sleep quickly (which is unusual for me as my mind usually ramps up and doesn't allow this).</i></p>
                                        <p className="m--0"><i>I have also been waking up before the alarm goes off.....I just wake up and feel ready to get up. So, I definitely feel like this is a sign of more thorough sleep."</i></p>
                                        <span className="d-block txt--16">- <strong>Nancy B.</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
export default WholeSleep
