import React, {useEffect, useState} from "react"
import Faqs from "../../components/Faqs";
import Tabs from "../../components/Tabs"
import {Helmet} from "react-helmet";

const FullBodyCollagen = ({updateActiveTab}) => {
    const [activeTab, setActiveTab] = useState('about')

    const handleActive = (el) => {
        setActiveTab(el);
    }

    useEffect(() => {
        setActiveTab(updateActiveTab)
    },[updateActiveTab])


    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Full Body Collagen"/>
                <meta property="og:description" content="30 servings of Hydrolyzed Collagen Peptides for daily bone, joint and skin support. Easily dissolvable and flavorless powder is perfect for adding to hot or cold drinks, smoothies, sauces or any of your favorite recipes."/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/FBC-FB.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Full Body Collagen." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Full Body Collagen"/>
                <meta name="twitter:description" content="30 servings of Hydrolyzed Collagen Peptides for daily bone, joint and skin support. Easily dissolvable and flavorless powder is perfect for adding to hot or cold drinks, smoothies, sauces or any of your favorite recipes."/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/FBC-FB.jpg"/>
                <meta name="twitter:image:alt" content="Image of Full Body Collagen." />
                <meta name="twitter:card" content="summary_large_image"/>
                <title>Full Body Collagen</title>
            </Helmet>

            {/*tab menu*/}
            <section className="pt--70 pt--md-30 pb--0">

                <Tabs active={activeTab} handleActive={handleActive}/>

                {/*tab content*/}
                <div className="tab-content">
                    {/*tab content about*/}
                    { (activeTab === "about") &&
                    <div id="about">
                        {/*what is collagen*/}
                        <section className="container pt--70 pt--sm-30 pb--30">
                            <h2 className="mb--20 h2--supplements mb--md-20 mb--xxs-10">Research-Proven Collagen Peptides to Support your<br className="d-none d-md-block"/> Bones, Joints and Skin</h2>
                            <h4 className="txt--center txt--pink font--oswald txt--24 txt--xxs-20 mb--60 mb--md-30">Instantly boost the nutritional benefit of any liquid or recipe without changing its flavor or texture!</h4>

                            <div className="row">

                                <div className="col-12 col-lg-7 order-2 order-lg-1 mb--60 mb--md-30">
                                    <h2 className="h2--supplements txt--pink txt--left txt-md-center mb--30">What is Collagen?</h2>
                                    <p className="p--supplements"><strong className="fw--bold">Full Body Collagen</strong> is in my personal daily rotation because of its amazing protective benefits for our skin, joints and bones. It’s really versatile in that it is flavorless, can be mixed in anything and dissolves completely and instantly.</p>

                                    <ul className="checkmark--supplements">
                                        <li>Collagen is <strong className="fw--bold">the most abundant protein found in our bodies,</strong> and acts like the "glue" that holds everything together.</li>
                                        <li><strong className="fw--bold">It is made up of the amino acids <i>glycine, proline and hydroxyproline</i></strong> (don’t worry I’m not going to quiz you on that!).</li>
                                        <li>These three amino acids can be found in other protein sources, but not in as high of concentrations - which is why <strong className="fw--bold">collagen peptides are a great supplement to add to a healthy balanced diet.</strong></li>
                                        <li>Collagen used to make its way into our diets through foods like bone broths, slow-cooked organ meats, kidney pies, baked beef hearts, whole crustaceans, and whole-fish soups and stews.<br/><br/> But if you’re not regularly eating these types of foods, you may not be getting much or any of this goodness into your body.</li>
                                    </ul>
                                </div>

                                <div className="col-12 col-lg-5 order-1 order-lg-2 txt--center">
                                    <img src="/images/supplements/full-body-collagen/betty-full-collagen.jpg" className="no-shadow radius-0 mb--30" alt="Full body collagen." width="470" height="587"/>
                                </div>
                            </div>

                            <p className="txt--23 txt--pink mb--0 lh--1"><strong className="fw--bold">It’s easy to add to hot or cold beverages, dissolves instantly and has no taste.</strong></p>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Full Body Collagen Recipe Guide<span className="d-inline-block d-sm-inline"> Included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/full-body-collagen/bonus-2.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="620" height="239"/>
                                    </div>
                                  </div>
                            </div>
                        </div>

                        {/*3 Research-Proven Ways*/}
                        <section className="section--padding-lg container container--smd">
                            <h2 className="h2--supplements txt--pink mb--50 mb--sm-20">3 Research-Proven Ways Collagen Supports Your Body:</h2>
                            <div className="d-flex mb--40 mb--sm-20 flex-row flex-column flex-md-row">
                                <img src="/images/supplements/full-body-collagen/support-skin.png" alt="Support skin." className="no-shadow radius-0 align-self-center align-self-md-start mb--20" width="118" height="118"/>

                                <div className="txt--sm-center txt--left pl--sm-0 pl--40">
                                    <p className="p--supplements"><strong className="fw--bold"><span className="txt--pink">Support for Your Skin:</span> Full Body Collagen</strong> supports healthy skin elasticity, thickness, hydration and nail health. When it comes to our skin, 75% of the protein in our skin is made up of collagen, and a deficiency in our collagen as we age can show up as wrinkles, sagging skin or even age spots.</p>
                                    <p className="p--supplements">Simply adding in a serving of collagen daily can be a great way to help maintain optimal collagen levels and a healthy youthful appearance.</p>
                                </div>
                            </div>

                            <div className="d-flex mb--40 mb--sm-20 flex-column flex-md-row">
                                <img src="/images/supplements/full-body-collagen/support-joints.png" alt="Support joints." className="mb--20 no-shadow radius-0 align-self-center align-self-md-start" width="118" height="118"/>

                                <div className="txt--sm-center txt--left pl--sm-0 pl--40">
                                    <p className="p--supplements"><strong className="fw--bold"><span className="txt--pink">Support for Your Joints:</span> Full Body Collagen</strong> can help support joint tissue health and function involving cartilage, tendons and ligaments. When it comes to our joints, it’s that breakdown of the cartilage that leads to achy joints and osteoarthritis which can be reduced by supplementing with collagen because it triggers your body to repair your joint structures and may help increase your mobility.</p>
                                    <p className="p--supplements">It can also improve muscle strength and increase lean mass when used in combination with resistance training.</p>
                                </div>
                            </div>

                            <div className="d-flex flex-row flex-column flex-md-row">
                                <img src="/images/supplements/full-body-collagen/support-bones.png" alt="Support bones." className="mb--20 no-shadow radius-0 align-self-center align-self-md-start" width="118" height="118"/>

                                <div className="txt--sm-center txt--left pl--sm-0 pl--40">
                                    <p className="p--supplements"><strong className="fw--bold"><span className="txt--pink">Support for Your Bones:</span></strong> Did you know that 95% of our bones are made up of collagen? But as we age many people suffer from bone loss and loss of bone density - making them more prone to breakage and fractures.</p>
                                    <p className="p--supplements"><strong className="fw--bold">Full Body Collagen</strong> (especially when combined with resistance training) can help maintain healthy bone density, bone mass and bone quality.</p>
                                </div>
                            </div>
                        </section>

                        <section className="container pt--0">
                            <img src="/images/supplements/full-body-collagen/betty-circles.jpg" alt="Betty Rocker Full body callogen." className="no-shadow radius-0 mx--auto" width="1168" height="591"/>
                        </section>

                        {/*sourced and created*/}
                        <section className="bg--supplements-section section--padding-lg">
                            <div className="container container--md">
                                <h2 className="h2--supplements txt--pink txt--left txt--sm-center mb--30">Full Body Collagen: Sourced and Created with Love</h2>
                                <p className="p--supplements">Whenever you are taking any supplements, it is so important to pay attention to the <strong className="fw--bold">source</strong>!</p>

                                <p className="p--supplements">At Whole Betty, we source Full Body Collagen from cows from Germany that are fed a mixed diet of grass and hay. Our farmers never use GMO foods or antibiotics.</p>

                                <p className="p--supplements">We’ve <strong className="fw--bold">hydrolyzed</strong> the collagen which means it’s in the smallest, finest particles - making it extremely dissolvable in anything, as well as exceptionally easy for your body to absorb and digest so it can do the most good for you. You can put it in hot or cold beverages.</p>
                            </div>
                        </section>

                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 mb--sm-20 h2--supplements txt--pink">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--40 mb--sm-20" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="no-shadow radius-0 mb--20 img--lg" width="456" height="75"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                    </div>}

                    {/*tab content nutrition*/}
                    { (activeTab === "nutrition") &&
                    <div id="nutrition">
                        {/*facts*/}
                        <section className="container section--padding-xl">
                            <div className="row">
                                <div className="col-xs-12 col-lg-5 txt--center">
                                    <img src="/images/supplements/full-body-collagen/nutrition-table.png" className="supplements--border no-shadow radius-0 mb--50 mb--xs-20" alt="Nutrition table" width="462" height="538"/>

                                    <div className="col-xs-12 order-3 order-lg-4">
                                        <div className="note note--sm d-none d-lg-block">
                                            <p className="p--supplements"><strong className="fw--bold">Recommended Use:</strong> As a dietary supplement, mix 13 grams (approx. one scoop) with eight ounces of water per day. For best results, add powder prior to adding water.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-lg-7">
                                    <h4 className="h4--supplements"><strong className="fw--bold"><span className="txt--pink">1.</span> 30 Servings</strong></h4>
                                    <p className="p--supplements mb--30 mb--xs-15">With 30 servings per container, you’ll have the perfect amount for a daily scoop!</p>

                                    <h4 className="h4--supplements"><strong className="fw--bold"><span className="txt--pink">2.</span> Sodium</strong></h4>
                                    <p className="p--supplements mb--30 mb--xs-15">Sodium is a mineral found in most foods we eat. This number designates the amount of naturally occurring sodium in the collagen peptides.</p>

                                    <h4 className="h4--supplements"><strong className="fw--bold"><span className="txt--pink">3.</span> Collagen peptides</strong></h4>
                                    <p className="p--supplements mb--30 mb--xs-15">Collagen peptides are made of 3 specific amino acids in high concentrations: glycine, proline and hydroxyproline and are therefore classified as a protein. A complete protein contains 9 essential amino acids (that your body can’t make on its own) and can be found in dietary sources such as animal meats, and some plants as well as supplements. For this reason, it’s recommended to add your collagen into your whole food diet that includes plenty of complete dietary protein.</p>

                                    <h4 className="h4--supplements"><strong className="fw--bold"><span className="txt--pink">4.</span> Ingredients</strong></h4>
                                    <p className="p--supplements mb--0">WFull Body Collagen is simple and pure. We don’t add anything to our peptides, and this makes them as versatile as possible for you so they can be added to hot or cold beverages, soups, sauces or baked goods. The hydrolization process breaks them down to the finest, tiniest particle making them easy to dissolve and easy to digest and absorb in your body.</p>
                                </div>

                            </div>
                        </section>

                        {/*pink section*/}
                        <section className="bg--pink py--30">
                            <div className="container">

                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-lg-4 txt--sm-center txt--right order-2 order-lg-1">
                                        <img src="/images/supplements/betty.png" className="no-shadow radius-0" alt="Betty Image." width="214" height="311"/>
                                    </div>

                                    <div className="col-xs-12 col-lg-8 order-1 order-lg-2">
                                        <h3 className="h3--supplements ls--xxl txt--white d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row txt--uppercase mb--30"><span className="no-shrink">whole body <strong className="fw--light">by</strong></span> <img src="/images/supplements/logo-white.png" className="no-shadow radius-0 img--header ml--10" alt="White logo" width="416" height="73"/></h3>
                                        <p className="txt--white p--supplements txt--sm-center mb--0 mb--md-20">At Whole Betty, we believe that Whole Foods should form the foundation for what you put in your body. That’s why our ingredients are mindfully sourced and our blends are consciously created so you get the maximum health benefit, every time.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Full Body Collagen Recipe Guide<span className="d-inline-block d-sm-inline"> Included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/full-body-collagen/bonus-2.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="620" height="239"/>
                                    </div>
                                  </div>
                            </div>
                        </div>

                    </div>}

                    {/*tab content whybuy*/}
                    { (activeTab === "quality") &&
                    <div id="quality">
                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 mb--sm-20 h2--supplements txt--pink">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--40 mb--sm-20" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="no-shadow radius-0 mb--20 img--lg" width="456" height="75"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                        {/*pink section*/}
                        <section className="bg--pink py--30">
                            <div className="container">

                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-lg-4 txt--sm-center txt--right order-2 order-lg-1">
                                        <img src="/images/supplements/betty.png" className="no-shadow radius-0" alt="Betty Image." width="214" height="311"/>
                                    </div>

                                    <div className="col-xs-12 col-lg-8 order-1 order-lg-2">
                                        <h3 className="h3--supplements ls--xxl txt--white d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row txt--uppercase mb--30"><span className="no-shrink">whole body <strong className="fw--light">by</strong></span> <img src="/images/supplements/logo-white.png" className="no-shadow radius-0 img--header ml--10" alt="White logo" width="416" height="73"/></h3>
                                        <p className="txt--white p--supplements txt--sm-center mb--0 mb--md-20">At Whole Betty, we believe that Whole Foods should form the foundation for what you put in your body. That’s why our ingredients are mindfully sourced and our blends are consciously created so you get the maximum health benefit, every time.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Full Body Collagen Recipe Guide<span className="d-inline-block d-sm-inline"> Included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/full-body-collagen/bonus-2.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="620" height="239"/>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>}

                    {/*tab content faq*/}
                    { (activeTab === "faq") &&
                    <div id="faq">
                        {/*faq*/}
                        <section className="container container--xs section--padding-xl" id="faq">
                            <h2 className="h2--supplements mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                            <Faqs productName='fbc'></Faqs>
                        </section>

                        {/*pink section*/}
                        <section className="bg--pink py--30">
                            <div className="container">

                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-lg-4 txt--sm-center txt--right order-2 order-lg-1">
                                        <img src="/images/supplements/betty.png" className="no-shadow radius-0" alt="Betty Image." width="214" height="311"/>
                                    </div>

                                    <div className="col-xs-12 col-lg-8 order-1 order-lg-2">
                                        <h3 className="h3--supplements ls--xxl txt--white d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row txt--uppercase mb--30"><span className="no-shrink">whole body <strong className="fw--light">by</strong></span> <img src="/images/supplements/logo-white.png" className="no-shadow radius-0 img--header ml--10" alt="White logo" width="416" height="73"/></h3>
                                        <p className="txt--white p--supplements txt--sm-center mb--0 mb--md-20">At Whole Betty, we believe that Whole Foods should form the foundation for what you put in your body. That’s why our ingredients are mindfully sourced and our blends are consciously created so you get the maximum health benefit, every time.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Full Body Collagen Recipe Guide<span className="d-inline-block d-sm-inline"> Included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/full-body-collagen/bonus-2.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="620" height="239"/>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </section>

            {/*what our rockstars are saying*/}
            <section className="bg--supplements-section section--padding-xl">
                <div className="container">
                    <h2 className="mb--80 mb--md-40 mb--sm-20 h2--supplements">What Our Rockstars are Saying About<br className="d-none d-md-block"/> Full Body Collagen…</h2>

                    <div className="row">

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Cara L." src="https://player.vimeo.com/video/378670837" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“When it comes to Whole Betty, the message is: Fuel your body, fuel your mind - be strong - and because of that, you feel really good about how you look and who you are and how you feel mentally and physically”</i><span className="d-block">- <strong className="fw--bold">Cara L.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Angela P." src="https://player.vimeo.com/video/378670844" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I would highly recommend this product. It is made with high quality and easy to use. If you’re looking for a way to get some extra protein and collagen in your diet, I would definitely give Whole Betty Full Body Collagen a try!”</i><span className="d-block">- <strong className="fw--bold">Angela P.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Stephenie M." src="https://player.vimeo.com/video/378670863" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!”</i><span className="d-block">- <strong className="fw--bold">Stephenie M.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Melissa B." src="https://player.vimeo.com/video/378671479" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I’ve been using the Full Body Collagen for a couple of weeks….I’ve had it every day and I totally noticed a difference. I have some spinal and joint pain, and it’s gone down since I started using this!”</i><span className="d-block">- <strong className="fw--bold">Melissa B.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Angela J." src="https://player.vimeo.com/video/378670852" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I wanted to show you how I use Full Body Collagen - I mix it with cream and have it in my coffee - and it doesn’t have an aftertaste and doesn’t mask the taste of your coffee.”</i><span className="d-block">- <strong className="fw--bold">Angela J.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20 mb--sm-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Tammy F." src="https://player.vimeo.com/video/378670877" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“What I love about this product is you can put it in anything and it mixes up really smoothly. I’ve tried other collagens before and they were clumpy. There are no added carbs or sugar, and there’s no taste.”</i><span className="d-block">- <strong className="fw--bold">Tammy F.</strong></span></p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default FullBodyCollagen
