import React from "react";
import { slideUp, slideToggle} from "../utilities/slide";

const FaqItem = ({item}) => {

    const toggleFaq = (e) => {
        e.preventDefault();

        let faqItem = e.target.parentNode
        faqItem.classList.toggle('active')
        slideToggle(faqItem.querySelector('.faq__content'), faqItem)

        for (let sibling of faqItem.parentNode.children) {
            if (sibling !== faqItem) {
                sibling.classList.remove('active');
                slideUp(sibling.querySelector('.faq__content'), faqItem);
            }
        }
    }


    return (
        <div className="faq">
            <div
                tabIndex={0}
                role='button'
                onClick={toggleFaq}
                className="faq__head"
                dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <div className="faq__content">
                <div className="faq__content--padding" dangerouslySetInnerHTML={{ __html: item.content }}/>
            </div>
        </div>
    )
}
export default FaqItem
