export const scrollTo = (e, toTop = false) => {
    e.preventDefault()

    let clickedEl = e.target
    let target = clickedEl.getAttribute('href')
    let headerHeight = document.querySelector('.header').clientHeight;
    let sectionTarget = (toTop) ? 0 : document.querySelector(target).offsetTop - headerHeight;

    window.scroll({
        top: sectionTarget,
        behavior: 'smooth',
    })
}