import React from "react"
import { Helmet } from "react-helmet"
import {scrollTo} from "../../utilities/scrollTo";

import Faqs from "../Faqs";
import ScrollToTabs from "../ScrollToTabs";
import DigitalProductBuyBox from "../DigitalProductBuyBox";

const HotAbsAndBooty = ({product}) => {
    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="30-Day Booty + Abs Challenge"/>
                <meta property="og:description" content="Define your abs and sculpt your booty and legs with this time-saving 30-day workout plan that can be done at home or in the gym!"/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/booty-and-abs-fb.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of 30-Day Booty + Abs Challenge." />


                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="30-Day Booty + Abs Challenge"/>
                <meta name="twitter:description" content="Define your abs and sculpt your booty and legs with this time-saving 30-day workout plan that can be done at home or in the gym!"/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/booty-and-abs-fb.jpg"/>
                <meta name="twitter:image:alt" content="Image of 30-Day Booty + Abs Challenge." />
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            {/*sales hero*/}
            <section className="hero pt--xs-30 bg--hero-abs">
                <div className="bg--hero-abs-mob txt--center">
                    <h1 className="h1--md mb--40">30-Day <span className="txt--purple-dark">Booty + Abs</span> Challenge</h1>
                </div>

                <div className="container txt--center">
                    <div className="container container--md">
                        <div className="row mb--10">
                            <div className="col-12 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
                                <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--10">
                                    <iframe title="Hero Video." src="https://player.vimeo.com/video/244466173"/>
                                </div>

                                <h2 className="h2--md txt--uppercase">leaner, tighter, <span className="d-inline-block">faster, stronger:</span></h2>
                                <p className="fw--black txt--purple-dark txt--25 txt--xs-18">Sculpt and Define a Flat Stomach, <span className="d-inline-block">Perky Butt and Lean Legs</span></p>

                                <a className="btn btn--md fw--medium" onClick={scrollTo} href="#order-now">start now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ScrollToTabs supplements={false}/>

            <section className="container arrow-down js-section" id="how-it-works">
                <h2 className="h2--sm txt--center mb--30">Here's How <span className="txt--purple-dark">It Works:</span></h2>
                <h4 className="txt--center mb--40">Define your abs and sculpt your booty and legs with this time-saving <br className="d-none d-md-block"/>
                    30-day workout plan that can be done at home or in the gym!</h4>

                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-40">
                        <div className="txt--center-md">
                            <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                <img src="/images/hot-abs/betty-1.jpg" alt="" className="no-shadow lazy" width="332" height="332"/>
                            </div>
                        </div>
                        <h3 className="txt--md-left txt--purple-dark mb--20 ml--50 ml--md-0"><strong>TONE</strong></h3>
                        <ul className="checkmark-purple list--padding">
                            <li>This program delivers proven, <strong>balanced and fun resistance training workouts</strong> that sculpt out the shape in your booty, legs and abs using minimal equipment.</li>
                            <li><strong>Hot Abs and Booty</strong> can be done at home with minimal equipment or in the gym – and in the demo videos you’ll see options for using a variety of equipment and in many cases, just your own bodyweight.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-40">
                        <div className="txt--center-md">
                            <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                <img src="/images/hot-abs/betty-2.jpg" alt="" className="no-shadow lazy" width="332" height="332"/>
                            </div>
                        </div>
                        <h3 className="txt--md-left txt--purple-dark mb--20 ml--50 ml--md-0"><strong>SCULPT</strong></h3>
                        <ul className="checkmark-purple list--padding">
                            <li><strong>Do it from home or in the gym</strong> and see the fastest definition targeting and strengthening your abs and glutes from multiple angles so you not only get a beautiful sculpted shape, but improve your body’s function as well.</li>
                            <li><strong>Lift and shape your booty and define</strong> and shred your abs with this body shaping program!</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-40">
                        <div className="txt--center-md">
                            <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                <img src="/images/hot-abs/betty-3.jpg" alt="" className="no-shadow lazy" width="332" height="332"/>
                            </div>
                        </div>
                        <h3 className="txt--md-left txt--purple-dark mb--20 ml--50 ml--md-0"><strong>DEFINE</strong></h3>
                        <ul className="checkmark-purple list--padding">
                            <li>You’ll get a <strong>30 day plan that includes upper body and full body training</strong> as well for a balanced workout program with a specific focus on the butt, legs and abs!</li>
                            <li>This program is primarily geared toward intermediate exercisers, but is <strong>appropriate for beginners as well</strong>, as you’ll have demonstrations and modifications.</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/*testimonials*/}
            <section className="arrow-down bg--gray-top js-section" id="results">
                <div className="container">
                    <h2 className="h2--sm mb--40">Just A Few <span className="txt--purple-dark">Booty+Abs Program</span> Results:</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hot-abs/testimonial-1.jpg" alt="Testimonial of Laura M." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I feel stronger, sexier and lighter!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I’m REALLY pleased with the changes my body is making! I feel stronger, sexier, lighter, and generally better inside and out.</p>
                                        <p className="txt--pink"><strong>- Laura M.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hot-abs/testimonial-2.jpg?v=1.0.1" alt="Testimonial of Chantele V." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"So happy to see fat literally melting off"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I’m constantly looking at my midsection in the mirror because I’m so happy to see fat literally melting off, and definition starting to form.</p>
                                        <p className="txt--pink"><strong>- Chantele V.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-block d-sm-none d-md-block col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hot-abs/testimonial-3.jpg?v=1.0.1" alt="Testimonial of Kimmie S." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I could stick to this and feeling stronger!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>So here we are – a before and after shot! Still some ways to go but I”m proud that I could stick to this and I’m feeling stronger!</p>
                                        <p className="txt--pink"><strong>- Kimmie S.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/*what is included*/}
            <section className="bg--section arrow-down js-section" id="what-is-included">
                <h2 className="h2--sm mb--40">Your Complete <span className="txt--purple-dark">30-Day Hot Abs + Booty Workout Plan</span> Includes….</h2>
                <div className="container container--md">
                    <div className="card card--tag-sm mb--30 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--purple tag--sm">#1</div>
                            <h4><strong>INCLUDED: Complete Legs + Booty Follow-Along <br className="d-none d-md-block"/>Video Workout Program <span className="txt--purple-dark">$127 VALUE</span></strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row align-items-center">
                                <div className="col-md-6 mb--sm-30 txt--center">
                                    <img className="no-shadow lazy" src="/images/hot-abs/included-1.png" alt="" width="477" height="348"/>
                                </div>
                                <div className="col-md-6">
                                    <ul className="checkmark-purple">
                                        <li><strong>Follow along with me in the workout video so you can do the entire workout with me</strong> – with motivation, direction and form tips throughout.</li>
                                        <li>Use the <strong>tutorial video for each workout</strong> to learn options for doing the moves in the workouts with just your own bodyweight OR how to add simple equipment – dumbbells, bands, and a swiss ball.</li>
                                        <li>Keep your body guessing with <strong>fun, varied moves from workout to workout</strong> so you can sculpt, tighten, lift and shred!</li>
                                        <li>Get <strong>motivation and encouragement</strong> from the support of your coach while you’re exercising in these fun videos!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card--tag-sm mb--30 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--purple tag--sm">#2</div>
                            <h4><strong>INCLUDED: Hot Abs Workouts Tutorial Videos <span className="txt--purple-dark">$47 VALUE</span></strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row align-items-center">
                                <div className="col-md-6 txt--center mb--sm-30">
                                    <img className="no-shadow lazy" src="/images/hot-abs/included-2.png" alt="" width="467" height="331"/>
                                </div>
                                <div className="col-md-6">
                                    <ul className="checkmark-purple">
                                        <li><strong>Sculpt your abs</strong> with these specially designed core workouts that firm, flatten and define!</li>
                                        <li>Learn the moves for each workout in the <strong>demo videos</strong></li>
                                        <li><strong>Modifications and alternatives shown</strong> so you can build your strength using this program</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card--tag-sm mb--30 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--purple tag--sm">#3</div>
                            <h4><strong>INCLUDED: Abs + Booty Workout Guide <span className="txt--purple-dark">$67 VALUE</span></strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row">
                                <div className="col-md-6 txt--center mb--sm-30">
                                    <img className="no-shadow lazy" src="/images/hot-abs/included-3.png" alt="" width="486" height="351"/>
                                </div>
                                <div className="col-md-6">
                                    <ul className="checkmark-purple">
                                        <li>In this awesome guide, you’ll get <strong>valuable information and form tips</strong> for each of the workout moves so you can get <strong>MAXIMUM</strong> benefit from doing them!</li>
                                        <li>Since everyone learns a little differently, it’s useful to have this information written down <strong>so you can read it, as well as being able to see it and learn it with me in the videos.</strong></li>
                                        <li>You’ll also have <strong>each of your workouts written out in the guide for quick and easy reference</strong> so you can browse through them to see which one you want to do.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card--tag-sm mb--40 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--purple tag--sm">#4</div>
                            <h4><strong>INCLUDED: 30-Day Workout Plan <span className="txt--purple-dark">$27 VALUE</span></strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row align-items-center">
                                <div className="col-md-6 txt--center mb--sm-30">
                                    <img className="no-shadow lazy" src="/images/hot-abs/included-4.png" alt="" width="459" height="372"/>
                                </div>
                                <div className="col-md-6">
                                    <ul className="checkmark-purple">
                                        <li>THIS is exactly what you need to get the MOST out of your brand new <strong>Booty and Abs Program!</strong></li>
                                        <li>Start your program right away, and follow the format which takes you through your booty, legs, abs and bonus workouts <strong>so you can get a total body benefit, with an emphasis on your legs, abs, and butt!</strong></li>
                                        <li>You’ll speed to results with this format, whether you’re doing these workouts <strong>at home or in the gym</strong> and make amazing progress toward your goals!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="txt--center">
                        <a className="btn btn--md fw--medium" onClick={scrollTo} href="#order-now">start now</a>
                    </div>
                </div>
            </section>

            {/*meet your coach*/}
            <section className="bg--meet-coach js-section" id="meet-your-coach">
                {/*if changing heading here, change it on both locations desktop and responsive*/}
                <div className="bg--meet-coach-mob mb--20 d-flex align-items-center justify-content-center d-lg-none">
                    <h2 className="h2--sm">Meet Your <span className="txt--purple-dark">Coach!</span></h2>
                </div>
                <div className="container container--md">
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-4">
                            <h2 className="d-none d-lg-block h2--sm mb--30 txt--left">Meet Your <span className="txt--purple-dark">Coach!</span></h2>

                            <p className="fw--black"><span className="txt--purple-dark">Who is Betty Rocker?</span> Betty Rocker is me! My name is Bree Argetsinger, but I’ve been called “punk rock Betty Crocker” since I was a little girl.</p>
                            <p>I’m a health coach and fitness motivator, and I work with people all over the world, helping them transform from the inside out.</p>
                            <p>My passion for fitness and health developed after treating my body very badly for many years and choosing to educate myself and do the work to develop good practices based on science, my own intuition and a deep love and compassion for other people who were struggling as well.</p>
                            <p>While working as a structural integration therapist with extreme sports athletes and racers (which I did for 8 years!), my clients started asking me for nutrition plans that were like “what I was using.” When I saw how much more quickly they got results following my combined nutrition and workout plans, I transitioned to the work I do now and began teaching this impactful information online so I could reach as many people as possible.</p>
                            <p>I have mastered many aspects of health and personal development, including becoming a C.H.E.K. (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A. certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner (realignment of the body through manual therapy).</p>
                            <p>I’ve written multiple cookbooks, and nutrition and fitness plans that have been successfully used by thousands of people. My primary focus is on empowering you with the skills and knowledge you need to make the best choices for yourself – so you can achieve the result you’re looking for while enjoying yourself along the way.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/*testimonials*/}
            <section className="arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--40">More <span className="txt--purple-dark">Booty+Abs Program</span> Results:</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hot-abs/testimonial-4.jpg" alt="Testimonial of Nelly C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I can see my abs!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I can see my abs!</p>
                                        <p className="txt--pink"><strong>- Nelly C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hot-abs/testimonial-5.jpg" alt="Testimonial of Carol B." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"My progress from your program!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I just wanted to share my progress from your Abs + Booty Program and tabata workouts! I am a 33 year old mom of 2.9 months post-partum and 2 C-sections! I absolutely love your workouts and your encouragement during the videos!!</p>
                                        <p className="txt--pink"><strong>- Carol B.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-block d-sm-none d-md-block col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hot-abs/testimonial-6.jpg" alt="Testimonial of Sabrina S." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"So excited about how much my body is changing!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I am so excited about how much my body is changing!</p>
                                        <p className="txt--pink"><strong>- Sabrina S.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/*start now*/}
            <section className="bg--gray-top arrow-down" id="order-now">
                <div className="container pt--40">
                    <h2 className="mb--10">START TODAY!</h2>
                    <h3 className="txt--center mb--40">Click <span className="txt--purple-dark">“Start Now”</span> To Start Your 30-Day Booty + Abs Challenge<br/>– For Less Than $1 A Day!</h3>

                    <img src="/images/hot-abs/abs-booty-full.png" alt="" width="1170" height="658" className="lazy no-shadow mb--20"/>

                        <div className="container container--md txt--center">
                            <h2 className="fw--regular h2--sm mb--40">30-Day Booty
                                <span className="d-inline-block">+ Abs Challenge Plan</span>
                                <span className="d-inline-block txt--purple-dark"><strong>($275)Total Value</strong></span>
                            </h2>

                            <DigitalProductBuyBox
                                product={product}
                                singleBox={true}
                            />

                            <img className="shadow-0 d-block mx--auto mb--30 lazy" src="/images/credit-cards.png" alt="" width="300" height="39"/>

                                <p className="txt--14 txt--xs-16 mb--10 mb--xs-20"><strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong></p>
                                <p className="txt--14 txt--xs-16"><em>You’ll receive INSTANT ACCESS to the Booty and Abs Challenge Immediately <br className="d-none d-md-block"/>After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.</em></p>
                        </div>
                </div>
            </section>

            {/*fb testimonials*/}
            <section className="bg--section arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--30">More Success Stories <span className="d-inline-block txt--purple-dark">From Our Challengers!</span></h2>
                    <div className="row align-items-center img--stack-wrapper">
                        <div className="col-md-6 mb--50 mb--xs-30">
                            <div className="img-stack mb--50 mb--xs-30">
                                <img className="lazy" src="/images/hot-abs/fb-testimonial-1.jpg" alt="" width="544" height="251"/>
                            </div>

                            <div className="img-stack">
                                <img className="lazy" src="/images/hot-abs/fb-testimonial-3.jpg" alt="" width="527" height="96"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb--50 mb--xs-30">
                            <div className="img-stack mb--50 mb--xs-30">
                                <img className="lazy" src="/images/hot-abs/fb-testimonial-2.jpg" alt="" width="542" height="222"/>
                            </div>

                            <div className="img-stack">
                                <img className="lazy" src="/images/hot-abs/fb-testimonial-4.jpg" alt="" width="540" height="190"/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/*guarantee*/}
            <div className="container pt--30 pb--50 pt--sm-120">
                <div className="box-guarantee box-guarantee--purple">
                    <img src="/images/guarantee-100.png" alt="" width="254" height="261" className="lazy no-shadow"/>
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className="h2--sm txt--white mb--20 txt--smd-left">100% Money Back GUARANTEE</h2>

                            <p className="txt--white lh--auto">Take the first step toward improving your health by trying my 30-day Booty and Abs challenge plan for the next 30 days. If you don’t feel healthier, more energized and simply amazing, you won’t pay. If you’re not satisfied, simply contact customer service within 30 days for a full refund.</p>

                            <p className="txt--white txt--22 fw--black"><span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc</p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="bg--section js-section py--50 pb--xs-30" id="faq">
                <div className="container">
                    <h2 className="mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                </div>
                <div className="container container--xs">

                    <Faqs color="purple" productName='hotabs'/>

                </div>
            </section>
        </>
    )
}
export default HotAbsAndBooty
