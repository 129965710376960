import React from "react"
import { Helmet } from "react-helmet"
import {scrollTo} from "../../utilities/scrollTo";

import Faqs from "../Faqs";
import ScrollToTabs from "../ScrollToTabs";
import DigitalProductBuyBox from "../DigitalProductBuyBox";

const Lioness = ({ product }) => {
    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Lioness - 8-Week Women’s Strength Training Program"/>
                <meta property="og:description" content="Build, strengthen and sculpt your most fit, sculpted physique ever with the empowering 8­-week women's strength training program!"/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/lioness-fb.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Lioness - 8-Week Women’s Strength Training Program." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Lioness - 8-Week Women’s Strength Training Program"/>
                <meta name="twitter:description" content="Build, strengthen and sculpt your most fit, sculpted physique ever with the empowering 8­-week women's strength training program!"/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/lioness-fb.jpg"/>
                <meta name="twitter:image:alt" content="Image of Lioness - 8-Week Women’s Strength Training Program." />
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            {/*sales hero*/}
            <section className="hero bg--section pt--20 bg--hero-lioness">
                {/*if updated title change both desktop and responsive versions*/}
                <div className="bg--hero-lioness-mob d-flex flex-column justify-content-center align-items-start d-lg-none">
                    <h1 className=" txt--100 txt--left txt--uppercase mb--5">Lioness</h1>
                    <h2 className="txt--left txt--36 mb--20"><span className="txt--blue">8-Week Women’s</span> Strength Training Program</h2>
                </div>

                <div className="container">
                    <div className="container container--md">
                        <div className="row mb--10">
                            <div className="col-12 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
                                <h1 className="d-none d-lg-block txt--100  txt--left txt--uppercase mb--5">Lioness</h1>
                                <h2 className="d-none d-lg-block txt--left txt--36 mb--20"><span className="txt--blue">8-Week Women’s</span> Strength Training Program</h2>
                                <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--20">
                                    <iframe title="Hero Video." src="https://player.vimeo.com/video/264865723"/>
                                </div>

                                <div className="txt--center">
                                    <a className="btn btn--md fw--medium" onClick={scrollTo} href="#order-now">start now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ScrollToTabs supplements={false}/>

            <section className="container arrow-down js-section" id="how-it-works">
                <h2 className="h2--sm txt--center mb--30">Here's How <span className="txt--blue">It Works:</span></h2>
                <h4 className="txt--center mb--40">Unleash your power with progressive strength workouts designed to <br className="d-none d-md-block"/> sculpt lean muscle and build a fit physique!</h4>

                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 item-md-border">
                        <div className="txt--center-md">
                            <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                <img src="/images/lioness/lioness-1.png" alt="" className="no-shadow lazy" width="332" height="332"/>
                            </div>
                        </div>
                        <h3 className="txt--md-left txt--blue mb--20 ml--50 ml--md-0 txt--uppercase"><strong>Strengthen</strong></h3>
                        <ul className="checkmark-blue list--padding">
                            <li>Add lean muscle to sculpt a tight, toned physique with specially sequenced workouts that will define sculpted legs, a lifted booty, a strong back and arms, and flat abs.</li>
                            <li>Work your body on the right schedule with timed training designed to stimulate the perfect blend of tissue growth and repair.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 item-md-border">
                        <div className="txt--center-md">
                            <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                <img src="/images/lioness/lioness-2.png" alt="" className="no-shadow lazy" width="332" height="332"/>
                            </div>
                        </div>
                        <h3 className="txt--md-left txt--blue mb--20 ml--50 ml--md-0 txt--uppercase"><strong>Sculpt</strong></h3>
                        <ul className="checkmark-blue list--padding">
                            <li>No bulk, just sculpt! Designed for a woman’s body, these workouts are specially sequenced to add lift to your booty, curve to your hamstrings, define your back, and tone to your abs.</li>
                            <li>Enjoy the versatility of training from home with the full follow along video guides, or take it to the gym and use the short video tutorials so you can move around with ease on your own time frame.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-40">
                        <div className="txt--center-md">
                            <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                <img src="/images/lioness/lioness-3.png" alt="" className="no-shadow lazy" width="332" height="332"/>
                            </div>
                        </div>
                        <h3 className="txt--md-left txt--blue mb--20 ml--50 ml--md-0 txt--uppercase"><strong>Shred</strong></h3>
                        <ul className="checkmark-blue list--padding">
                            <li>Watch the body fat effortlessly disappear as your body composition shifts to more muscle, which burns more calories at rest and tightens the shape of your body</li>
                            <li>Enjoy the effortless confidence that being stronger gives you with greater endurance, more power, drive and energy</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/*testimonials*/}
            <section className="arrow-down bg--section js-section" id="results">
                <div className="container">
                    <h2 className="h2--sm mb--40">Betty Rocker <span className="d-inline-block txt--blue">Program Results:</span></h2>

                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/lioness/testimonial-1.jpg?v=1.0.1" alt="Testimonial of Julie." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Love my results!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I’m so ecstatic for the results I’ve finally been able to achieve! After years of trying to get my body back, the Lioness Program gave me the structure, motivation, and ease of use I’ve been searching for. I love that I didn’t have to go to the gym every day and I still saw results like I’ve never seen before. Having the program on my phone was like having my own personal trainer with me at all times. I’m now in maintenance mode but I re-do the program about once a year to give me a pick-me-up whenever I’m feeling uninspired. It’s my #1 fitness recommendation to my clients. Thank you Bree for my body freedom!</p>
                                        <p className="txt--pink"><strong>- Julie</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/lioness/testimonial-2.jpg?v=1.0.1" alt="Testimonial of Amy P." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I’ve lost 20 pounds!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I just want to share my progress pics. I’ve lost 20 pounds! #muscles</p>
                                        <p className="txt--pink"><strong>- Amy P.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 d-block d-sm-none d-md-block mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/lioness/testimonial-3.jpg" alt="Testimonial of Betty Rocker." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I loved writing this plan and use it all the time!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I use Lioness whenever I want to quickly add lean muscle to my body and get an amazing result. I loved writing this plan and use it all the time!</p>
                                        <p className="txt--pink"><strong>- Betty Rocker</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="bg--lioness-dark js-section" id="what-is-included">
                <div className="container">
                    <h2 className="txt--white h2--sm mb--60 mt--10">Your Complete <span className="txt--blue">Lioness Training Program</span> Includes…</h2>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center">
                            <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--md-20">
                                <iframe title="Video." src="https://player.vimeo.com/video/257596208"/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--left mb--20 txt--white"><strong>Complete 60-Day Workout Program <span className="txt--blue">$249 VALUE</span></strong></h4>
                            <ul className="checkmark-blue pb--20">
                                <li className="txt--white">Effectively Track Your Progress with the Weekly Workout Trackers</li>
                                <li className="txt--white">Insanely Effective Weight Training Workout Program in 2 Phases</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center mb--10">
                            <img src="/images/lioness/included-1.png" alt="" width="610" height="255" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white py--20 py--md-0">
                            <h4 className="txt--20 txt--xs-20 txt--left mb--20 txt--white">
                                <strong><span className="txt--blue">PHASE 1:</span> Foundation, strength, carve out sexy lean muscle</strong>
                            </h4>
                            <ul className="checkmark-blue">
                                <li className="txt--white">4 days per week: Build your foundation with key lifts that work your entire body for strength, shape and shred.</li>
                                <li className="txt--white">Fast, fun and efficient workout finishers to maximize fat burn in less than 10 minutes.</li>
                                <li className="txt--white">Perfect abs formula to work your core from all angles.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center mb--10">
                            <img src="/images/lioness/included-2.png" alt="" width="610" height="254" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white py--20 py--md-0">
                            <h4 className="txt--20 txt--xs-20 txt--left mb--20 txt--white">
                                <strong><span className="txt--blue">PHASE 2:</span> Up the intensity, burn fat and continue to sculpt muscle</strong>
                            </h4>
                            <ul className="checkmark-blue">
                                <li className="txt--white">3 Days per week: Up the intensity with timed block workouts that build each week so you can add strength while you continue to sculpt a lean, mean physique.</li>
                                <li className="txt--white">New workouts in phase 2 to keep your body guessing and avoid the plateau.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center mb--10">
                            <img src="/images/lioness/included-2-1.png" alt="" width="610" height="251" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--20 txt--xs-20 txt--left mb--20 txt--white">
                                <strong>Options for beginner, intermediate and advanced</strong>
                            </h4>
                            <ul className="checkmark-blue">
                                <li className="txt--white">Video demos for every exercise so you can confidently do your workout with different equipment options.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center mb--10">
                            <img src="/images/lioness/included-3.png" alt="" width="529" height="254" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>INCLUDED: Workout Warmups <span className="d-inline-block txt--blue">$79 VALUE</span></strong></h4>
                            <ul className="checkmark-blue">
                                <li className="txt--white">Prevent Injuries and Make Your Workouts More Effective with a Warmed-Up Body</li>
                                <li className="txt--white">Follow Along Video Demos for Your Warmups, no equipment needed</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center mb--md-20">
                            <img src="/images/lioness/included-4.png" alt="" width="529" height="254" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>INCLUDED: Ab Workouts <span className="d-inline-block txt--blue">$129 VALUE</span></strong></h4>
                            <ul className="checkmark-blue">
                                <li className="txt--white">Shred a Sexy Stomach with these Companion Ab Workouts</li>
                                <li className="txt--white">Done-for-you Sequencing so You Know When to Do Your Ab Workouts for Maximum Effect</li>
                                <li className="txt--white">Video Demos for Every Move, with Plenty of Variations for All Fitness Levels</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center mb--20">
                        <div className="col-12 col-lg-6 txt--center mb--10">
                            <img src="/images/lioness/included-5-new.png" alt="" width="529" height="455" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white py--20">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>INCLUDED: 8-Week Done-for-You Meal Plan <span className="d-inline-block txt--blue">$147 VALUE</span></strong></h4>
                            <ul className="checkmark-blue">
                                <li className="txt--white">Complete 8-Week Meal Plan</li>
                                <li className="txt--white">Family-Friendly Whole Food Recipes -Gluten Free, Dairy Free, plus Vegetarian Options Included</li>
                                <li className="txt--white">Grocery Shop with Ease Using Your Weekly Shopping List</li>
                                <li className="txt--white">Easily Prep Some or All of Your Weekly Food with Done for You Food Prep Steps</li>
                                <li className="txt--white">Eliminate all the Guesswork with Daily Menus, with room for Eating out</li>
                                <li className="txt--white">Complete Getting Started Guide so You can Get Maximum Benefit and Optimal Results</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/*bonuses*/}
            <section className="arrow-down container container--md">
                <h2 className="h2--sm mb--40"><span className="txt--blue">Special Bonuses</span> - To BOOST Your Results</h2>
                <div className="card card--tag-sm mb--30 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--blue"><span className="d-none d-md-inline-block">Bonus</span> #1</div>
                        <h4><strong>Workout Finishers <span className="txt--blue">$99 VALUE</span></strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb--sm-30 txt--center">
                                <img className="no-shadow lazy" src="/images/lioness/bonus-1.png" alt="" width="499" height="308"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-blue">
                                    <li>Blast Fat with these 10-Minute HIIT Circuits and take your Results to the Next Level</li>
                                    <li>Follow-Along Video Demos</li>
                                    <li>No Equipment Needed – Do these Anywhere!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card--tag-sm mb--30 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--blue"><span className="d-none d-md-inline-block">Bonus</span> #2</div>
                        <h4><strong>18 Dumbbell Complexes <br/>from Coach Kate Vidulich <span className="txt--blue">$77 VALUE</span></strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row align-items-center">
                            <div className="col-md-6 txt--center mb--sm-30">
                                <img className="no-shadow lazy" src="/images/lioness/bonus-2.png" alt="" width="419" height="300"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-blue">
                                    <li>18 Dumbbell Complexes to ADD to Your Program!</li>
                                    <li>Powerful Workouts that BLAST Stubborn Fat and SKYROCKET Your Conditioning!</li>
                                    <li>Fat Loss Accelerators Exercise Index Included from Coach Kate Vidulich, Fat Loss Expert, BSC, ACSM, MCTT</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card--tag-sm mb--30 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--blue"><span className="d-none d-md-inline-block">Bonus</span> #3</div>
                        <h4><strong>10 Killer Kettlebell Workouts from <br className="d-none d-md-block"/>Coach Maddie Berkey <span className="txt--blue">$57 VALUE</span></strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row">
                            <div className="col-md-6 txt--center mb--sm-30">
                                <img className="no-shadow lazy" src="/images/lioness/bonus-3.png" alt="" width="419" height="366"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-blue">
                                    <li>10 Awesome, High Intensity Kettlebell Workouts Strengthen Your Entire Body with these Efficient, Body-Sculpting Workouts</li>
                                    <li>Complete Demo Document with Form Descriptions and Tips to Get More out of each Move</li>
                                    <li>From Coach Maddie Berkey, Creator of MadWellness, Certified Holistic Nutrition Coach, CPT</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card--tag-sm mb--40 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--blue"><span className="d-none d-md-inline-block">Bonus</span> #4</div>
                        <h4><strong>20 Amazing Ab Workouts <span className="txt--blue">$67 VALUE</span></strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row align-items-center">
                            <div className="col-md-6 txt--center mb--sm-30">
                                <img className="no-shadow lazy" src="/images/lioness/bonus-4.jpg" alt="" width="229" height="300"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-blue">
                                    <li>20 Killer Ab Workouts to Tone and Tighten your Tummy!</li>
                                    <li>Demo Video for Each Exercise</li>
                                    <li>Tons of Variety so You NEVER Get Bored</li>
                                    <li>Excellent to Add into the Lioness Program</li>
                                    <li>From Coach Scott Colby, creator of Fit For Photos, CPT, published author and sought after speaker</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/*meet your coach*/}
            <section className="arrow-down bg--lioness-coach js-section" id="meet-your-coach">
                {/*    if changing heading here, change it on both locations desktop and responsive*/}
                <div className="bg--lioness-coach-mob mb--30 d-flex justify-content-center d-lg-none">
                    <h2 className="txt--white txt--center h2--sm">Meet Your <span className="txt--blue">Coach!</span></h2>
                </div>
                <div className="container txt--white pt--30">
                    <img src="/images/lioness/lioness-betty-coach.png" alt="" width="370" height="370" className="mb--20 lazy no-shadow pull--left"/>

                        <h2 className="d-none d-lg-block h2--sm mb--30 txt--left txt--white">Meet Your <span className="txt--blue">Coach!</span></h2>

                        <p className="txt--white"><strong>Who is Betty Rocker? Betty Rocker is me! My name is Bree Argetsinger, but I’ve been called “punk rock Betty Crocker” since I was a little girl.</strong></p>
                        <p className="txt--white">I created the Lioness Program after hearing over and over from my customers that they wanted the strength training program I was using!</p>
                        <p className="txt--white"><strong>But actually, the ONLY reason I figured out the sequencing I use now is because I really messed myself up about 10 years ago doing it WRONG and overtraining…</strong></p>
                        <p className="txt--white">It’s easy to do.</p>
                        <p className="clearfix txt--white m--0"><strong>I thought the more I did, the better I would look and feel…</strong></p>
                        <p className="txt--white">…but I ended up burning out my adrenals and totally messing up my hormones, causing me to gain weight, have constant fatigue and lose a lot of the gains I had made.</p>
                        <p className="txt--white">When that happened, I had to rethink my 6-7 day a week training approach and get very specific about how to get the maximum benefit – <strong>I needed the most efficient workouts that would shred my stomach, legs and arms and would leave me with the energy I needed to take on everything else going on in my life!</strong></p>
                        <p className="txt--white">That’s why I started cycling through the specific 2-phase sequence you’re going to learn in the Lioness Program, and also why I recommend doing 4-6 weeks of bodyweight training every 8-12 weeks. <strong>That’s exactly what I do, and it’s helped me stay injury free, maintain a beautiful strong, lean, feminine physique and have tons of energy to spare.</strong></p>
                        <p className="txt--white">I will tell you though that without the right fuel I would never look or feel the way I do. That’s why I included awesome meal plans when I wrote this program…because my hungry Lionesses need to feed their bodies right!</p>
                        <p className="txt--white">I have mastered many aspects of health and personal development, including becoming a C.H.E.K. (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A. certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner (realignment of the body through manual therapy).</p>
                        <p className="txt--white">I’ve written multiple cookbooks, and nutrition and fitness plans that have been successfully used by thousands of people. My primary focus is on empowering you with the skills and knowledge you need to make the best choices for yourself – so you can achieve the result you’re looking for while enjoying yourself along the way.</p>
                </div>
            </section>

            <section className="bg--lioness-ready">
                <div className="container">
                    <div className="row py--20">
                        <div className="col-12 col-lg-6 offset-lg-6">
                            <h2 className="h2--sm txt--md-left txt--md-blue mb--40">Ready, Set, Shred!</h2>
                            <p>Lioness Strength Training will give you so much more than a better body. It will reveal a whole new version of YOU.</p>
                            <p>The you who breaks hearts and breaks rules. The you who never has to hide any part of who she is….</p>
                            <p>Because you have ALWAYS been beautiful.</p>
                            <p>Now you’re just deciding to be HEALTHIER, FITTER, FASTER, and STRONGER.</p>
                            <p>SELF CONFIDENCE is the best outfit. ROCK IT. OWN IT. BE IT.</p>
                        </div>
                    </div>
                    <div className="txt--center d-block d-lg-none">
                        <img src="/images/lioness/lioness-ready-mob.jpg" width="600" height="400"  alt="" className="no-shadow lazy"/>
                    </div>
                </div>
            </section>

            {/*testimonials*/}
            <section className="arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--40">Betty Rocker <span className="d-inline-block txt--blue">Program Results:</span></h2>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/lioness/testimonial-4.jpg" alt="Testimonial of Linda B." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Working on my 6-pack"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Thanks Betty Rocker! I won’t give up – still going strong – working on my 6-pack!</p>
                                        <p className="txt--pink"><strong>- Linda B.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/lioness/testimonial-5.jpg" alt="Testimonial of Nola B." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Phase 1 done!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Lioness Phase 1 complete! Where have the last 4 weeks gone? I’m really enjoying this program. I’ve lost an inch off my waist and gained muscle :)</p>
                                        <p className="txt--pink"><strong>- Nola B.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 d-block d-sm-none d-md-block mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/lioness/testimonial-6.jpg" alt="Testimonial of Jo S." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Look! It’s working!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I really didn’t think changing what I ate and doing a little bit of exercise each day would make a difference. But LOOK!! It’s working!</p>
                                        <p className="txt--pink"><strong>- Jo S.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/*start now*/}
            <section className="bg--section arrow-down" id="order-now">
                <div className="container pt--40">
                    <h2 className="mb--10">BECOME A LIONESS TODAY!</h2>
                    <h2 className="h2--sm fw--regular txt--center mb--40">Click <span className="txt--blue">“Start Now”</span> to Start <span className="d-inline-block">the Lioness Strength Training Program!</span></h2>

                    <div className="txt--center">
                        <img src="/images/lioness/main-lioness-new.png" alt="" width="1069" height="581" className="lazy no-shadow mb--20"/>
                    </div>

                    <div className="container container--md txt--center border-top-1 border-light-gray pt--30">

                        <h2 className="fw--regular h2--xs h2--xs-32 h2--xxs-30 h2--xxxs-24 mb--40">
                            <span className="d-inline-block">Lioness Program <span className="txt--blue"><strong>($502 Value)</strong></span></span>
                            <span className="d-inline-block">+ Bonuses <span className="txt--blue"><strong>($201 Value)</strong></span></span>
                            <span className="d-inline-block">= $703 Total Value</span></h2>

                        <DigitalProductBuyBox
                            product={product}
                        />

                        <img className="shadow-0 d-block mx--auto mb--30 lazy" src="/images/credit-cards.png" alt="" width="300" height="39"/>

                            <p className="txt--14 txt--xs-16 mb--10 mb--xs-20"><strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong></p>
                            <p className="txt--14 txt--xs-16"><em>You’ll receive INSTANT ACCESS to the Lioness Strength Training and Bonuses Immediately <br className="d-none d-md-block"/>After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.</em></p>
                    </div>
                </div>
            </section>

            {/*fb testimonials*/}
            <section className="container pb--0">
                <h2 className="h2--sm mb--30">Betty Rocker <span className="d-inline-block txt--blue">Program Results</span></h2>
                <div className="row align-items-center img--stack-wrapper">
                    <div className="col-md-6 mb--50 mb--xs-30">
                        <div className="img-stack mb--50 mb--xs-30">
                            <img className="lazy" src="/images/lioness/fb-testimonial-1.jpg" alt="" width="544" height="142"/>
                        </div>

                        <div className="img-stack mb--50 mb--xs-30">
                            <img className="lazy" src="/images/lioness/fb-testimonial-3.jpg" alt="" width="548" height="253"/>
                        </div>

                        <div className="img-stack">
                            <img className="lazy" src="/images/lioness/fb-testimonial-5.jpg" alt="" width="537" height="135"/>
                        </div>
                    </div>
                    <div className="col-md-6 mb--50 mb--xs-30">
                        <div className="img-stack mb--50 mb--xs-30">
                            <img className="lazy" src="/images/lioness/fb-testimonial-2.jpg" alt="" width="547" height="238"/>
                        </div>

                        <div className="img-stack mb--50 mb--xs-10">
                            <img className="lazy" src="/images/lioness/fb-testimonial-4.jpg" alt="" width="547" height="307"/>
                        </div>
                    </div>
                </div>

            </section>

            {/*guarantee*/}
            <div className="container pt--30 pb--50">
                <div className="box-guarantee box-guarantee--blue">
                    <img src="/images/guarantee.png" alt="" width="239" height="245" className="lazy no-shadow"/>
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className="h2--sm txt--white mb--20 txt--smd-left">60 – Day Money Back GUARANTEE</h2>

                            <p className="txt--white lh--auto">Take the first step toward improving your health by trying my 8-week Lioness Strength Training Program. If you don’t feel healthier, more energized and simply amazing, you won’t pay. If you’re not satisfied, simply contact customer service within 60 days for a full refund.</p>

                            <p className="txt--white txt--22 fw--black"><span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc</p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="bg--section js-section py--50 pb--xs-30" id="faq">
                <div className="container">
                    <h2 className="mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                </div>
                <div className="container container--xs">

                    <Faqs color="blue" productName='lioness'/>

                </div>
            </section>
        </>
    )
}
export default Lioness
