import React, {useEffect, useState} from "react"
import Faqs from "../../components/Faqs";
import Tabs from "../../components/Tabs"
import {Helmet} from "react-helmet";
import RockAndRestoreFormula from "../RockAndRestoreFormula";

const RockAndRestore = ({updateActiveTab}) => {
    const [activeTab, setActiveTab] = useState('about')

    const handleActive = (el) => {
        setActiveTab(el);
    }

    useEffect(() => {
        setActiveTab(updateActiveTab)
    },[updateActiveTab])

    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Rock and Restore"/>
                <meta property="og:description" content="This great tasting fruit punch formula contains 30 servings of all 9 of the essential amino acids (including the BCAA’s) in their free form for rapid absorption and metabolic use so you can rock your workouts, build lean muscle, recover faster, boost your immune system and improve cognitive function."/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="/images/fb-share/rr-fb.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Rock and Restore." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Rock and Restore"/>
                <meta name="twitter:description" content="This great tasting fruit punch formula contains 30 servings of all 9 of the essential amino acids (including the BCAA’s) in their free form for rapid absorption and metabolic use so you can rock your workouts, build lean muscle, recover faster, boost your immune system and improve cognitive function."/>
                <meta name="twitter:image" content="/images/fb-share/rr-fb.jpg"/>
                <meta name="twitter:image:alt" content="Rock and Restore." />
                <meta name="twitter:card" content="summary_large_image"/>
                <title>Rock and Restore</title>
            </Helmet>

            {/*tab menu*/}
            <section className="pt--70 pt--md-30 pb--0">

                <Tabs active={activeTab} handleActive={handleActive}/>

                {/*tab content*/}
                <div className="tab-content">
                    {/*tab content about*/}
                    { (activeTab === "about") &&
                    <div id="about">
                        {/*rock and restore your body*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--50 mb--md-20 h2--supplements"><span className="txt--pink">Rock and Restore</span> your body with all the essential amino acids<br className="d-none d-xl-block"/> (including the BCAA’s) in one convenient, great-tasting <span className="d-inline-block">sports drink!</span></h2>

                            <img src="/images/supplements/rock-restore/img1.jpg" className="mr--10 no-shadow pull-md--right d-none d-lg-block mx--auto mb--30" alt="Rock And Restore Your Body." width="452" height="533"/>

                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="mb--30 mb--xs-20 border-2 border-pink radius-5 bg--supplements-section py--15 px--20 p--xs-10">
                                        <h6 className="txt--20 txt--xxs-18 mb--0"><strong className="fw--medium">Taking <span className="txt--pink">Rock and Restore</span> will help you rock your workouts, build lean muscle, recover faster, boost your immune system and improve cognitive function.</strong></h6>
                                    </div>

                                    <img src="/images/supplements/rock-restore/img1.jpg" className="mr--10 no-shadow pull-md--right d-block d-lg-none mx--auto mb--30 mb--xs-20" alt="Rock And Restore Your Body." width="452" height="533"/>

                                    <div className="d-block d-md-flex mb--30 mb--xs-20">
                                        <div className="d-flex flex-row flex-md-column justify-content-center justify-content-md-between align-items-center mw--60">
                                            <img src="/images/supplements/rock-restore/icon1.svg" alt="Amino Acids." className="no-shadow radius-0 align-self-center align-self-md-start mb--20 mr--35 mr--md-0" width="60" height="60"/>
                                            <img src="/images/supplements/rock-restore/icon2.svg" alt="Amino Acids." className="no-shadow radius-0 align-self-center align-self-md-start mb--20 mr--35 mr--md-0" width="60" height="60"/>
                                            <img src="/images/supplements/rock-restore/icon3.svg" alt="Amino Acids." className="no-shadow radius-0 align-self-center align-self-md-start mb--sm-20" width="60" height="60"/>
                                        </div>

                                        <div className="pl--sm-0 pl--25">
                                            <p className="p--supplements">Amino acids are involved in virtually ALL of your body’s cellular processes. You may associate them the most with their role in muscle building and exercise support, but they’re also the raw materials needed to <strong>make important neurotransmitters (such as dopamine and serotonin)</strong>, hormones, enzymes, and immune system antibodies. They also serve crucial roles in <strong>cellular energy generation.</strong></p>
                                            <p className="p--supplements">When you eat dietary protein (including protein in your meals, or in a protein shake), the amino acids must be broken down before your body can use them for all the essential jobs they do.</p>
                                        </div>
                                    </div>

                                    <div className="border-2 border-pink radius-5 bg--supplements-section py--15 px--20 mb--md-20 p--xs-10">
                                        <h6 className="txt--20 txt--xxs-18 mb--0"><strong className="fw--medium">Amino acids in their free form (like they are in <span className="txt--pink">Rock and Restore</span>) are immediately available for absorption, and can be used more quickly metabolically than the amino acids your body has to break down from dietary protein.</strong></h6>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*what are the essential amino acids title*/}
                        <section className="bg--pink py--30">
                            <div className="container">
                                <h2 className="h2--supplements txt--white txt--center lh--1"><strong className="fw--medium">What are the essential amino acids, and where do the BCAA’s fit in?</strong></h2>
                            </div>
                        </section>

                        {/*what are the essential amino acids*/}
                        <section className="bg--supplements-section section--padding-lg">
                            <div className="container">
                                <img src="/images/supplements/rock-restore/img2.jpg" className="no-shadow pull--left d-none d-md-block" alt="Rock And Restore." width="428" height="587"/>
                                <p className="p--supplements">Of the 20 amino acids, there are 9 that the body cannot make on its own and must get from dietary sources. These 9 are called the <strong>Essential Amino Acids</strong> and they are all found in <strong><span className="txt--pink">Rock and Restore.</span></strong></p>
                                <img src="/images/supplements/rock-restore/img2.jpg" className="no-shadow pull--left d-block d-md-none" alt="Rock And Restore." width="428" height="587"/>
                                <p className="p--supplements">Unlike fat and carbohydrates, which the body can store easily for later use in the liver and fat cells, the body doesn’t store amino acids for later use - which is why it’s important to include protein regularly throughout the day in your meals.</p>
                                <p className="p--supplements">The protein you eat gets broken down into amino acids, but different foods provide different combinations of amino acids and not every food contains all the essential amino acids. Plus, the amino acids you eat aren’t immediately available for use as they have to go through the digestive process first.</p>
                                <p className="p--supplements">Without adequate consumption of all of the essential amino acids, the body may break down healthy tissue like muscle tissue to obtain the amino acids needed to perform essential functions like making energy, enzymes, neurotransmitters, hormones and its immune response role.</p>
                                <p className="p--supplements">The <strong>branch-chain amino acids</strong> (BCAA’s) are 3 of the best known essential amino acids because of their ability to <strong>support muscle growth, muscle repair and recovery.</strong> <br className="d-none d-lg-block"/><strong><span className="txt--pink">Rock and Restore</span></strong> contains all 3 branch-chain amino acids, including the optimal dose of leucine, considered the most anabolic BCAA (promoting muscle protein synthesis).</p>

                                <div className="box-icon">
                                    <img src="/images/supplements/rock-restore/icon4.svg" alt="Icon drink." className="no-shadow radius-0" width="40" height="65"/>
                                    <h6 className="txt--20 txt--xxs-18"><strong className="fw--medium">Drink <span className="txt--pink">Rock and Restore</span> before, during or after your workout to not only provide your body with the building blocks needed for muscle protein synthesis, repair and recovery of muscle tissue but all the other benefits in essential amino acids as well!</strong></h6>
                                </div>

                            </div>
                        </section>

                        {/*the rock and restore formula title*/}
                        <section className="bg--pink py--30">
                            <div className="container">
                                <h2 className="h2--supplements txt--white txt--center lh--1"><strong className="fw--medium">The Rock and Restore Formula</strong></h2>
                            </div>
                        </section>

                        {/*the rock and restore formula*/}
                        <section className="bg--supplements-section section--padding-lg">
                            <div className="container">                                

                                <p className="p--supplements txt--center mb--40 mb--xs-20">Read more about the Rock and Restore Formula and learn more about each essential amino acid<br className="d-none d-lg-block"/> and its roles in your body.</p>

                                <RockAndRestoreFormula/>

                            </div>
                        </section>

                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block txt--pink">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="no-shadow radius-0 mb--20 img--lg" width="456" height="75"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>
                    </div>}

                    {/*tab content nutrition*/}
                    { (activeTab === "nutrition") &&
                    <div id="nutrition">

                        {/*the rock and restore formula*/}
                        <section className="section--padding-lg">
                            <div className="container">
                                <h2 className="h2--supplements txt--center lh--1 mb--30"><strong className="fw--medium">The Rock and Restore Formula</strong></h2>

                                <p className="p--supplements txt--center mb--40 mb--xs-20">Read more about the Rock and Restore Formula and learn more about each essential amino acid<br className="d-none d-lg-block"/> and its roles in your body.</p>

                                <RockAndRestoreFormula/>

                            </div>
                        </section>
                    </div>}

                    {/*tab content whybuy*/}
                    { (activeTab === "quality") &&
                    <div id="quality">
                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                            <div className="row">
                                <div className="col-xs-12 col-lg-6">
                                    <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                    <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                    <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <div className="video bg--white video--16-9 mb--30">
                                        <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                    </div>

                                    <div className="txt--center">
                                        <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="no-shadow radius-0 mb--20 img--lg" width="456" height="75"/>
                                      </div>
                                </div>
                            </div>

                            <div className="supplements--box d-flex align-items-center">
                                <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                            </div>
                        </section>
                    </div>}

                    {/*tab content faq*/}
                    { (activeTab === "faq") &&
                    <div id="faq">
                        {/*faq*/}
                        <section className="container container--xs section--padding-xl" id="faq">
                            <h2 className="h2--supplements mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                            <Faqs productName='rr'/>
                        </section>
                    </div>}

                </div>
            </section>

            {/*pink section*/}
            <section className="bg--pink py--30">
                <div className="container">

                    <div className="row flex-wrap align-items-center">
                        <div className="col-xs-12 col-lg-4 txt--center-md txt--right order-2 order-lg-1">
                            <img src="/images/supplements/betty.png" className="no-shadow radius-0" alt="Betty Image." width="214" height="311"/>
                        </div>

                        <div className="col-xs-12 col-lg-8 order-1 order-lg-2">
                            <h3 className="h3--supplements ls--xxl txt--white d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row txt--uppercase mb--30"><span className="no-shrink">whole body <strong className="fw--light">by</strong></span> <img src="/images/supplements/logo-white.png" className="no-shadow radius-0 img--header ml--10" alt="White logo" width="416" height="73"/></h3>
                            <p className="txt--white p--supplements txt--center-md mb--0 mb--md-20">At Whole Betty, we believe that Whole Foods should form the foundation for what you put in your body. That’s why our ingredients are mindfully sourced and our blends are consciously created so you get the maximum health benefit, every time.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/*what our rockstars are saying*/}
            <section className="bg--supplements-section section--padding-xl">
                <div className="container">
                    <h2 className="mb--80 mb--md-20 h2--supplements">What Our Rockstars are Saying About<br className="d-none d-md-block"/> <span className="txt--pink">Rock and Restore…</span></h2>

                    <div className="row">

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Maria C." src="https://player.vimeo.com/video/568923436" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“Give your body those essential amino acids to help with muscle repair, and keep you rolling through the next day, and all the way through day 30 of your challenges - and on to the next!”</i><span className="d-block">- <strong className="fw--bold">Maria C.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Amanda M." src="https://player.vimeo.com/video/568923364" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping a bit more.”</i><span className="d-block">- <strong className="fw--bold">Amanda M.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Trista A." src="https://player.vimeo.com/video/568923472" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“This stuff is amazing. After my Betty Rocker workouts if I really push myself, I get super sore the next day. But this delicious Rock and Restore really helps!”</i><span className="d-block">- <strong className="fw--bold">Trista A.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Lucy M." src="https://player.vimeo.com/video/568923458" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“It is amazing with ice! But when I’m working out I like it at room temperature. I like to take it during my workout and I like how I still have some energy at the end.”</i><span className="d-block">- <strong className="fw--bold">Lucy M.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Heidi R." src="https://player.vimeo.com/video/568923419" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“It has been a wonderful addition to the routine I do. It dissolves instantly, tastes amazing…I love the portability - it’s just powder, so you can take it when you go.”</i><span className="d-block">- <strong className="fw--bold">Heidi R.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Angela J." src="https://player.vimeo.com/video/568923394" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>"This will help your muscles knit back together without a lot of pain and strain."</i><span className="d-block">- <strong className="fw--bold">Angela J.</strong></span></p>
                            </div>
                        </div>
                    </div>

                    <div className="row gutters-responsive">
                        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center justify-content-center">
                            <div className="testimonial-rr">
                                <img src="/images/supplements/rock-restore/testimonial-1-n.jpg" className="no-shadow radius-0" alt="Rating Amanda M." width="550" height="717"/>
                                <p>Amanda M.</p>
                                <span><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/></span>
                            </div>                            
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center justify-content-center">
                            <div className="testimonial-rr">
                                <img src="/images/supplements/rock-restore/testimonial-2-n.jpg" className="no-shadow radius-0" alt="Rating Courtney K." width="550" height="717"/>
                                <p>Courtney K.</p>
                                <span><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/></span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center justify-content-center">
                            <div className="testimonial-rr">
                                <img src="/images/supplements/rock-restore/testimonial-3-n.jpg" className="no-shadow radius-0" alt="Rating Jessica B." width="550" height="717"/>
                                <p>Jessica B.</p>
                                <span><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/></span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center justify-content-center">
                            <div className="testimonial-rr">
                                <img src="/images/supplements/rock-restore/testimonial-4-n.jpg" className="no-shadow radius-0" alt="Rating Maria C." width="550" height="717"/>
                                <p>Maria C.</p>
                                <span><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/><i className="fas fa-star"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default RockAndRestore
