import React, {useEffect, useState} from "react";
import {scrollTo} from "../utilities/scrollTo";

const ScrollTopButton = ({isSupplement, handleActiveTab}) => {

    const [scrolled, setScrolled] = useState(false)
    const [isResponsive, setIsResponsive] = useState(false)
    const [menuOverlay, setMenuOverlay] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleResize)
        handleScroll()
        handleResize()
    })

    const handleResize = () => {
        if(window.innerWidth < 992) {
            setIsResponsive(true)
        } else {
            setIsResponsive(false)
        }
    }

    const handleScroll = () => {
        const offset = window.scrollY;

        if(offset > 1000) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }

    const scrollToSection = (e) => {
        e.preventDefault()

        let tab = e.target
        let tabId = tab.getAttribute('href')

        scrollTo(e);

        handleMenu()

        if(isSupplement) {
            handleActiveTab(tabId)
        }
    }

    const handleMenu = () => {
        document.body.classList.toggle('responsive-menu-open')
        document.body.classList.contains('responsive-menu-open') ? setMenuOverlay(true) : setMenuOverlay(false)
    }

    return (

        (!isResponsive) ? (
            <button onClick={(event)=>scrollTo(event, true)} className={`btn--top ${ scrolled ? 'active' : '' }`}><i className="fas fa-chevron-circle-up" aria-label="Scroll To Top"/></button>
        ) : (
          <>
              {(menuOverlay) ? (
                  <span
                      aria-label="Overlay."
                      role="button"
                      tabIndex="0"
                      onClick={handleMenu}
                      onKeyDown={handleMenu}
                      className="overlay"/>
              ) : ''}
              <button onClick={(event)=>scrollTo(event, true)} className={`btn--top ${ scrolled ? 'active' : '' }`}><i className="fas fa-chevron-circle-up" aria-label="Scroll To Top"/></button>
              <button onClick={handleMenu} className="responsive-menu-btn">
                  <span className="txt--uppercase">Jump to Section</span>
                  <div className="menu-toggle">
                      <span/>
                      <span/>
                      <span/>
                  </div>
              </button>

              <div className="sidebar-menu">
                  <div className="sidebar-menu__top">
                      <p className="txt--16 m--0">You can now go to any desired section on this page.</p>
                  </div>
                  <div className="sidebar-menu__content">
                      { isSupplement ? (
                          <ul>
                              <li><a onClick={scrollToSection} href="#about" className="d-block fw--regular">About</a></li>
                              <li><a onClick={scrollToSection} href="#nutrition" className="d-block fw--regular">Nutrition</a></li>
                              <li><a onClick={scrollToSection} href="#quality" className="d-block fw--regular">Why Buy</a></li>
                              <li><a onClick={scrollToSection} href="#faq" className="d-block fw--regular" >Faq</a></li>
                              <li><a onClick={scrollToSection} href="#order-now" className="d-block fw--regular txt--pink" >Add to Cart</a></li>
                          </ul>
                      ) : (
                          <ul>
                              <li><a onClick={scrollToSection} href="#what-is-included" className="d-block fw--regular">Included</a></li>
                              <li><a onClick={scrollToSection} href="#results" className="d-block fw--regular">Results</a></li>
                              <li><a onClick={scrollToSection} href="#faq" className="d-block fw--regular">FAQ</a></li>
                              <li><a onClick={scrollToSection} href="#order-now" className="d-block fw--regular" >Start Now</a></li>
                              <li><a onClick={scrollToSection} href="#order-now" className="d-block fw--regular txt--pink" >Add to Cart</a></li>
                          </ul>
                      )}
                  </div>
              </div>
          </>
        )

    )
}
export default ScrollTopButton
