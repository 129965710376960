const faqsData = {
    '7dmp' : [
        {
            title: 'When should I do this program?',
            content: '<p>When you are ready to start looking and feeling your best, you can start anytime! It can be tough to commit to a long-term program, and you don’t have to.</p>\n' +
                '     <p>This is the perfect jumpstart into healthy eating that will fit into your life, and not force you into a time-consuming or expensive protocol that you can’t follow easily long-term.</p>\n' +
                '     <p>Start out with something you can do right now. Try out a week of healthy eating and track your progress for just 7 days to see how much difference 1 week can make to how you feel!</p>'
        },
        {
            title: 'Do I have to follow the menu guide?',
            content: '<p>No, with the 3 eating types you can choose to start out very simply with just a couple easy recipes and shortcuts. Or you can use the menus as a guide to help you make good choices when you’re dining out.</p>\n' +
                     '<p>If you’re following the menu guide, you can swap the order of your meals around however is most convenient for you.</p>'
        },
        {
            title: 'What exactly does the complete Plan include?',
            content: '<p>The 7-day meal plan sets you up with a guide to nutrition, 3 eating types to choose from and a week of delicious, easy recipes for breakfast, snacks, smoothies and entrees. Plus menu guides for each day, and a customized guideline for each of the eating types.</p>'
        },
        {
            title: 'Does the Plan include Vegetarian options?',
            content: '<p>The eating plan includes complete Vegetarian* options for every recipe that has meat or fish so you can make the plan work for you.</p>\n' +
                     '<p><i>*Vegan options are not included.</i></p>'
        },
        {
            title: 'How do I know what my Eating Type is?',
            content: '<p>Which one of these sounds the most like you? It’s ok if there are more than one, or even all 3! In this plan, you’ll have a custom set up for each of the types so you can try them all.</p>\n' +
                     '<p><strong>If you’re a MINIMAL eating type,</strong> you need <strong>SHORTCUTS</strong> to save you time and EATING MADE EASY that gets results – even if you are super busy, don’t cook much or are traveling.</p>\n' +
                     '<p><strong>If you’re a HYBRID eating type,</strong>  you need a <strong>FLEXIBLE,</strong> easy-to-use guide that helps you stay healthy and get results on a busy schedule. You can do some light prep, but have the flexibility to take healthy shortcuts.</p>\n' +
                     '<p><strong>If you’re a BATCH eating type,</strong> you need an <strong>EFFICIENT,</strong> simple map laid out to follow so you can conveniently prep your food for the week in one main session and save time all week.</p>'
        },
    ],
    '30dmp' : [
        {
            title: 'What do I get with the Meal Plan and Eating Guide?',
            content: '<p>The 30-Day Challenge Eating Guide and Meal Plan is a 30-day eating plan that includes a comprehensive guide to how to burn fat, boost your metabolism and combine foods properly so you can easily create a healthy lifestyle while carving out a lean physique.</p>\n' +
                '     <p>It includes daily menus so you can easily combine the right meals around your workouts, eat optimally without counting calories or restricting your food intake, leaves room for eating out and those special treats and occasions and will totally teach you how to do this yourself.</p>\n' +
                '     <p>It includes grocery lists so you can shop with ease for the simple recipes, and options for how to prep your food – whether you’d like to pre-make some of the entrees and staple dishes, or do it on the fly.</p>\n' +
                '     <p>The recipes are delicious, easy to make, and are all gluten and dairy free. Your plan also contains Vegetarian options for variety or if you are following that eating style.</p>\n' +
                '     <p>You also get 3 amazing BONUS books, including the Swaps and Substitutions Guide, Recipes Guide so you have all your 30-day Challenge recipes from the meal plans in one place, and a Healthy Desserts Cookbook so you can enjoy treats guilt-free!</p>\n' +
                '     <p>All these recipes will give you lots of great ideas and you can swap things in and out of your meal plans for additional variety.</p>'
        },
        {
            title: 'Is the 30-Day Challenge Meal Plan different than your other eating plans?',
            content: '<p>Yes! This program was created specially for the 30-day Challenge. While I use a similar format for all of my meal plans to teach you how to combine food, you’ll get new, easy to make recipes and unique meal plans to follow.</p>'
        },
        {
            title: 'I\'m really short on time - how long will it take me to get set up?',
            content: '<p>The recipes are super simple and easy to make, and none of them take long to prepare – often much less time (not counting cooking time, if that’s needed).</p>\n' +
                '     <p>I’m a big fan of bouncing back and forth between setting myself up with a strategic batch food prep session – and cooking easy meals as I go through the week. The eating guide and meal plans will give you options so you can do whatever is easiest for you each week.</p>\n' +
                '     <p>Sometimes it saves more time to cook several staple meals on a Sunday, and other times it’s just easier to cook as you go. And with the easy to use guide, you’ll be able to order out with confidence and stick to your nutrient guidelines and stay on track, even if you’re not cooking.</p>'
        },
        {
            title: 'Will I need to buy fancy, hard to find ingredients to make these recipes?',
            content: '<p>Not at all! Most of the ingredients can be found in a regular grocery store or ordered online to save money if you live someplace remote.</p>\n' +
                '     <p>You won’t need to buy some rare fruit from a foreign country to see results – just eat real food! I’ve also included a very comprehensive swaps and alternatives guide for you, so you can confidently change up ingredients as needed.</p>\n' +
                '     <p>And if you ever have a question, me and the girls at Team Betty Rocker are a quick email away – just write us at support@thebettyrocker.com and we’ll be more than happy to help you with a substitution or any question you have!</p>'
        },
        {
            title: 'How Much Weight Will I Lose During the Challenge?',
            content: '<p>To be honest, I don’t have an answer to that because it’s a little different for everyone. If you’re in the habit of weighing yourself, one of the important things to know is that your weight on the scale won’t actually tell you much about your body composition – as in how much water you’re carrying, how much muscle, and how much fat.</p>\n' +
                '     <p>You’ll most likely start to notice your body changing within a week. You’ll notice you’re feeling stronger, you might notice your clothes fitting differently (even without a lot of weight loss, which means your body composition is changing).</p>\n' +
                '     <p>You will be losing inches and dress sizes as the challenge progresses and you stick with the workouts – and challengers who use the meal plan with the workouts have noticeably faster progress.</p>\n' +
                '     <p>Muscle is denser than fat, and takes up less space in your body – so as you lose fat and add muscle, you may not see the number on the scale going down as fast as you expect, even though you are noticing the shape and tone of your body changing.</p>\n' +
                '     <p>Everyone is completely unique and the rate at which we lose fat is different. If you want to lose the most fat and change the shape of your body the fastest, using the 30-day Challenge Meal Plan with the challenge workouts will give you the fastest and longest-lasting results.</p>\n' +
                '     <p>Exercise makes you FEEL amazing – more energy and better sleep are some side effects of regular exercise. But you simply cannot out-train a bad diet – which is why it’s so important to have the right combination of nutrients, and learn how to do this stuff yourself so you can make it a lasting LIFESTYLE.</p>\n' +
                '     <p>To really see the changes your body is experiencing as you go through the challenge, you DEFINITELY should take PROGRESS PICTURES every week, and NOT rely just on the scale.</p>\n' +
                '     <p>In fact, I suggest not stepping on the scale at all during the challenge. Use your clothes and your pictures as a way to track progress the best! You will see amazing changes when you stick to the plan!</p>'
        },
        {
            title: 'Will the Meal Plan be Shipped to My House?',
            content: '<p>Your Eating Guide and Meal Plan will be Immediately Accessible after your purchase in my exclusive customers only VIP area.</p>\n' +
                '     <p>This program is 100% digital – which means not physical books. The reason for this is that when I looked into the cost of printing all of the amazing components you are purchasing, the cost alone was multiple times what I am charging for the information. I wanted to make this information available to you without the added cost so you can actually benefit from it.</p>\n' +
                '     <p>What’s great is that it’s available on any device you’re on, and you can print out any part or parts of it yourself – in fact, I’ve broken most of the programs into separate documents to make it very easy for you to print just the recipes for example.</p>\n' +
                '     <p>Right after you purchase, you’ll get an email (to the email you use on purchase) with a secure login and password so you can INSTANTLY access your program materials online (you can download them and save them to any of your devices).</p>\n' +
                '     <p>You’ll have forever access to your new program, and be able to log in to your secure area from any device, anytime.</p>\n' +
                '     <p>If you pay with Paypal, make sure the email you get your Paypal emails at is one you check, as that is where your program will go. It’s as simple as an email to my customer support team at support@thebettyrocker.com if you need to update that email address.</p>'
        },
        {
            title: 'Do I have to follow the meal plans and recipes exactly? What if I have an allergy, or don\'t like an ingredient?',
            content: ' <p>Not at all. The cookbooks and recipes have a wide variety of gluten free, dairy free options to choose from – and you can always choose a different recipe to suit your preferences from one of your bonus cookbooks!</p>\n' +
                '      <p>I’ve also included a very comprehensive swaps and alternatives guide for you, so you can confidently change up ingredients as needed.</p>\n' +
                '      <p>If you need some help, me and the girls at Team Betty Rocker are a quick email away – just write us at support@thebettyrocker.com and we’ll be more than happy to help you with a substitution or any question you have!</p>'
        }
    ],
    '90dch' : [
        {
            title: 'What exactly is included?',
            content: '<p>The 90 Day Challenge comes complete with 90 days of progressive workouts, (including HIIT, yoga, and core training,) full-length follow-along workout videos for every workout, a mobility and motivational goal setting series, as well as an 8 week meal plan with grocery lists, recipes, daily and weekly menus, and detailed food prep instructions whether you want minimal prep or food for the entire week.</p>\n' +
                '     <p>You’ll have a fully laid out workout calendar, which can be followed just as it is written or modified for your fitness level or personal schedule. I’ll show the breakdown from beginner to advanced, low to high impact so you can do this no matter where you are, no matter what your fitness level.</p>\n' +
                '     <p>You’ll also get amazing bonus gifts, including the ENTIRE 30 Day #makefatcrychallenge video collection and a 90 day coaching e-course!</p>'
        },
        {
            title: 'How long are the workouts?',
            content: '<p>Your workouts will be between 15-20 minutes.</p>\n' +
                '     <p>If you are advancing, or have more time/energy, you are welcome to repeat the workout again. This is a great way to progress even further.</p>'
        },
        {
            title: 'How many workouts are there per week, and how are they formatted to meet my fitness level?',
            content: '<p>You’ll have 5 workouts per week, plus a special bonus activity to keep you motivated and on track to reaching your goals. Each week, the workouts build in complexity, increasing your strength and endurance. I’ll show you the breakdown from beginner to advanced, low to high impact, so you can do this no matter where you are and no matter what your fitness level is.</p>\n' +
                '     <p>Not only will I be working out with you in the 90 day challenge, I’ve invited Coach Nikki, Coach Amanda and Coach Alyssa from Team Betty Rocker to join me!</p>\n' +
                '     <p>You and I will work out together one-on-one most days of the week, but you’ll also get a dedicated ABS workout in each week with Coach Amanda, a dedicated yoga workout with Coach Nikki, and Coach Alyssa has created a series of weekly mobility and motivation activities for your active recovery day to keep you on track to reaching your goals.</p>\n' +
                '     <p>You can follow along with the 90 Day calendar as it is written or move the days around to fit your schedule. Just make sure that you aren’t skipping your rest days!</p>'
        },
        {
            title: 'Do I need equipment for these workouts or a gym?',
            content: '<p>No, the workouts can all be done with no equipment at all, just your own body, in any location.</p>'
        },
        {
            title: 'Do I get Rest Days, and is it ok for me to take extra rest days?',
            content: '<p>Rest days are built right in and you’ll get 2 per week – one of which is a mobility class to restore you and keep you on track to the best results. FEEL FREE to change which days you workout and which days you rest from what I’ve suggested in the calendar for you.</p>\n' +
                '     <p>FEEL FREE to take additional rest days anytime, and pick back up with the next workout. We all have crazy weeks sometimes, get sick or get sidetracked. It’s OK. Just pick back up where you left off. You can make this program work for your schedule!</p>\n' +
                '     <p>The 90 Day Challenge is a very effective program as it is written, and you will see great results if you are consistent, and follow the nutrition guidelines.</p>'
        },
        {
            title: 'Can I do other workouts along with the challenge workouts?',
            content: '<p>I recommend sticking with one challenge or program at a time. If you find that you have more time/energy for exercise, I encourage you to do the video partially or all the way through a second time.</p>\n' +
                '     <p>The program is written to work specific muscle groups on specific days, then rest them by either switching to different muscle groups the next day, or giving you a recovery day. Muscle grows after we’ve trained it, during rest- not while we’re training. This mindful progression is what creates the results.</p>\n' +
                '     <p>If you overtrain, it’s easy to burn out, or worse, get injured. I know that YOU know your body best. I just ask that you are mindful not to push too hard, thinking that will give you better results.</p>'
        },
        {
            title: 'Can I stop and start the program? Can I do it more than once?',
            content: '<p>Yes you can! Many people use it in 30 day blocks after they have gone through it the first time. It’s such a great resource to have that you can come back to it again and again. Having access to an entire library of workouts to do anywhere is an amazing game-changing tool that will help you stay consistent with your fitness!</p>'
        },
        {
            title: 'Do the workouts in the 90 day Challenge expire?',
            content: '<p>Absolutely not – the 90 day Challenge is yours to keep and you can access it anytime via your secure portal with the login and password we’ll give you. If you ever lose it, just shoot us an email at support@thebettyrocker.com, and we’ll be happy to resend it to you.</p>\n' +
                '     <p>Your 90 day program includes a complete 90 day home workout plan, an 8-week done for you meal plan and several amazing bonuses, including full access to the 30-day #makefatcrychallenge.</p>\n' +
                '     <p>Unlike the Free 30 Day Challenge workouts that were delivered to your inbox daily with only 24 hours of access, you will have immediate and permanent access to the entire program through your special login area.</p>\n' +
                '     <p>There is a special daily email push that comes with the challenge which you can choose the start date for. That will run its course as you do all 90 days, but should you wish to do the program again, contact us and we’ll happily reset it for you!</p>'
        },
        {
            title: 'How do I use the meal plans?',
            content: '<p>The meal plans are provided to give you a foundation and framework for healthy eating that you can use for years to come.</p>\n' +
                '     <p>The recipes in the program are all very simple and made with whole foods. They are all gluten and dairy free and include vegetarian options. The recipes combine the right nutrients to give you amazing energy, better sleep, clear skin, and support the lean muscle you’ll gain and fat you’ll lose with the workout program.</p>\n' +
                '     <p>You’re not “locked in” to the plans like a “diet,” instead you will have clear guidelines around how to make the best choices and set yourself up to suit your lifestyle. You’ll have over 120 healthy, delicious recipes to choose from – as well as daily menus to either use as guidelines – or follow if you prefer a more structured approach.</p>'
        },
        {
            title: 'How soon will I get my program and videos?',
            content: '<p>Right away! As soon as you enter your billing information and complete checkout, you’ll receive an email with a secure login and password so you can instantly access your brand new program from ANY device in an exclusive member’s area. You’ll have forever access to this once you have purchased it!</p>\n' +
                '     <p>Be sure to use your best email address when you purchase, type your address carefully, and check your spam if you don’t see it. If you use Paypal to check out, be sure you know the email address associated with that account as your product login will go there. If you can’t find your login details or wish to update your contact information, you can write to\n' +
                '         <a href="mailto:support@thebettyrocker.com">support@thebettyrocker.com</a></p>\n' +
                '     <p>You’ll be able to set your “start date” for the program directly from your orientation emails!</p>'
        },
        {
            title: 'What if I have a question or need support?',
            content: '<p>I’ve got you covered! Feel free to reach out to my friendly customer support team, who is looking forward to helping you at\n' +
                '        <a href="mailto:support@thebettyrocker.com">support@thebettyrocker.com</a>.</p>\n' +
                '     <p>They’re online 7 days a week to assist. You can also reply to any of the emails you’ll be getting in your coaching e-course and we’ll be delighted to help you!</p>'
        },
    ],
    'bfs' : [
        {
            title: 'What is the Body Fuel System, and what does it include?',
            content: '<p>This program will give you a foundation of nutrition and an understand how food works in your body (and how to use and apply that information on a daily basis) so you can…</p>\n' +
                '     <ul>\n' +
                '        <li>achieve and maintain your ideal weight</li>\n' +
                '        <li>get out of a fat-storing state and into a fat-burning one</li>\n' +
                '        <li>feel great about the choices you make with food</li>\n' +
                '        <li>and vastly increase your health.</li>\n' +
                '    </ul>\n' +
                '    <p>The program does not count calories, or ask you to drink a shake every other meal. It will teach you how to eat in a sustainable, healthy way to support your body’s energy, improve your metabolism, boost your immune system, lower inflammation, melt off excess body fat and support an active lifestyle. You’ll still be able to enjoy treats and special occasions guilt-free when you have a solid base to draw from daily.</p>\n' +
                '    <p><strong>You get a complete, 6-week done for you meal plan. The meal plans include:</strong></p>\n' +
                '    <ul>\n' +
                '       <li>Healthy, delicious, nutrient dense recipes made with whole foods (gluten and dairy free, Vegetarian options included)</li>\n' +
                '       <li>3 options for each week - 1 if you’re short on time and super busy, 2 if you’re into doing batch prep, and 3 if you’re somewhere in the middle!</li>\n' +
                '       <li>Grocery lists for each week</li>\n' +
                '       <li>Daily menus for each day of the week</li>\n' +
                '    </ul>\n' +
                '    <p>Your program includes a comprehensive <strong>Eating Guide,</strong> that will teach you how everything works so you can use this information for life.</p>\n' +
                '    <p>Here’s a chapter synopsis of the Eating Guide, and what you will learn:</p>\n' +
                '    <ul>\n' +
                '       <li>Why a System is Not the Same As a “Diet”</li>\n' +
                '       <li>Stay On Track: Observe and Report</li>\n' +
                '       <li>Getting Started in 7 Easy Steps</li>\n' +
                '       <li>Fit, Healthy, and Confident: Dialing in the System for Your Goals</li>\n' +
                '       <li>Eating Around Workouts and Rearranging the Order of Your Meals</li>\n' +
                '       <li>Protein: The Captain of the Nutrients</li>\n' +
                '       <li>Carbohydrates: Thor’s Hammer and How to Throw It</li>\n' +
                '       <li>Fat: One of Your Most Powerful Allies</li>\n' +
                '       <li>Hate Having Superpowers? Don’t Eat Greens.</li>\n' +
                '       <li>Gluten, Dairy, Food Allergies and Listening to Your Body</li>\n' +
                '       <li>”NSA” – How to Create a No-Strings Attached Relationship with Junk Food</li>\n' +
                '       <li>How Much Does Eating Healthy Cost?</li>\n' +
                '       <li>Six Ways to Win at Grocery Shopping</li>\n' +
                '       <li>Dominate Your Kitchen like a Boss</li>\n' +
                '       <li>Kitchen and On-the-Go Tools</li>\n' +
                '       <li>Making it Happen in the Real World: Time Commitment and -Meal Frequency</li>\n' +
                '       <li>Liquid Fuels: High and Low Octane Beverages and How to Use Them</li>\n' +
                '       <li>Five Hacks to Crush Food Cravings</li>\n' +
                '       <li>Dining Out Strategies</li>\n' +
                '       <li>Travel Tips</li>\n' +
                '       <li>Dealing With People Who Don’t Support Your Choices</li>\n' +
                '    </ul>\n' +
                '    <p><strong>You’ll also receive some great Bonus gifts!</strong></p>\n' +
                '    <ul>\n' +
                '       <li>Dining Out and Travel</li>\n' +
                '       <li>Healthy Shortcut Foods Guide</li>\n' +
                '       <li>Swaps and Substitutions Guide</li>\n' +
                '       <li>Eat This, Not That</li>\n' +
                '    </ul>'
        },
        {
            title: 'What are the foods that I can expect to eat if I follow the Body Fuel System?',
            content: '<p>You’ll be eating whole foods from Mother Nature while you’re using the Body Fuel System. Of course you can swap things in that are more shortcut foods, but the more you eat whole foods, the more nutrients your body gets to work with - which works FOR YOU instead of against you.</p>\n' +
                '     <p>I’ve created easy to make healthy recipes in this program that are gluten and dairy free (you can eat foods that contain dairy and gluten if they don’t bother you) because these foods often create inflammation and reducing your intake of them, even for as little as 6 weeks, can have a real impact on the health of your gut biome and improve digestion and metabolism.</p>\n' +
                '     <p>For every recipe that contains meat or fish, I’ve created a companion Vegetarian* recipe so you have lots of options if you choose to not eat meat or fish.</p>\n' +
                '     <p>Additionally, I’ve included a <i>Bonus Swaps and Substitutions Guide</i> so you’ll have some good ideas of what to use in place of foods that aren’t your preference or aren’t available.</p>\n' +
                '     <p>I always recommend swapping in foods in season when you can, or foods that are on sale to save money.</p>\n' +
                '     <p><i>*This program is not vegan.</i></p>'
        },
        {
            title: 'How much weight can I lose on the Body Fuel System?',
            content: '<p>While everyone\'s body is unique and will have varying results, it\'s important to understand what\'s happening in your body as you start eating the way the <i>Body Fuel System</i> is designed.</p>\n' +
                '     <p>As you begin to lose body fat, your weight will naturally reflect that, but if you combine exercise with this kind of eating, you\'ll also add some lean muscle - that will start to change the shape and composition of your body.</p>\n' +
                '     <p>Weight is a terrible indicator of body composition. Your weight is simply a number that tells you how much the combined tissues in your body weigh – but it doesn’t tell you the far more important thing, which is what those tissues are actually composed of. Muscle and fat are very different creatures in your body. A pound of fat and a pound of muscle weigh exactly the same thing, but they take up very different amounts of space in your body.</p>\n' +
                '     <p>So if you are adding muscle to your body because you’re following a consistent exercise program and eating in a way that supports that muscle and burns fat (like the foods in the <i>Body Fuel System</i>), you’re going to be gaining a little lean muscle and burning body fat.</p>\n' +
                '     <p>This is the healthiest and most sustainable way to change your body. When you combine that approach with an exercise program like the ones I create, you accelerate your results massively.</p>\n' +
                '     <p>You can achieve your ideal body when you follow the nutritional principles taught in this System - because how your body LOOKS and FEELS is much more important than the number on the scale!</p>\n' +
                '     <p>If you don’t see your weight drop rapidly but you’re taking your progress pics you WILL see your body start to change. Look for those signs. Don’t judge the whole, remarkable, complex you by some arbitrary number that cannot take all of your awesomeness into account.</p>'
        },
        {
            title: 'What if I’m not trying to lose weight? What if I want to see more lean muscle definition or gain weight?',
            content: '<p>Many people who are underweight or lack that lean, sculpted muscle they want to see have not been eating enough of the right foods, and often they haven’t been eating and combining nutrients properly.</p>\n' +
                '     <p>Once they start the program, they are able to see immediate progress without counting calories, restricting meals, or tracking each bite they eat because giving the body the fuel it needs to build and repair tissue is actually a very simple process. Once they achieve optimal health and their ideal physique, they can make simple adjustments and follow the same guidelines and maintain it.</p>\n'
        },
        {
            title: 'Is the Body Fuel System for men and women?',
            content: '<p>Absolutely. It is for anyone looking to learn about food and how to eat it to affect change and a long-term successful eating lifestyle. It’s very easy to customize. The portions in the program are recommended amounts, and I provide you with guidelines about how to easily customize it to yourself.</p>\n' +
                '     <p>Keep in mind that even programs that count calories specifically can never be exact for everyone, every day.</p>\n' +
                '     <p>Your body’s needs change based on a variety of factors daily - and what’s awesome about this program is that it will allow you to learn your own body without being restricted by a daily calorie number, while building real nutritional knowledge that you can apply for life.</p>\n'
        },
        {
            title: 'How do I know which eating type I am? What if I\'m a couple of them?',
            content: '<p>You\'ll know which eating type you are based on how busy you are and how the descriptions appeal to you. The great news is that you can be more than one of the eating styles - you\'ll have access to all 3 for every week of the system, which will set you up for life!</p>\n' +
                '     <p>I often switch between the 3 types throughout the month - minimal while I’m traveling, batch prep when I have some extra time on a Sunday (to shop and prep), and hybrid when I’m home but have a lot going on.</p>\n'
        },
        {
            title: 'I have some food allergies/sensitivities, will the system work for me?',
            content: '<p>The <i>Body Fuel System</i> is a great option if you have food allergies. The menus and recipes are gluten and dairy free, and if you have any nut, fruit or shellfish allergies, it is easy to substitute other foods in place of them and still see great results.</p>\n' +
                '     <p>To make it easy for you, I’ve included a <i>Swaps and Substitutions Bonus Guide</i> to help you make changes and get good ideas of other foods to add in if something doesn\'t work for you.</p>\n'
        },
        {
            title: 'Is it possible to eat Vegetarian while following the Body Fuel System? What about Vegan?',
            content: '<p>The <i>Body Fuel System</i> contains Vegetarian options for every meal that has a meat or fish as the main protein, and contains a broad spectrum of recipes made from whole, natural foods - as well as great resources for <i>Healthy Shortcut Foods.</i></p>\n' +
                '     <p>The program also includes a <i>Healthy Swaps and Substitutions Guide</i> that can be super helpful for those with food allergies or preferences. It includes a variety of protein sources that come from plants, fish and meat.</p>\n' +
                '     <p>I respect everyone’s food choices but this book does not give specific Vegan options.</p>'
        },
        {
            title: 'Does your System track calories and macros?',
            content: '<p>The Body Fuel System specifically does not count calories. Instead, it focuses on the nutrient density contained in whole foods, and their effect on our bodies. It goes in depth into the macro-nutrients – but not to count them, instead to understand them and combine the properly so you won’t have to count.</p>\n' +
                '     <p>Many people who count calories endlessly end up feeling restricted and can develop unhealthy obsessions about food, and never really learn to combine the nutrients appropriately, or listen to their body\'s own biofeedback.</p>\n' +
                '     <p>While there is absolutely nothing wrong with knowing your calorie content or tracking your calories or macros and this can be a very helpful process to check in with from time to time, I find it limiting to do endlessly.</p>\n' +
                '     <p>The information and meal plans in the Body Fuel System are exactly the kind of information you need to truly understand food and your body – to ensure a lifestyle of food enjoyment and a healthy body that you can be proud of and feel great in for years to come.</p>\n'
        },
        {
            title: 'Do I have to give up my treats and dessert and wine to be successful with the Body Fuel System?',
            content: '<p>No! You don\'t have to give them up. In fact, I\'ve written in NSA (no strings attached) eating days for you so you have room to enjoy your favorite treats. When you eat the right nutrients daily, many cravings and energy highs and lows will disappear.</p>\n' +
                '     <p>When you make healthy eating from the right combination of nutrients your baseline, you don\'t have to worry about the treats you enjoy setting you back. But without that foundation, they always will - and you may be stuck with cravings, excess body fat and erratic energy.</p>\n'
        },
        {
            title: 'Do I have to exercise for this System to work?',
            content: '<p>Ideally, everyone should include some kind of healthy activity in their life. You’ll hear me say that how we look and feel is a byproduct of how we treat ourselves, and it’s true. That’s a combination of things, like getting good quality sleep, eating nutritious food, exercising and mitigating our stress.</p>\n' +
                '     <p>I invite you to use any of my amazing workout programs in combination with the Body Fuel System for even greater results.</p>\n' +
                '     <p>However moderate or extreme your workouts or exercise plan, simply follow the guidelines included in the Body Fuel System and make your baseline around including the right nutrients to support your activities and you will see amazing results!</p>\n'
        },
        {
            title: 'I want to use the System with my family/roommate/partner …. How do I adjust it for us?',
            content: '<p>As no two people are identical, the program was written to be flexible. The most important thing to do FIRST is to read the Eating Guide, which will empower you to use the weekly meal plans in the best way for you (and your other household members).</p>\n' +
                '     <p>Before going out and buying double of everything on the Batch grocery list, remember you’ve got several ways to use this program effectively - and I’d recommend this especially if you’re planning to use the program with other people.</p>\n' +
                '     <p>Browse through the recipes and see what stands out to you. Make a grocery list using one of the blank templates in either the hybrid or minimal sections to write down what you’ll want to buy to make the recipes you’ve selected.</p>\n' +
                '     <p>Use the Meal Plan guided weekly menus as a loose guideline for the first week to see some suggestions of what to eat at meal times.</p>\n' +
                '     <p>One of the most valuable parts of this is starting to see what nutrients you’re including in your meals and what whole food sources they are coming from. The recipes in the plan can be substituted for other foods as long as you’re keeping an eye on getting the nutrients you need.</p>\n' +
                '     <p>As you start to get familiar and comfortable with this eating style, it will be easier to see how much food you will need to buy weekly, whether you are using the hybrid, minimal or batch method.</p>\n' +
                '     <p>You will find that all of the principles about food that I teach you in the <i>Body Fuel System</i> are applicable to us at any age. I only recommend wholesome, natural foods. – that support us at every stage of life – especially during growth! I hear from so many readers that their kids love the smoothies, their husbands love the entrees, and they love it all!</p>\n' +
                '     <p>The 6-week portion of the system is a guide, to help you learn to practice and utilize all of the valuable nutrition information in the core of the book. Even if you were doing it on your own, you would make some modifications.</p>\n'
        },
        {
            title: 'Is the Body Fuel System okay for me if I’m pregnant, trying to get pregnant or nursing?',
            content: '<p>Yes, absolutely. The <i>Body Fuel System</i> provides a wholesome, healthy eating system that is ideal for anyone who wants to support optimal wellness. The natural, whole foods in the guide will support your body during one of the most important times there is to ensure you’re getting plenty of nutrients. You should always consult with your doctor or health care provider when making changes to the way you eat during pregnancy.</p>\n' +
                '     <p>Because the System includes some raw greens (greens mix salad and daily green smoothie), I would personally suggest buying only organic greens and vegetables, and being sure to wash them thoroughly. If your diet did not previously consist of any raw fruits or vegetables, introduce them slowly – and again, ensure high quality.</p>\n' +
                '     <p>You should be sure you are aware of any food allergies as well – the system includes nuts, eggs and fish. It is gluten and dairy-free, but allows for easy modifications and substitutions. As always, check with your doctor or health care provider before making any changes.</p>\n'
        },
        {
            title: 'I want to start your eating guide but I’m diabetic. Will it work for me too? What about Fibromyalgia?',
            content: '<p>If you are currently taking prescription medication, it is important that you discuss any changes in your daily eating plan with your physician.</p>\n' +
                '     <p>Because the <i>Body Fuel System</i> focuses on natural foods (such as natural, lean proteins and fibrous fruits and greens) and recommends gluten free, unprocessed grains, you may experience a natural reduction in blood sugar. You must discuss these changes with your physician so that they can oversee any necessary changes that need to be made in your current medications. Keep track of your blood sugar during the initial stages of the <i>Body Fuel System.</i></p>\n' +
                '     <p>The system is ideal for many physical ailments. The nutrients found in whole, unprocessed foods contain many anti-inflammatory benefits, vitamins, minerals and phytonutrients (such as antioxidants). Many aches and pains will lessen as your body is infused with the natural health benefits found in these foods.</p>\n'
        },
        {
            title: 'What if I live somewhere other than the US, will I be able to buy all the food you recommend?',
            content: '<p>While I don’t know exactly what foods you will have in your country or region, I will tell you that the information contained in the program applies everywhere and that the program is whole food based so that you could easily the recipes for a similar food (I’ve included a <i>Swaps and Substitutions Bonus Guide</i> to help make this easy).</p>\n' +
                '     <p>Even in the United States, you might not find every ingredient exactly as it is written throughout the 6 weeks of groceries. The <i>Food Swaps and Substitutions Guide</i> will help you find just the right alternative wherever you are. You’re not required to make the recipes exactly as I’ve written them, - feel free to change it up based on what’s available, your preferences, or any allergies/intolerance you may have.</p>'
        },
        {
            title: 'How do I access the program?',
            content: '<p>Once you make your purchase, the Betty Rocker system goes to work right away. It generates a secure password for you and emails you your personal login and password information. It sends this straight away to the email address you used when you made your purchase.</p>\n' +
                '     <p>Your program is digital, and does not get shipped in the mail.</p>\n' +
                '     <p>If you used Paypal to make your purchase, and there is a different email address associated with that account, it may end up somewhere other than where you expect it, so be sure to check!</p>\n' +
                '     <p>If you don’t see it within 5 minutes or so, it may have gone to spam, or junk, or an “other” box depending on your email service provider. Do a thorough search. If you can’t find it, do reach out to my friendly customer support team, who is looking forward to helping you at support@thebettyrocker.com. They’re online 7 days a week to assist.</p>\n' +
                '     <p>We are more than happy to re-send your email, merge your email addresses if you\'ve used more than one to make purchases in the past, or do anything to support you that we can.</p>\n' +
                '     <p>Once you’re logged in, you’ll see your program and you can access it anytime, from any device you’re on with your login and password!</p>\n'
        },
    ],
    'hotabs': [
        {
            title: 'I’m short on time, how long do the workouts take?',
            content: '<p>The workouts vary in length, but generally between 20-30 minutes.</p>\n' +
                '     <p>The first time you watch the workouts, you’ll want to review any form notes as well, to learn the best form and variations on the moves to do with or without equipment.</p>\n'
        },
        {
            title: 'Will I get DVD’s or CD’s in the Mail?',
            content: '<p>This program is 100% digital, which means portable, affordable and accessible on any device you’re on, so you can enjoy it at home or on the go.</p>\n' +
                '     <p>You won’t get anything shipped to you – instead, you’ll get instant access to ALL the elements of this program, the workout guide, the videos and the calendar.</p>\n' +
                '     <p>You’ll be able to use this program on ANY device – your computer, laptop, portable device or phone and you’ll have access to it forever!</p>\n'
        },
        {
            title: 'I have an injury, will you be showing modifications for it?',
            content: '<p>It’s very important you don’t train on an injury, and always exercise without pain.</p>\n' +
                '     <p>Exercise creates inflammation in the muscles. In a healthy system, this load is what helps grow and define muscle, but on a weakened system it can compromise the immune system and slow down the healing process.</p>\n' +
                '     <p><strong>Please check with your doctor before beginning an exercise program.</strong></p>\n' +
                '     <p>There are many modifications shown for the exercises, but these are geared toward different fitness levels. It is expected that you have full ability to exercise and are injury free.</p>\n'
        },
        {
            title: 'How long will it take to see results?',
            content: '<p>Your body will start responding when you start this program. You will feel and see results very quickly – and it is sometimes hard to see your body changing if you’re looking in the mirror at yourself every day, which is why I recommend taking progress pictures every 7-10 days, so you can compare them side by side.</p>\n' +
                '     <p>For the BEST and FASTEST results, it’s very important you follow the 30-day program, eat well balanced, nutrient-dense meals (any of my meal plans would give you the same kind of result as me, as this is exactly how I eat and train), and STAY CONSISTENT.</p>\n'
        },
        {
            title: 'Can I do this program at Home? Do I need equipment?',
            content: '<p>Yes you can! For any moves that include optional equipment I’ll show you how to do them either with or without. You can also do this program at the gym if you wish.</p>\n' +
                '     <p>The optional equipment you can use includes a swiss ball, small stretchy bands and dumbbells, but you’ll be able to get a killer workout with or without it!</p>\n'
        },
        {
            title: 'Will I be able to do this if I’m a beginner? How about if I’m advanced?',
            content: '<p>Yes, these workouts are designed for all fitness levels – and I show you lots of variations throughout to progress or take it slow.</p>\n' +
                '     <p>No matter what level you’re at, it is essential that you watch for the form cues, and use the modifications and listen to your body. Always work within your limits, and pain-free. Pause the video if you need more rest, and take your time!</p>\n' +
                '     <p>If you’re advanced, I recommend using the weights and equipment and increasing the resistance and your pace to challenge yourself. The workouts challenge me, and they are very easy to make more or less difficult simply by increasing/decreasing pace or resistance (optional weight).</p>\n'
        },
        {
            title: 'Will these workouts make me bulky?',
            content: '<p>These workouts combine agility, speed and strength moves in a specific workout combination designed to sculpt lean muscle and burn fat.</p>\n' +
                '     <p>You’ll be targeting the EXACT muscles that will both give you a defined shape and make you stronger for your daily activities and workouts.</p>\n' +
                '     <p>Using this plan (with proper nutrition) will tone and strengthen your legs, sculpt and lift your glutes, and define and sculpt your abs.</p>\n'
        },
        {
            title: 'How soon will I get my workouts and videos?',
            content: '<p>Right away! As soon as you enter your billing information, you’ll receive an email with a secure login and password so you can instantly access your brand new program from ANY device. You’ll have forever access to this once you have purchased it!</p>\n'
        },
        {
            title: 'Do I get videos and a PDF too?',
            content: '<p>Yes! I created a video and a written guide for each complete workout where I will go over each move with you and describe the form that will help you get the most out of it and keep you safe and injury-free.</p>\n'
        },
        {
            title: 'What if I’m not satisfied?',
            content: '<p>Then you will get your money back, no problem at all! If you aren’t satisfied with this program in 30 days (the full length of the plan), you can get a refund. Just write us at support@thebettyrocker.com, and we’ll be happy to help you!</p>\n'
        },
    ],
    'hwd': [
        {
          title: 'Can I Do This At Home? What if I’m traveling, or at the gym?',
          content: '<p>Yes! Home Workout Domination is designed to be done from home with minimal equipment. It’s a great option to take with you traveling as you’ll have access to the entire program on any device. You can most certainly do these workouts in the gym too if you’re there!</p>'
        },
        {
          title: 'What equipment do I need?',
          content: ' <p>You’ll have a demo videos for each workout to show you how to do the workout with/without equipment in addition to the follow along workout videos, so you’ll see options. If you’re doing it with home workout equipment, you’ll use:</p>\n' +
              '      <ul class="checkmark-purple-light">\n' +
              '         <li><strong>dumbbells</strong> – alternatives: water jugs, cans, wine bottles, water bottles</li>\n' +
              '         <li><strong>a swiss ball</strong> – alternatives: sliders, an ottoman, paper plates</li>\n' +
              '         <li><strong>cables/bands</strong> – alternatives: a towel, ankle weights, dumbbells or dumbbell alternatives</li>\n' +
              '         <li><strong>jump rope</strong> – alternative: air jump rope</li>\n' +
              '      </ul>\n' +
              '      <p>The equipment itself is optional, as you can use bodyweight and still get a great workout. You can also use household items to add resistance if you don’t have any equipment yet.</p>'
        },
        {
          title: 'How are the workouts set up and how long are they?',
          content: '<p>Home Workout Domination works your body head to toe – working upper body, lower body, full body, HIIT training and yoga in a sequence to keep your body guessing and get you results!</p>\n' +
              '     <p>You’ll be doing “superset” workouts, which are like short circuits pairing strength with cardio to give you the maximum effect in the shortest amount of time.</p>\n' +
              '     <p>Each workout will contain several supersets so you can efficiently move through the workout and maximize the opportunity to get stronger and lose unwanted body fat.</p>\n' +
              '     <p>You will have options for each exercise – you can do these workouts using only body weight or add more resistance with various workout equipment or household objects like water bottles and furniture.</p>\n' +
              '     <p>The workouts are 25 min to 35 min in length, depending on the day. If you’re short on time, you can leave one superset off so you can still get your workout done. The plan is written for 5 days a week – but I’ve included a custom 3-day plan for you as well.</p>\n'
        },
        {
          title: 'Is it ok for beginners?',
          content: '<p>Yes, this program is awesome for beginners – it’s actually great for all fitness levels! Since you’ll be the one choosing the equipment and resistance (i.e. how much weight you use) you can safely start where you are at.</p>\n' +
              '     <p>Every workout comes with a companion demonstration video to help you learn proper form, safe alignment and all the equipment options before doing the follow along workout with me.</p>\n' +
              '     <p>Plus, with all the great options, you can grow with this program and use it over and over again without it feeling repetitious. This program also includes a Healthy Eating Guide with a 7 Day Meal Plan so you can get a great feel for healthy eating that supports your workouts!</p>\n'
        },
        {
          title: 'How soon will I get my program and videos?',
          content: '<p>Right away! As soon as you complete your payment, you’ll receive instant access and a special login and password to the email you used when you purchased that will securely log you in to the exclusive customer area.</p>\n' +
              '     <p>You’ll have forever access to this area from any device you are on.</p>'
        },
        {
          title: 'Can I do this program if I’m short on time?',
          content: '<p>Definitely. These workouts take less time because of how the circuits are formatted – so you don’t have to spend 30 minutes doing a dedicated cardio session PLUS another 20-30 minutes on strength training.</p>\n' +
              '     <p>Plus, doing a home workout means you won’t need to get dressed, drive to the gym, find the equipment you need, do the workout, drive home, etc. You’ll be more efficient AND effective in less time – and be able to reach your goals!</p>\n'
        },
    ],
    'lioness': [
        {
            title: 'Can I do Lioness at home?',
            content: '<p>Yes! You’ll need some equipment, but the program includes follow along videos so you can do it with me and see options for using your dumbbells, swiss ball and stretchy bands. There are also shorter tutorial videos included for every move which are perfect for use at the gym that show how to use some machines and barbells for heavier lifts that you’d typically do there.</p>\n' +
                '     <p>Progressive overload, which is adding weight/resistance incrementally, is what gives you results quickly – so having access to weights that go up in small increments is ideal. Many of the exercises you can begin with at a very light weight or bodyweight, but as you get stronger you’ll want to add weight.</p>\n'
        },
        {
            title: 'Is this Program Hard?',
            content: '<p>The workouts and meal plans are laid out in a very easy to follow manner – so I’d say this program isn’t “hard” it’s challenging! So you will see results.</p>\n' +
                '     <p>Like everything in life, you will get out of it what you put into it –  and you’ll have to do the work to see the results!</p>\n'
        },
        {
            title: 'How Long do the Workouts Take?',
            content: '<p>This is a different format of training than some of my other bodyweight or HIIT style workout programs you may have tried (30 Day #makefatcry Challenge, 90 Day Challenge). The workouts are longer, as it’s a different kind of training.</p>\n' +
                '     <p>In Phase 1 (the first 4 weeks), you’ll dedicate 4 days a week to the lifting workouts, split between upper and lower body circuits. These workouts take between 45 min to an hour to complete (to account for warm-up, changing weights, resting, stretching, a HIIT finisher or an ab circuit to finish).</p>\n' +
                '     <p>In Phase 2 (the second 4 weeks), you’ll do 3 days of full body power strength training (also 45 minutes to an hour with the warm up, strength training workout, abs workout and stretching) and one shorter cardio day (20-35 minutes).</p>\n' +
                '     <p>You will have the option to add another day of cardio or HIIT if you are at a more advanced level and properly fueling your body with balanced nutrition – but it’s completely optional.</p>\n' +
                '     <p>You can also enjoy active recovery day movement like yoga, cycling, swimming and walking on your rest days.</p>\n'
        },
        {
            title: 'Is it only for experienced lifters or people who are already in great shape?',
            content: '<p>Not at all. You can start from any fitness level and experience great results. With my follow-along demo videos, you’ll be able to learn form and use lighter equipment if you’re just getting started.</p>\n' +
                '     <p>No matter how much experience you have, you will really progress with your fitness and physique!</p>\n' +
                '     <p>If you are an experienced lifter, you’ll love how rapidly you progress and how easily you can challenge yourself with this 2-phase program. Along with the follow alongs, you’ll have quick videos that go over each move if you’re in the gym so you can focus on your equipment and your lifts.</p>\n' +
                '     <p>If you’re a beginner, you’ll quickly master the exercises with the easy-to-follow full-length video tutorials and make rapid progress.</p>\n'
        },
        {
            title: 'Will I Bulk up?',
            content: '<p>Definitely not. This program will help you shape and tone your body. You will definitely be building lean muscle. As you drop fat and strengthen your muscles, you’ll notice the shape of your body change – curves will appear in your calves, hamstrings and butt, your abs will become more apparent, your arms more toned and defined, and your back more visibly strong.</p>\n' +
                '     <p>Just so you know, it is challenging to add muscle mass in general. Even men, who have higher levels of testosterone, have to work hard to add muscle. You are not going to get bulky just by adding heavier weights to your workouts – they will give you the opportunity to build shapely muscles and lose body fat. Bonus – as you add lean muscle, your body burns more calories at rest – so you’ll be burning more fat. Weight lifting, and heavier resistance training, sculpts those beautiful body lines.</p>\n'
        },
        {
            title: 'What does a 2-Phase Program mean?',
            content: '<p>This program is divided into 2 “phases,” which are just time periods, divided. In Phase 1, we’ll be doing upper body/lower body split workouts on different days to help you master the moves and build your strength with progressive overload.</p>\n' +
                '     <p>You’ll watch your strength increase and fat start to come off your body as you build your foundation in Phase 1, then you’ll up the intensity and begin a brand new timing sequence that will take your results to the next level in Phase 2.</p>\n' +
                '     <p>Progressive overload is exactly what it sounds like: adding heavier weights over time to facilitate adaptation from your body. The adaptation is increasing your strength and muscle mass- so you are progressively (increasing over time) overloading (increasing weight) your muscles with weight heavier than they are used to using.</p>\n'
        },
        {
            title: 'Are the meal plans easy to follow? Do I have to follow them exactly to see success?',
            content: '<p>The plans are very easy to follow, and you are unique – you can adapt them to suit you easily. You do not have to follow them exactly, and I don’t recommend it.</p>\n' +
                '     <p>I would recommend beginning by browsing the recipe guide to see what recipes you’d like to try. Ease into the eating plan – focus on incorporating more whole foods, having a daily green smoothie, including protein in all your meals, and check out the daily menus for ideas.</p>\n' +
                '     <p>The plan includes Vegetarian options, and all the recipes are gluten and dairy free.</p>\n' +
                '     <p>If you have a food allergy, simple use the “food swaps” section of the Eating Plan to interchange whichever food doesn’t work for you to with something similar. Anything on that list of swaps is a great option!</p>\n' +
                '     <p>If you don’t like certain ingredients in a recipe, you may also use that food swaps section to use something you DO like.</p>\n' +
                '     <p>As long as you’re choosing foods and ingredients that are in their whole and unprocessed forms, you can’t go wrong.</p>\n' +
                '     <p>When you build your daily eating around whole foods with balanced nutrients your body will quickly respond with more energy, support your lean muscle development for more tone, and you’ll lose excess body fat.</p>\n'
        },
        {
            title: 'Can I watch the videos on my iPad or mobile device?',
            content: '<p>Totally.  You’ll have a secure login and password that will give you access from any device you’re on.</p>\n' +
                '     <p>If you ever lose your login, you can send an email to support@thebettyrocker.com and we’ll re-send it to you!</p>'
        },
        {
            title: 'Can I do this if I have a job and kids?',
            content: '<p>Yes you can! With the formatting in this program, you will NOT need to workout every day. Plus, with the follow along video option, you can do this from home, saving you even more time.</p>\n' +
                     '<p>You’ll be efficiently and productively using the time you are working out to make serious progress so you can enjoy the rest of your life – and the way being strong and shredded will make you feel!</p>\n'
        },
    ],
    'bgp': [
        {
            title: 'What is "Berry Green Protein" and how is it made?',
            content: '<p>Berry Green Protein is an organic, high-protein, nutrient-dense, plant-based functional food powder. Its protein source is a natural pea protein isolate from certified organic North American grown yellow peas. This pea protein is produced with a natural fermentation process which uses no chemical solvents.</p>\n' +
                     '<p>It has an excellent array of amino acids, including high levels of BCAA’s (branch chain amino acids).</p>\n' +
                     '<p>We work closely with our certified organic growers to get the best phytonutrient profile in this proprietary blend of greens, vegetables and fruits. The brightly colored, non-oxidized powders are protected from direct heat, UV light and moisture during the manufacturing process. The organic blend of ingredients is harvested at an optimal time, then concentrated and dried to preserve their nutritional potency.</p>\n'
        },
        {
            title: 'Is Berry Green Protein Gluten and Dairy Free?',
            content: '<p>Yes – it is 100% gluten and dairy free. It is ideal for anyone who is looking for a plant-based protein with high digestibility. Berry Green Protein contains NO grains, alfalfa, corn, fructose, or artificial sweeteners.\n' +
                     '<p>We sometimes get questions about wheatgrass and barley grass, as they sound like they’d contain gluten! Wheatgrass and Barley Grass are both grasses of the wheat and barley grain. While the kernels of these plants contain gluten, their grasses do not. Many people commonly juice wheatgrass as it can often be found in health food stores.</p>\n'
        },
        {
            title: 'How do I use it?',
            content: '<div class="row">\n' +
                            '<div class="col-xs-12 col-md-8">\n' +
                                '<p>Shake it up, blend it up or bake with it!</p>\n' +
                                '<p>Mix 1 serving of Berry Green Protein into 8-12 oz of water, or other liquid of your choice and shake or mix vigorously to combine.</p>\n' +
                                '<p>You can also add a serving of Berry Green Protein to a smoothie and blend it.</p>\n' +
                                '<p>It also bakes beautifully into many recipes, increasing the protein and micronutrient value of your recipe.</p>\n' +
                                '<p><strong class="fw--bold">Bonus Recipe Guide included in your first purchase!</strong></p>\n' +
                            '</div>\n' +
                            '<div class="col-xs-12 col-md-4 txt--center">\n' +
                                '<img src="/images/supplements/berry-green-protein/faq-img.png" class="mt--sm-20 no-shadow radius-0" alt="Berry Green Protein." width="267" height="300"/>\n' +
                            '</div>\n' +
                        '</div>\n'

        },
        {
            title: 'Should I use it daily?',
            content: '<p>While you can use it daily to increase your protein intake and boost your greens and fruit micronutrient intake, you can also use it every other day if you’re rotating in another type of protein you enjoy or getting plenty of protein and greens from whole foods.</p>\n'
        },
        {
            title: 'Should I still use it if I’m already having green smoothies?',
            content: '<p>Yes, I really recommend that you add a concentrated greens powder into your rotation of smoothies, and I always recommend adding protein to your smoothies to ensure you meet your daily needs for protein – especially if you’re active. You can either add Berry Green Protein right into your smoothies themselves, or enjoy it as an additional serving in a glass of water at some point in the day.</p>\n' +
                     '<p>You may not be getting enough variety in the greens you’re putting in your smoothies to really harness all of the phytonutrients, vitamins and minerals that can be derived from the 15 different superfoods in this blend. It’s also very hard to ensure that the greens and fruits you’re using in your smoothies were harvested at their optimal time for the best bioavailability of nutrients, and we know how expensive organic produce can be. This is a wonderful way to supplement what you’re already doing and give your body some real health insurance!</p>\n'
        },
        {
            title: 'How does it taste?',
            content: '<p>Berry Green Protein tastes like the real strawberries and vanilla bean we use to flavor it with! It has a lovely smell that you’ll notice right away when you open the container for the first time, and the taste matches the aroma. It has a balanced moderate sweetness with no weird aftertaste, so you can enjoy it in water, or still add fruit in a smoothie and it won’t be “too sweet.”</p>\n' +
                     '<p>One of the ingredients you’ll see on the label is <strong class="fw--bold">“organic natural flavors.”</strong> A “natural flavor” means that the flavor must come from a real food source, however companies vary greatly on how these are processed. We start out by using organic strawberries and vanilla bean, which we distill the essence of to get our flavor. Therefore, while you won’t see “strawberries” or “vanilla bean” listed in the nutrition panel specifically, they are represented in the flavoring and those with strawberry allergies should avoid this product.</p>\n' +
                     '<p>In regards to <strong class="fw--bold">the way we sweetened the product,</strong> I never liked the way stevia tasted in powders I had tried in the past which was such a bummer because it’s a wonderful low-sugar plant-based option. When I started working on this product, I learned that there are actually different kinds of stevia available, and again, the way they are processed makes a difference in the taste (and frankly, quality).</p>\n' +
                     '<p>While many manufacturers commonly use a widely available form of stevia known as “stevia Reb B” we opted for the higher quality “stevia Reb A “- which we also source from organic growers. The common practice is to extract the stevia with alcohol – another contributor in its odd – sometimes “too sweet” aftertaste. Naturally I opted to distill the stevia without alcohol, which means it adds the kind of balanced, mild and natural sweetness to Berry Green Protein, rather than a harsh or overpowering sweetness.</p>\n' +
                     '<p><strong class="fw--bold">I recommend watching <Link class="txt--pink">the product reviews</Link> as many of the people talked about how it tasted to them, and they are all different.</strong></p>\n'
        },
        {
            title: 'I don’t like the aftertaste that stevia-sweetened products give...should I still try this?',
            content: '<p>You definitely should try it. While I was making this product, I learned that there are different kinds of stevia, and not all stevia is processed the same way. What is commonly used is a commercial grade stevia, known as stevia "reb B." It’s commonly processed with alcohol, which contributes to its odd aftertaste if you’re sensitive to it. Since I am sensitive to it personally, I opted to go with a higher grade, more expensive stevia knows as stevia "reb A." We only use the organic version and do not process it with alcohol. This gives our protein a true, clean and mild sweetness with no awkward aftertaste.</p>\n'
        },
        {
            title: 'How does it blend?',
            content: '<p>Even when shaken up with just water, the Berry Green Protein blends beautifully and is drinkable without being gritty or sticky. Because I chose not to add a bunch of coagulants and other additives to make it bind, if you leave Berry Protein sitting in a glass of water for a while, some of its sediment will naturally sink to the bottom of your glass. This is normal, just give it another stir, or another shake if it’s in your blender bottle and it will mix right back into the water.</p>\n'
        },
        {
            title: 'Do you ship to countries outside of the United States?',
            content: '<p>We would absolutely LOVE to, however each country sets specific regulations on what kind of food products can be imported so we ship only to the USA at this time. Because we’ve included so many organic whole foods in Berry Green Protein we can’t guarantee it will make it to you through customs due to regulations your country may have on importing fruits and vegetables. We apologize for this inconvenience!</p>\n'
        }
    ],
    'fbc': [
        {
            title: 'What is Collagen?',
            content: '<p>Collagen is the most abundant protein found in our bodies and acts like the “glue” that holds everything together. It is the primary component of our hair, skin and bones and makes up a portion of other connective tissues like cartilage and muscle as well.</p>\n' +
                     '<p>It is comprised of the amino acids glycine, proline and hydroxyproline. These three amino acids can be found in other protein sources, but not in as high of concentrations - which is why collagen peptides are a great supplement to a healthy balanced diet.</p>\n' +
                     '<p>Numerous scientific studies have shown that collagen supplementation can reduce joint pain, increase the quality and strength of your nails, improve the elasticity of your skin and reduce wrinkles, speed healing, and strengthen bones.\n'
        },
        {
           title: 'Where does collagen come from?',
           content: '<p>In times past, hunter-gatherers made use of the entire animal they hunted making rich bone broths from boiling down bones, hooves and hides to take advantage of the rich and nourishing collagen peptides that were distilled out in the process. In its modern supplemental form, it is still derived from the distillation of these same often unused (in our modern era) parts of animals that contain an abundance of collagen so less is wasted.</p>\n'
        },
        {
           title: 'What is the source of Full Body Collagen?',
           content: '<p>We source Full Body Collagen from cows from Germany that are fed a mixed diet of grass and hay (in the winter). Our farmers never use GMO food or antibiotics, which is one of the reasons I chose a reliable and high quality supplier in Europe - the standard is simply better due to GMO’s being banned and other farming standards being more in line with what I wanted to put in my own body. We’ve hydrolyzed the collagen which means it’s in the smallest, finest particles - making it extremely dissolvable in anything, as well as exceptionally easy for your body to absorb and digest so it can do the most good for you.</p>\n'
        },
        {
            title: 'What does it taste like?',
            content: '<p>Unlike many collagen supplements, our collagen has no odor, taste or flavor. I encourage you to watch some of the review videos, as this comment about our collagen being actually flavorless (unlike other collagen brands they have tried) has been made by numerous people. Since our collagen comes from a high-quality source and is broken down to such a fine particle, it is instantly dissolvable in any beverage and many people even use it in their coffee.</p>\n'
        },
        {
            title: 'I’ve tried other collagen before and it was lumpy/left globs behind - how does Full Body Collagen dissolve?',
            content: '<p>Full Body Collagen can be mixed in anything and dissolves completely and instantly thanks to it being hydrolyzed and fine grain. I recommend putting the scoop of collagen in first if you’re pouring it into a beverage and then stirring the beverage over the top of it for best results - but I’ve had no issues mixing it in after I’ve already poured my liquid in either. Finding a collagen that fully dissolves is a good thing - that means your body will be able to easily absorb all of the goodness you are taking in.</p>\n'
        },
        {
            title: 'Is it Gluten and Dairy Free?',
            content: '<p>Yes - there is just one ingredient in our collagen peptides: collagen peptides. No dairy or gluten, wheat products, nuts, corn, soy, sugar or anything other than collagen are added. You’ll notice that there is some sodium on the nutrition label, that is naturally occurring and many foods contain some minerals like this.</p>\n'
        },
        {
            title: 'Is it Vegetarian?',
            content: '<p>Collagen is found exclusively in the flesh and connective tissues of mammals. If you are looking to supplement some of these amino acids in a plant-based way, you could consider spirulina which contains 2 of the 3 amino acids found in collagen: glycine and proline (it does not contain hydroxyproline). You can try my Berry Green Protein, which contains spirulina if you are looking for a plant based alternative to collagen, and consider boosting it with a spirulina powder or capsule.</p>\n'
        },
        {
            title: 'Is it Sugar Free?',
            content: '<p>Yes, it is 100% sugar free. We do not add anything to our collagen peptides. They have no taste, sweetness or flavoring and are extremely versatile.</p>\n'
        },
        {
            title: 'How do I use Full Body Collagen?',
            content: '<p>There are so many ways to use collagen peptides! I have used them in so many different ways and I put together a <strong class="fw--bold"><i>Full Body Collagen Recipe Guide</i></strong> that you’ll receive with your first purchase.</p>\n' +
                     '<p>You can add it to coffee or tea, a smoothie, or simply shake it up with your favorite protein powder to boost the amino acid profile. It is also so convenient to add it into any kind of soup, oatmeal or baked good to receive all of the amazing benefits, plus a boost of amino acids without changing the flavor or texture.</p>\n'
        },
        {
            title: 'Can I use Full Body Collagen daily?',
            content: '<p>Yes! Full Body Collagen is in my daily rotation because of its amazing protective benefits for your skin, joints and bones, and numerous studies have been done showing its efficacy with daily use. It’s really versatile in that it is flavorless, can be mixed in anything and dissolves completely and instantly.</p>\n'
        },
        {
            title: 'Can you mix Full Body Collagen with a regular protein powder?',
            content: '<p>Yes, definitely and that’s a great idea! Full Body Collagen is an excellent way to boost the amino acid profile of your regular protein powder so you can receive all of the amazing benefits for your joints, skin and bones.</p>\n'
        },
        {
            title: 'Can you ship this outside of the continental USA?',
            content: '<p>We would absolutely LOVE to, however each country sets specific regulations on what kind of food products can be imported so we ship only to the USA at this time. We can’t guarantee it will make it to you through customs due to regulations your country may have on imports. We apologize for this inconvenience!</p>\n'
        }

    ],
    'vp': [
        {
            title: 'Is I <i class="fa fa-heart"></i> Vanilla Protein Gluten and Dairy Free?',
            content: '<p>Yes – it is 100% gluten and dairy free. It is ideal for anyone who is looking for a plant-based protein with high digestibility. <strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> contains NO grains, soy, alfalfa, corn, fructose, or artificial sweeteners.</p>\n'
        },
        {
            title: 'How do I use it?',
            content: '<p>Shake it up, blend it up or bake with it!</p>\n' +
                     '<p>Mix 1 serving of <strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> into 8-12 oz of water, or other liquid of your choice and shake or mix vigorously to combine.</p>\n' +
                     '<p>You can also add a serving of <strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> to a smoothie and blend it.</p>\n' +
                     '<p>It also bakes beautifully into many recipes, increasing the protein and micronutrient value of your recipe.</p>\n' +
                     '<p>Bonus Recipe Guide included in your first purchase! - add image</p>\n'
        },
        {
            title: 'What is the taste and texture like?',
            content: '<p><strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> has a light, sweet vanilla flavor with no awkward “plant” aftertaste. We use 100% organic ingredients of the best quality to blend your shake, and we included a little coconut milk powder (flavorless) to help give it a creamy and smooth texture minus the dairy.</p>\n'
        },
        {
            title: 'What is it sweetened with, and does it have added sugar?',
            content: '<p><strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> is sweetened with an organic, all natural and sugar-free sweetener called monk fruit. Unlike other sweeteners that contain fructose or sugar alcohols, monk fruit gets its sweetness from unique antioxidants called mogrosides that are extracted from the fresh pressed juice of the fruit. Because of these unique properties, monk fruit provides great antioxidants and anti-inflammatory benefits without having an effect on blood sugar.</p>\n'
        },
        {
            title: 'How does it blend?',
            content: '<p>Even when shaken up with just water, <strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> blends beautifully, and is drinkable without being gritty or sticky. Because I chose not to add a bunch of coagulants and other additives to make it bind, if you leave <strong class="fw--bold">I <i class="fa fa-heart txt--pink"></i> Vanilla Protein</strong> sitting in a glass of water for a while, some of its sediment will naturally sink to the bottom of your glass. This is normal, just give it another stir, or another shake if it’s in your blender bottle and it will mix right back into the water.</p>\n'
        },
        {
            title: 'Do you ship to countries outside of the United States?',
            content: '<p>We would absolutely LOVE to, however each country sets specific regulations on what kind of food products can be imported so we ship only to the USA at this time. Because we’ve included so many organic whole foods in Berry Green Protein we can’t guarantee it will make it to you through customs due to regulations your country may have on importing fruits and vegetables. We apologize for this inconvenience!</p>\n'
        }
    ],
    'sc': [
        {
            title: 'What is SereniCalm®?',
            content: '<p>SereniCalm is a blend of herbs and organic compounds specifically designed to support your body in a healthy response to stress.</p>\n' +
                     '<p>The synergistic blend of ingredients in Serenicalm is formulated to support the synthesis of neurotransmitters (important chemical messengers that transmit signals between neurons), adrenal gland function, cellular energy generation, and a calm mind.</p>\n' +
                     '<p>The adrenal glands, key players in mounting a healthy response to stress, have a high demand for B vitamins, which are commonly depleted during stress as a result of overtaxing these glands.</p>\n' +
                     '<p>It can be beneficial to provide these essential nutrients in supplemental form during periods of acute and chronic stress to replenish them and ensure the body has an adequate supply to draw from.</p>\n' +
                     '<p>Additionally, B vitamins can aid in the maintenance of healthy blood sugar levels. The irritability, emotionally charged states, or feelings of panic some of us experience during stressful situations may be the result of unrecognized acute hypoglycemia (low blood sugar).</p>\n' +
                     '<p>With a combination of required enzyme cofactors and calming neurotransmitters, Serenicalm can help even out the “highs and lows” that may be contributing to difficulty coping with everyday stress.</p>\n'
        },
        {
            title: 'Who is it for?',
            content: '<p>Serenicalm is ideal for those who have chronic stress and can help support the body when going through acutely stressful situations. When combined with stress reducing lifestyle changes, it can help improve your stress response.</p>\n'
        },
        {
            title: 'How do I use SereniCalm?',
            content: '<p>The recommended use is as a dietary supplement. Take one capsule per day with a meal. You can take it anytime of day - particularly around the time of day that you tend to feel the most stress.</p>\n' +
                     '<p>For some people that might be in the evening after work, for others it could be around work or in the morning. Lowering your stress in the evening can be helpful for improving sleep quality and quantity. If you’re using Whole Sleep this can help boost the benefits.</p>\n' +
                     '<p>Feel free to try it at different times of day to see when it works best for you.</p>\n'
        },
        {
            title: 'What are the ingredients in SereniCalm and what effects do they have?',
            content: '<p><strong class="fw--bold">Gamma-aminobutyric acid (GABA):</strong> GABA is a naturally occurring amino acid in the brain and a major inhibitory neurotransmitter in the central nervous system.</p>\n' +
                     '<p>GABA serves as a critical calming agent for the body, helping to combat stress and anxiety.</p>\n' +
                     '<p>The brain synthesizes GABA from glutamate, an excitatory neurotransmitter. These two neurotransmitters work together in an orchestrated system of checks and balances. Stressful situations may decrease GABA levels, causing this delicate system to become unbalanced. Supplementation with GABA may be of benefit in helping individuals cope with both acute and chronic stress.</p>\n' +
                     '<p><strong class="fw--bold">Niacin (Vitamin B3):</strong> Niacin is a cofactor in cellular energy production. Over 400 enzymes require the coenzymes synthesized from niacin— NAD and NADP—primarily as electron donors and acceptors for redox reactions in metabolism.</p>\n' +
                     '<p>NAD functions mainly in energy-producing reactions involving the catabolism of carbohydrates, fats, and proteins.</p>\n' +
                     '<p>Niacin may also have a mild tranquilizing effect, making it potentially beneficial for those with obsessive-compulsive and anxiety disorders.</p>\n' +
                     '<p><strong class="fw--bold">Pantothenic Acid (Vitamin B5):</strong> Pantothenic Acid is a water-soluble vitamin and precursor in the biosynthesis of coenzyme A (CoA), an essential coenzyme in a variety of life-sustaining biochemical and metabolic reactions.</p>\n' +
                     '<p>CoA is involved in the Krebs cycle and the synthesis of essential fats, cholesterol, and steroid hormones. These roles may be why B5 has long been recognized as supporting proper function of the adrenal glands.</p>\n' +
                     '<p><strong class="fw--bold">Vitamin B6:</strong> Vitamin B6 is a required cofactor for the conversion of 5-HTP to serotonin, and L-Dopa to dopamine. The GABA-glutamate cycle also requires B6 for glutamate decarboxylase, the enzyme that converts glutamate to GABA, making this nutrient indispensable for neurotransmitter synthesis and a healthy response to stress.</p>\n' +
                     '<p><strong class="fw--bold">Glycine:</strong> Glycine, the smallest amino acid, is synthesized from serine in the human body, but endogenous synthesis in healthy adults has been shown to fall short of meeting the many biological and metabolic demands for this amino acid. Individuals experiencing acute or chronic stress may have an even greater demand for glycine, making this a conditionally essential amino acid and increasing the need for supplementation.</p>\n'
        },
        {
            title: 'Is it Vegetarian and Gluten/Dairy/Sugar Free?',
            content: '<p>Yes. SereniCalm is 100% Vegetarian, gluten-free, dairy-free, and sugar-free.</p>\n'
        },
        {
            title: 'Can I use it with my other medications/medical condition?',
            content: '<p>Always consult your doctor to ensure that SereniCalm can be combined with your current medications. SereniCalm is not a medication but it does have compounds that work with your body that may interact with the other things you are taking.</p>\n'
        },
        {
            title: 'Caution with GABA',
            content: '<p>Due to the inclusion of GABA, this product is not recommended for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</p>\n'
        },
        {
            title: 'Is it safe to take while pregnant or nursing?',
            content: '<p>It is always recommended to consult with your health care practitioner before taking any supplements when pregnant or nursing. Due to the inclusion of GABA, this product is not recommended for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</p>\n'
        },
        {
            title: 'Can you ship this outside of the continental USA?',
            content: '<p>We would absolutely LOVE to, however each country sets specific regulations on what kind of food products can be imported so we ship only to the USA at this time. We can’t guarantee it will make it to you through customs due to regulations your country may have on imports. We apologize for this inconvenience!</p>\n'
        }
    ],
    'ws': [
        {
            title: 'What is Whole Sleep and how does it work?',
            content: '<p>Whole Sleep is a blend of herbs and organic compounds that support your body’s natural sleep cycle. It will help you fall asleep - and stay asleep - so you wake up refreshed and energized, and ready to ROCK your day without the after effects commonly associated with sleeping pills.</p>\n' +
                     '<p>Whole Sleep works with your body to promote GABA, the neurotransmitter that promotes relaxation - and serotonin, the hormone that promotes a feeling of calm and well-being. When you take it at the time you’d like to fall asleep, it will help reset your body clock so you more naturally become tired at the right time moving forward.</p>\n' +
                     '<p>Whole Sleep is not a magic pill. Other lifestyle factors can influence the quality of your sleep like noise, light exposure too close to bedtime, eating, consuming alcohol or exercising just before bed, and other people in your sleep space. Check out our 5 senses guide to help optimize your sleep environment so Whole Sleep can serve you to the utmost.</p>\n'
        },
        {
            title: 'Who is it for?',
            content: '<p>Whole Sleep is excellent for anyone who needs support for a quality night’s rest. It’s great for those seasons of our lives where we just aren’t able to get enough sleep, and want to make the sleep we get higher quality. For example, if you’re going through a period of high stress, working the night shift, traveling through different time zones (or travel in general), taking care of small children or other dependents like pets, Whole Sleep can be a great way to improve the quality of rest you’re able to get.</p>\n' +
                     '<p>It’s also great for you if you have trouble turning “off” at night, or wake up regularly. It contains a small amount of melatonin, which your body makes naturally already but many things can throw off the timing of its release. Taking Whole Sleep a bit before you’d like to fall asleep will ensure melatonin is present to do its job, which will help reset your natural cycles of melatonin.</p>\n'
        },
        {
            title: 'Why is sleep so important?',
            content: '<p>Sleep timing, duration and quality play a major role in hormone regulation, brain function and all body processes, including our ability to regulate body fat and support lean muscle. Getting great sleep means we can share the best parts of ourselves with our loved ones and the world around us. We can show up fully, tap into our inner motivation and play full out. Make quality sleep your #1 priority for a balanced lifestyle of awesome!</p>\n'
        },
        {
            title: 'What are the ingredients in Whole Sleep and what effects do they have?',
            content: '<p>Whole Sleep contains valerian root (Valeriana officinalis), passion flower (Passiflora incarnata), and lemon balm (Melissa officinalis), all safe nervine botanicals known for their relaxant properties and ability to reduce tension and promote sleep, without causing morning grogginess.</p>\n' +
                     '<p>Valerian root has demonstrated sedative effects due to its ability to induce the release of GABA from brain tissue. It has been suggested that the passion flower and German chamomile (Matricaria recubita) work together, possibly causing anxiety reducing effects without impairing memory, diminishing motor skills, or causing drowsiness upon awakening.</p>\n' +
                     '<p>Lemon balm has been suggested to improve calmness via the inhibitory action of GABA, without the overt side-effects of other medications. Gamma aminobutyric acid (GABA), is the main calming neurotransmitter in the body, and calming neurotransmitter production support is provided with the inclusion of L-theanine, clinically proven to reduce stress and improve the quality of sleep.</p>\n' +
                     '<p>Whole Sleep also includes melatonin, a hormone whose main role lies in its involvement in the control of the circadian (day/night) biological rhythms. Melatonin mediates the body’s response to variations of natural light availability from indoor/outdoor exposure or due to seasons (winter/summer). Darkness into the eye tells the brain to make melatonin so the body can prepare for sleep mode. Its production should peak at night and it is instrumental for maintaining quality sleep patterns. Melatonin production declines significantly with age, often causing sleep difficulties associated with aging. Supplemental melatonin was shown in studies to help with falling asleep when taken about 30 minutes before the desired sleep time. The most common dose found effective for sleep was 3mg - which is exactly what we’ve included in Whole Sleep.</p>\n' +
                     '<p>5-HTP can be used in conjunction with melatonin, as a precursor to serotonin, which can further support endogenous melatonin production during the night to help with staying asleep. When using 5-HTP for enhancing serotonin and melatonin production to promote healthy mood and sleep, the addition of the synergistic nutrient pyridoxal-5-phosphate, an activated form of vitamin B6, is suggested to catalyze the conversion of 5-HTP to serotonin (5-HT).</p>\n'
        },
        {
            title: 'How do I take Whole Sleep?',
            content: '<p>For best results, take the recommended dose of Whole Sleep 30 - 60 minutes before bedtime or the time you’d like to go to bed. Included with your first order is the Better Sleep Guide with my 5 Senses guide and practical tips for creating an optimal sleep environment to support you through the day and right into bed.</p>\n'
        },
        {
            title: 'How long should I take it?',
            content: '<p>Whole Sleep can be taken daily without the side effects that are common with sleeping pills. You can also take it during a transition time until you have reset your healthy sleep habits.</p>\n'
        },
        {
            title: 'Can I use it with my other medications/medical condition?',
            content: '<p>Always consult your doctor to ensure that Whole Sleep can be combined with your current medications. Whole Sleep is not a medication but it does have herbs and compounds that work with your body that may interact with the other things you are taking.</p>\n'
        },
        {
            title: 'Caution with GABA',
            content: '<p>Due to the inclusion of GABA, this product is not recommended for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</p>\n' +
                     '<p>Conditions for which melatonin is not recommended</p>\n' +
                     '<ul class="checkmark--supplements-disc">\n' +
                            '<li>Autoimmune conditions such as lupus or arthritis, because the immune stimulatory effect of melatonin may exacerbate the action of certain types of lymphocytes or B-cells involved in the pathogenic course of these diseases</li>\n' +
                            '<li>Immune-related cancers such as lymphoma and leukemia</li>\n' +
                            '<li>Pregnancy, lactation or during the time where fertility is desired</li></ul>\n'

        },
        {
            title: 'Interactions with drug therapy',
            content: '<p>Melatonin and 5-HTP may not be suitable to administer along with SSRI and MAOI medications and corticosteroid therapy. Please discuss this with your health care provider before using Whole Sleep with these medication classes.</p>\n'
        },
        {
            title: 'Is it safe to take while pregnant or nursing?',
            content: '<p>It is always recommended to consult with your health care practitioner before taking any supplements when pregnant or nursing. Due to the inclusion of GABA, this product is not recommended for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</p>\n' +
                     '<p>Melatonin is also not recommended during pregnancy, lactation or during the time where fertility is desired.</p>\n'
        },
        {
            title: 'Do you ship to countries outside of the United States?',
            content: '<p>We would absolutely LOVE to, however each country sets specific regulations on the import of plant based products, so we are only able to ship only to the USA at this time. We can’t guarantee it will make it to you through customs due to regulations your country may have on imports. We apologize for this inconvenience!</p>\n'
        }
    ],
    'rr': [
        {
            title: 'What are Essential Amino Acids?',
            content: '<p>Of the 20 amino acids, there are 9 that the body cannot make on its own and must get from dietary sources. All 9 of these essential amino acids (EAA’s), which include the branch chain amino acids (BCAA’s), are included in Rock and Restore.</p>\n' +
                     '<p>Unlike fat and carbohydrates which the body can store easily for later in the liver and in fat cells, the body doesn’t store amino acids for later use - which is why it’s important to include protein regularly throughout the day in your meals.</p>\n' +
                     '<p>The protein you eat gets broken down into amino acids, but different foods provide different combinations of amino acids and not every food that contains protein contains all the essential aminos.</p>\n' +
                     '<p>Without adequate consumption of all of the EAA’s, the body may break down healthy tissue like muscle tissue to obtain the amino acids it needs to perform essential functions.</p>\n'
        },
        {
            title: 'How do I use Rock and Restore?',
            content: '<p>You can use it before, during or after a workout - all times will support and replenish your essential amino acid stores. Mix 1 scoop into 8-12 oz of water (Betty Rocker likes hers with more water than less) and combine until well blended using a shaker bottle or stirring vigorously. Since this is a free-form amino acid and not dietary protein, you will not feel “full” from drinking it and can eat normally post-workout.</p>\n' +
                     '<p>You can also use it on non-workout days if you feel you are not taking in enough dietary protein, as it will help supplement your overall essential amino acid intake.</p>\n'
        },
        {
            title: 'How does Rock and Restore support my workouts?',
            content: '<p>Rock and Restore contains all 3 of the branch-chain amino acids (BCAA’s). BCAA’s are well known for supporting muscle growth, repair and recovery and Rock and Restore contains an optimal dose of the BCAA leucine, considered the most anabolic amino acid (promoting muscle protein synthesis and metabolic activity), which is particularly applicable for athletes and anyone looking to build lean muscle mass.</p>\n' +
                     '<p>Drink it before, during or after your workout to provide your body with the building blocks needed for muscle protein synthesis and repair and recovery of muscle tissue.</p>\n'
        },
        {
            title: 'Is Rock and Restore Gluten and Dairy Free? Vegetarian? Are there added sugars?',
            content: '<p>Yes, this formula is 100% gluten and dairy free, contains no added sugars, and is also vegetarian and hypo-allergenic.</p>\n'
        },
        {
            title: 'Can you ship this outside of the continental USA?',
            content: '<p>We would absolutely LOVE to, however each country sets specific regulations on what kind of food products can be imported so we ship only to the USA at this time. We can’t guarantee it will make it to you through customs due to regulations your country may have on importing supplements. We apologize for this inconvenience!</p>\n'
        }
    ]


}

export default faqsData;
