import React, {useEffect, useState} from "react"
import Faqs from "../../components/Faqs";
import Tabs from "../../components/Tabs"
import {Helmet} from "react-helmet";
import AccordionItem from "../../components/AccordionItem"

const VanillaProtein = ({updateActiveTab}) => {

    const [activeTab, setActiveTab] = useState('about')

    const handleActive = (el) => {
        setActiveTab(el);
    }

    useEffect(() => {
        setActiveTab(updateActiveTab)
    },[updateActiveTab])

    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="I ❤ Vanilla Protein"/>
                <meta property="og:description" content="100% organic, high protein (20g protein per serving), nutrient-dense whole food protein powder combining 4 plant-based protein sources into a delicious vanilla shake to support an active lifestyle."/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/vanilla-protein-FB.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of I ❤ Vanilla Protein." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="I ❤ Vanilla Protein"/>
                <meta name="twitter:description" content="100% organic, high protein (20g protein per serving), nutrient-dense whole food protein powder combining 4 plant-based protein sources into a delicious vanilla shake to support an active lifestyle."/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/vanilla-protein-FB.jpg"/>
                <meta name="twitter:image:alt" content="Image of I ❤ Vanilla Protein." />
                <meta name="twitter:card" content="summary_large_image"/>
                <title>I ❤ Vanilla Protein</title>
            </Helmet>

            {/*tab menu*/}
            <section className="pt--70 pt--md-30 pb--0">

                <Tabs active={activeTab} handleActive={handleActive}/>

                {/*tab content*/}
                <div className="tab-content">
                    {/*tab content about*/}
                    { (activeTab === "about") &&
                    <div id="about">
                        {/*Support for your active lifestyle*/}
                        <section className="container container--smd pt--70 pt--md-30 pb--30">
                            <h2 className="mb--30 h2--supplements mb--xxs-10">Support for your active lifestyle with delicious vanilla protein!</h2>
                            <p className="txt--24 txt--xxxs-22 txt--center txt--dark">Our superfood protein blend supports your body from the inside out. With 20 grams of organic plant protein (containing all the essential amino acids), you'll be loving the energy and vitality you get each time you shake it, bake it, or blend it up with <strong className="fw--bold">"I <i className="fa fa-heart txt--pink"></i> Vanilla Protein!"</strong></p>
                        </section>

                        <div className="container">
                            <img src="/images/supplements/vanilla-protein/vanilla-protein-betty.png" className="no-shadow radius-0 mb--60 mx--auto" alt="Vanilla protein." width="1171" height="736"/>
                        </div>

                        {/*5 Key Ways*/}
                        <section className="bg--supplements-vp-waves section--padding-lg">
                            <div className="container container--smd">
                                <h2 className="h2--supplements mb--30">5 Key Ways Protein <span className="d-inline-block">Supports Your Body</span></h2>

                                <div className="supplements--vanilla-box">
                                    <div className="supplements--vanilla-box__header">
                                        <div className="circle circle--pink">
                                            <img src="/images/supplements/vanilla-protein/key-1.png" className="no-shadow radius-0" alt="Energy source icon." width="67" height="58"/>
                                        </div>

                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-block d-md-none"><strong className="fw--medium">1. ENERGY SOURCE</strong></h6>
                                    </div>

                                    <div className="pl--30 pl--sm-0 d-flex flex-column justify-content-center">
                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-none d-md-block"><strong className="fw--medium">1. ENERGY SOURCE</strong></h6>
                                        <p className="p--supplements">Just like the other macronutrients (fat and carbohydrates), protein provides your body with the energy you need to move, think and perform your daily activities.</p>
                                    </div>
                                </div>

                                <div className="supplements--vanilla-box">
                                    <div className="supplements--vanilla-box__header">
                                        <div className="circle circle--pink">
                                            <img src="/images/supplements/vanilla-protein/key-2.png" className="no-shadow radius-0" alt="Satisfaction and fullness Icon." width="65" height="62"/>
                                        </div>

                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-block d-md-none"><strong className="fw--medium">2. SATISFACTION AND FULLNESS</strong></h6>
                                    </div>

                                    <div className="pl--30 pl--sm-0 d-flex flex-column justify-content-center">
                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-none d-md-block"><strong className="fw--medium">2. SATISFACTION AND FULLNESS</strong></h6>
                                        <p className="p--supplements">Protein is the most satisfying macronutrient (even more satisfying than fat or carbs). Including protein with each meal can help you feel more full and satisfied, and can help keep you from overeating, prevent blood sugar instability, and prevent cravings. If you are trying to lose weight or body fat, incorporating protein in your diet can make it easier to reach your goals.</p>
                                    </div>
                                </div>

                                <div className="supplements--vanilla-box">
                                    <div className="supplements--vanilla-box__header">
                                        <div className="circle circle--pink">
                                            <img src="/images/supplements/vanilla-protein/key-3.png" className="no-shadow radius-0" alt="Building blocks of the body Icon." width="64" height="63"/>
                                        </div>

                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-block d-md-none"><strong className="fw--medium">3. BUILDING BLOCKS OF THE BODY</strong></h6>
                                    </div>

                                    <div className="pl--30 pl--sm-0 d-flex flex-column justify-content-center">
                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-none d-md-block"><strong className="fw--medium">3. BUILDING BLOCKS OF THE BODY</strong></h6>
                                        <p className="p--supplements">Protein provides the structure for your bones, muscles, cartilage, skin, hair and nails.</p>
                                    </div>
                                </div>

                                <div className="supplements--vanilla-box">
                                    <div className="supplements--vanilla-box__header">
                                        <div className="circle circle--pink">
                                            <img src="/images/supplements/vanilla-protein/key-4.png" className="no-shadow radius-0" alt="Tissue repair Icon." width="61" height="62"/>
                                        </div>

                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-block d-md-none"><strong className="fw--medium">4. TISSUE REPAIR</strong></h6>
                                    </div>

                                    <div className="pl--30 pl--sm-0 d-flex flex-column justify-content-center">
                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-none d-md-block"><strong className="fw--medium">4. TISSUE REPAIR</strong></h6>
                                        <p className="p--supplements">Protein is essential for the repair of your body tissues after injury, and after training. This is one of the reasons why protein is one of the most important macronutrients to consume if you’re exercising regularly.</p>
                                    </div>
                                </div>

                                <div className="supplements--vanilla-box">
                                    <div className="supplements--vanilla-box__header">
                                        <div className="circle circle--pink">
                                            <img src="/images/supplements/vanilla-protein/key-5.png" className="no-shadow radius-0" alt="Regulation of essential body processes Icon." width="58" height="61"/>
                                        </div>

                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-block d-md-none"><strong className="fw--medium">5. REGULATION OF ESSENTIAL BODY PROCESSES</strong></h6>
                                    </div>

                                    <div className="pl--30 pl--sm-0 d-flex flex-column justify-content-center">
                                        <h6 className="h6--supplements txt--uppercase mb--10 txt--pink d-none d-md-block"><strong className="fw--medium">5. REGULATION OF ESSENTIAL BODY PROCESSES</strong></h6>
                                        <p className="p--supplements">Amino acids from protein form important hormones like insulin, human growth hormone, ACTH and glucagon - important for the regulation of blood sugar regulation, metabolism, and growth. They are also the foundation of enzymes, which speed up nearly all chemical reactions that happen in the cells - including those of digestion and metabolism.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*20 grams of premium*/}
                        <section className="section--padding-lg container">
                            <h2 className="h2--supplements mb--60 mb--md-30"><span className="txt--pink">20 grams of premium plant based protein</span><br className="d-none d-md-block"/> from 4 superfood sources with all the essential amino acids to <br className="d-none d-lg-block"/> <span className="txt--pink">fuel your workouts, boost your energy and support your body:</span></h2>

                            <div className="row flex-wrap">
                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-north-american-pea flex-shrink-0"></div>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">North American Pea</h6>
                                        <p className="p--supplements txt--xs-16">Highly digestible and absorbable high-quality plant protein that contains all the essential amino acids needed for muscle synthesis and repair, plus it’s rich in iron, arginine and branched-chain amino acids.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-chia-seeds flex-shrink-0"></div>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">Chia Seeds</h6>
                                        <p className="p--supplements txt--xs-16">A powerhouse superfood that’s loaded with heart-healthy fiber, protein, brain-friendly omega-3 fatty acids, B-vitamins, and magnesium. The antioxidants present in chia seeds (which also include quercetin, the most powerful antioxidant in the body) help to curb the levels of free radicals in the body, and also support healthy skin through the repair of skin cells.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-pumpkin-seeds flex-shrink-0"></div>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">Pumpkin Seeds</h6>
                                        <p className="p--supplements txt--xs-16">Rich in protein, fiber and unsaturated fatty acids pumpkin seeds can support healthy cholesterol and play a role in healthy weight management. They are also one of the best sources of magnesium, essential for blood pressure and blood sugar levels, as well as heart and bone health. Pumpkin seeds also contain antioxidants like carotenoids and vitamin E, supporting a healthy immune system.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-cranberry-seeds flex-shrink-0"></div>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">Cranberry Seeds</h6>
                                        <p className="p--supplements txt--xs-16">A healthy super seed that contains copper, aiding in iron absorption and manganese, which aids in protein and amino acid digestion and utilization, as well as the metabolism of cholesterol and carbohydrates. Manganese also helps your body utilize a number of vitamins, such as choline, thiamine, and vitamins C and E, and ensures proper liver function.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*3 ways to enjoy*/}
                        <section className="bg--supplements-vp-drink section--padding-lg">
                            <div className="container">

                                <div className="row">
                                    <div className="col-xs-12 col-md-8 col-lg-5">
                                        <div className="d-flex align-items-center justify-content-center mb--60 mb--sm-30">
                                            <img src="/images/supplements/three.png" className="mr--10 no-shadow radius-0" alt="Number Three"/>
                                            <h2 className="h2--supplements txt--left">Easy Ways to Enjoy <span className="d-inline-block">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein</span></h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-md-6 col-lg-5">
                                        <div className="d-flex mb--40">
                                            <div className="circle circle--pink">
                                                <img src="/images/supplements/shake.png" className="no-shadow radius-0" alt="Shake it Icon" width="33" height="57" />
                                            </div>

                                            <div className="pl--20">
                                                <h6 className="h6--supplements txt--uppercase mb--10"><strong className="fw--bold">shake it</strong></h6>
                                                <p className="txt--16">Mix <strong className="fw--bold">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein</strong> with just water and enjoy a fast, smooth and delicious protein and greens infusion!</p>
                                            </div>
                                        </div>

                                        <div className="d-flex mb--40">
                                            <div className="circle circle--pink">
                                                <img src="/images/supplements/blend.png" className="no-shadow radius-0" alt="Blend it Icon" width="36" height="56"/>
                                            </div>

                                            <div className="pl--20">
                                                <h6 className="h6--supplements txt--uppercase mb--10"><strong className="fw--bold">blend it</strong></h6>
                                                <p className="txt--16">Add your <strong className="fw--bold">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein</strong> to a smoothie to increase the variety of greens and boost your protein content!</p>
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <div className="circle circle--pink">
                                                <img src="/images/supplements/bake.png" className="no-shadow radius-0" alt="Bake it Icon" width="54" height="50" />
                                            </div>

                                            <div className="pl--20">
                                                <h6 className="h6--supplements txt--uppercase mb--10"><strong className="fw--bold">bake it</strong></h6>
                                                <p className="txt--16">Add <strong className="fw--bold">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein</strong> to your favorite power bars and muffins to boost the nutritional value and add a delicious strawberry vanilla sweetness!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <img src="/images/supplements/vanilla-protein/betty-vanilla-drink-mob.jpg" alt="Vanilla Protein." className="no-shadow radius-0 d-block d-md-none" width="767" height="511"/>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center no-gutters">
                                    <div className="col-12 col-md-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--29 txt--xxxs-20 font--oswald mb--20">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/vanilla-protein/bonus-3.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="633" height="244"/>
                                    </div>
                                  </div>
                            </div>
                        </div>

                        {/*quality support*/}
                        <section className="container section--padding-xl pb--0i">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"></iframe>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/vanilla-protein/vanilla-support.png" alt="Whole Betty" className="no-shadow radius-0 mb--20" width="384" height="64"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                        {/*whats inside*/}
                        <section className="bg--supplements-vanilla section--padding-lg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <h2 className="mb--30 h2--supplements txt--left">What's Inside</h2>
                                        <p className="p--supplements"><strong className="fw--bold">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein</strong> is an organic, nutrient-dense complete protein powder. “Complete”  means it contains all 9 of the essential amino acids your body cannot make on its own.</p>
                                        <p className="p--supplements">We’ve blended the protein of 4 plants, including natural pea protein isolate, organic chia seed, organic pumpkin seed, and organic cranberry seed. We use organic natural vanilla flavor and organic monk fruit to bring out its balanced sweetness and tasty vanilla flavor. We added a little organic coconut milk powder to make sure it blends well and has a smooth, creamy texture without the use of dairy, fillers, or chemical additives.</p>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <img src="/images/supplements/vanilla-protein/vanilla-mob.jpg" className="mt--20 no-shadow radius-0 d-block mx--auto d-md-none" alt="Vanilla Protein." width="861" height="412"/>

                        {/*ingredients*/}
                        <section className="bg--supplements-section section--padding-xl">
                            <div className="container">

                                <h2 className="mb--30 h2--supplements txt--left">Ingredients</h2>

                                <div className="row">

                                    <div className="col-xs-12 col-lg-5 txt--center">
                                        <img src="/images/supplements/vanilla-protein/vanilla-supplements.jpg" className="no-shadow radius-0 mb--20" alt="Supplements table" width="469" height="570"/>
                                        <div className="txt--left mb--20">
                                            <p className="p--supplements"><strong className="fw--bold">Ingredients:</strong> Protein blend (organic pea protein, organic pumpkin seed protein, organic cranberry seed protein, organic chia seed protein), organic natural flavors, <span className="circle circle--xxs circle--pink">2</span> organic coconut milk powder, organic monk fruit. <span className="circle circle--xxs circle--pink">3</span></p>
                                            <p className="p--supplements"><strong className="fw--bold">Contains tree nuts (coconut).</strong> <span className="circle circle--xxs circle--pink">4</span></p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-lg-7 accordion-counter-reset">

                                        <AccordionItem title="Protein" counter={true} icon="plus" modifierClasses="accordion--pink" open={true}>
                                            <p className="p--supplements">20 grams of protein is a versatile serving size that can be enjoyed on its own, boost the nutritional balance of a smoothie or any baked good. </p>
                                            <p className="p--supplements">When combined with a whole food based diet, protein powder can ensure you have all the nutrients you need to support your active lifestyle, while helping to reduce cravings by leaving you feeling full and satisfied.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Organic Natural Flavors" counter={true} icon="plus" modifierClasses="accordion--pink">
                                            <p className="p--supplements">All “natural flavors” must be derivatives of a natural plant substance. However there is a lot of margin around how natural these products end up being, as how they are processed really makes a difference.</p>
                                            <p className="p--supplements">Not only do we only use certified organic natural vanilla flavor, we distill these flavors out of our plants using non-chemical methods that do not compromise their quality or integrity.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Organic Monk Fruit" counter={true} icon="plus" modifierClasses="accordion--pink">
                                            <p className="p--supplements">Also known as “luo Han go” or “Buddha fruit” monk fruit is a small, round fruit native to southeast Asia. Monk fruit contains natural sugars, but actually derives its sweetness from unique antioxidants called “mogrosides” which are the part that we separate out and use to add sweetness to our shake.</p>
                                            <p className="p--supplements">It contains zero calories and is far sweeter than regular sugar - meaning we only need a tiny amount to give every serving the perfect balance.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Contains Tree Nuts (Coconut)" counter={true} icon="plus" modifierClasses="accordion--pink">
                                            <p className="p--supplements">We add coconut milk powder to our vanilla protein to make it blend well and have a creamy texture (there is no coconut flavor to the shake).</p>
                                            <p className="p--supplements">The FDA requires us to label our product with “contains tree nuts” because we use coconut milk powder. If you have a serious nut allergy, you should check with your doctor about the safety of coconut-derived products. But this product does not contain anything else that could be classified as a nut.</p>
                                        </AccordionItem>

                                    </div>
                                </div>

                            </div>
                         </section>

                        {/*pink section*/}
                        <section className="bg--pink py--30">
                            <div className="container">

                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-lg-4 txt--center-md txt--right order-2 order-lg-1">
                                        <img src="/images/supplements/betty.png" className="no-shadow radius-0" alt="Betty Image." width="214" height="311"/>
                                    </div>

                                    <div className="col-xs-12 col-lg-8 order-1 order-lg-2">
                                        <h3 className="h3--supplements ls--xxl txt--white d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row txt--uppercase mb--30"><span className="no-shrink">whole body <strong className="fw--light">by</strong></span> <img src="/images/supplements/logo-white.png" className="no-shadow radius-0 img--header ml--10" alt="White logo" width="416" height="73"/></h3>
                                        <p className="txt--white p--supplements txt--center-md mb--0 mb--md-20">At Whole Betty, we believe that Whole Foods should form the foundation for what you put in your body. That’s why our ingredients are mindfully sourced and our blends are consciously created so you get the maximum health benefit, every time.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>}

                    {/*tab content nutrition*/}
                    { (activeTab === "nutrition") &&
                    <div id="nutrition">
                        {/*whats inside*/}
                        <section className="bg--supplements-vanilla section--padding-lg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <h2 className="mb--30 h2--supplements txt--left">What's Inside</h2>
                                        <p className="p--supplements"><strong className="fw--bold">I <i className="fa fa-heart txt--pink"/> Vanilla Protein</strong> is an organic, nutrient-dense complete protein powder. “Complete”  means it contains all 9 of the essential amino acids your body cannot make on its own.</p>
                                        <p className="p--supplements">We’ve blended the protein of 4 plants, including natural pea protein isolate, organic chia seed, organic pumpkin seed, and organic cranberry seed. We use organic natural vanilla flavor and organic monk fruit to bring out its balanced sweetness and tasty vanilla flavor. We added a little organic coconut milk powder to make sure it blends well and has a smooth, creamy texture without the use of dairy, fillers, or chemical additives.</p>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <img src="/images/supplements/vanilla-protein/vanilla-mob.jpg" className="mt--20 no-shadow radius-0 d-block mx--auto d-md-none" alt="Vanilla Protein." width="861" height="412"/>

                        {/*ingredients*/}
                        <section className="bg--supplements-section section--padding-xl">
                            <div className="container">

                                <h2 className="mb--30 h2--supplements txt--left">Ingredients</h2>

                                <div className="row">

                                    <div className="col-xs-12 col-lg-5 txt--center">
                                        <img src="/images/supplements/vanilla-protein/vanilla-supplements.jpg" className="no-shadow radius-0 mb--20" alt="Supplements table" width="469" height="570"/>
                                        <div className="txt--left mb--20">
                                            <p className="p--supplements"><strong className="fw--bold">Ingredients:</strong> Protein blend (organic pea protein, organic pumpkin seed protein, organic cranberry seed protein, organic chia seed protein), organic natural flavors, <span className="circle circle--xxs circle--pink">2</span> organic coconut milk powder, organic monk fruit. <span className="circle circle--xxs circle--pink">3</span></p>
                                            <p className="p--supplements"><strong className="fw--bold">Contains tree nuts (coconut).</strong> <span className="circle circle--xxs circle--pink">4</span></p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-lg-7 accordion-counter-reset">

                                        <AccordionItem title="Protein" counter={true} icon="plus" modifierClasses="accordion--pink" open={true}>
                                            <p className="p--supplements">20 grams of protein is a versatile serving size that can be enjoyed on its own, boost the nutritional balance of a smoothie or any baked good. </p>
                                            <p className="p--supplements">When combined with a whole food based diet, protein powder can ensure you have all the nutrients you need to support your active lifestyle, while helping to reduce cravings by leaving you feeling full and satisfied.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Organic Natural Flavors" counter={true} icon="plus" modifierClasses="accordion--pink">
                                            <p className="p--supplements">All “natural flavors” must be derivatives of a natural plant substance. However there is a lot of margin around how natural these products end up being, as how they are processed really makes a difference.</p>
                                            <p className="p--supplements">Not only do we only use certified organic natural vanilla flavor, we distill these flavors out of our plants using non-chemical methods that do not compromise their quality or integrity.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Organic Monk Fruit" counter={true} icon="plus" modifierClasses="accordion--pink">
                                            <p className="p--supplements">Also known as “luo Han go” or “Buddha fruit” monk fruit is a small, round fruit native to southeast Asia. Monk fruit contains natural sugars, but actually derives its sweetness from unique antioxidants called “mogrosides” which are the part that we separate out and use to add sweetness to our shake.</p>
                                            <p className="p--supplements">It contains zero calories and is far sweeter than regular sugar - meaning we only need a tiny amount to give every serving the perfect balance.</p>
                                        </AccordionItem>

                                        <AccordionItem title="Contains Tree Nuts (Coconut)" counter={true} icon="plus" modifierClasses="accordion--pink">
                                            <p className="p--supplements">We add coconut milk powder to our vanilla protein to make it blend well and have a creamy texture (there is no coconut flavor to the shake).</p>
                                            <p className="p--supplements">The FDA requires us to label our product with “contains tree nuts” because we use coconut milk powder. If you have a serious nut allergy, you should check with your doctor about the safety of coconut-derived products. But this product does not contain anything else that could be classified as a nut.</p>
                                        </AccordionItem>

                                    </div>
                                </div>

                            </div>
                        </section>

                        {/*20 grams of premium*/}
                        <section className="section--padding-lg container">
                            <h2 className="h2--supplements mb--60 mb--md-30"><span className="txt--pink">20 grams of premium plant based protein</span><br className="d-none d-md-block"/> from 4 superfood sources with all the essential amino acids to <br className="d-none d-lg-block"/> <span className="txt--pink">fuel your workouts, boost your energy and support your body:</span></h2>

                            <div className="row flex-wrap">
                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-north-american-pea flex-shrink-0"/>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">North American Pea</h6>
                                        <p className="p--supplements txt--xs-16">Highly digestible and absorbable high-quality plant protein that contains all the essential amino acids needed for muscle synthesis and repair, plus it’s rich in iron, arginine and branched-chain amino acids.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-chia-seeds flex-shrink-0"/>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">Chia Seeds</h6>
                                        <p className="p--supplements txt--xs-16">A powerhouse superfood that’s loaded with heart-healthy fiber, protein, brain-friendly omega-3 fatty acids, B-vitamins, and magnesium. The antioxidants present in chia seeds (which also include quercetin, the most powerful antioxidant in the body) help to curb the levels of free radicals in the body, and also support healthy skin through the repair of skin cells.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-pumpkin-seeds flex-shrink-0"/>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">Pumpkin Seeds</h6>
                                        <p className="p--supplements txt--xs-16">Rich in protein, fiber and unsaturated fatty acids pumpkin seeds can support healthy cholesterol and play a role in healthy weight management. They are also one of the best sources of magnesium, essential for blood pressure and blood sugar levels, as well as heart and bone health. Pumpkin seeds also contain antioxidants like carotenoids and vitamin E, supporting a healthy immune system.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb--20 d-flex mb--30">
                                    <div className="mr--xxxs-0 mr--20 vanilla-ingredients-cranberry-seeds flex-shrink-0"/>
                                    <div className="col--content">
                                        <h6 className="h6--supplements mb--10 txt--pink">Cranberry Seeds</h6>
                                        <p className="p--supplements txt--xs-16">A healthy super seed that contains copper, aiding in iron absorption and manganese, which aids in protein and amino acid digestion and utilization, as well as the metabolism of cholesterol and carbohydrates. Manganese also helps your body utilize a number of vitamins, such as choline, thiamine, and vitamins C and E, and ensures proper liver function.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center no-gutters">
                                    <div className="col-12 col-md-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--29 txt--xxxs-20 font--oswald mb--20">I <i className="fa fa-heart txt--pink"/> Vanilla Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/vanilla-protein/bonus-3.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="633" height="244"/>
                                    </div>
                                  </div>
                            </div>
                        </div>

                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/vanilla-protein/vanilla-support.png" alt="Whole Betty" className="no-shadow radius-0 mb--20" width="384" height="64"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--xxxs-0 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>
                    </div>}

                    {/*tab content whybuy*/}
                    { (activeTab === "quality") &&
                    <div id="quality">
                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/vanilla-protein/vanilla-support.png" alt="Whole Betty" className="no-shadow radius-0 mb--20" width="384" height="64"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--xxxs-0 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center no-gutters">
                                    <div className="col-12 col-md-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--29 txt--xxxs-20 font--oswald mb--20">I <i className="fa fa-heart txt--pink"/> Vanilla Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/vanilla-protein/bonus-3.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="633" height="244"/>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>}

                    {/*tab content faq*/}
                    { (activeTab === "faq") &&
                    <div id="faq">
                        {/*faq*/}
                        <section className="container container--xs section--padding-xl" id="faq">
                            <h2 className="h2--supplements mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                            <Faqs productName='vp'/>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center no-gutters">
                                    <div className="col-12 col-md-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--29 txt--xxxs-20 font--oswald mb--20">I <i className="fa fa-heart txt--pink"/> Vanilla Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/vanilla-protein/bonus-3.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="633" height="244"/>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </section>

            {/*what our rockstars are saying*/}
            <section className="bg--supplements-section section--padding-xl">
                <div className="container">
                    <h2 className="mb--80 mb--md-20 h2--supplements txt--pink">What Our Rockstars are Saying About<br className="d-none d-md-block"/> <span className="d-inline-block txt--black">I <i className="fa fa-heart txt--pink"></i> Vanilla Protein</span>...</h2>

                    <div className="row">

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Roz V" src="https://player.vimeo.com/video/492151707" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I've been loving the I <i className="fa fa-heart txt--pink"/> Vanilla Protein protein powder! I use vanilla protein almost every day, and I'm always on the lookout for one that is gluten free, dairy free and made with high quality ingredients.”</i><span className="d-block">- <strong className="fw--bold">Roz V.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Nikki C." src="https://player.vimeo.com/video/492156660" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“One of the things that I love the most is the taste, because I am really, really picky about my protein powder. It has a great taste, it mixes in really well, and it blends great!”</i><span className="d-block">- <strong className="fw--bold">Nikki C.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Alyssa C." src="https://player.vimeo.com/video/492132945" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I absolutely love this product for so many reason - first because it's absolutely delicious, and secondly because it's so versatile!”</i><span className="d-block">- <strong className="fw--bold">Alyssa C.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Barb S." src="https://player.vimeo.com/video/492618876" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I love that it has no fillers, additives or chemicals. It's a great product that tastes great, and it's very easy to drink.”</i><span className="d-block">- <strong className="fw--bold">Barb S.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Donna P." src="https://player.vimeo.com/video/492148311" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“One thing I love about it is that it has a nice subtle sweetness and vanilla flavor that is not overpowering like a lot of protein powders can be.”</i><span className="d-block">- <strong className="fw--bold">Donna P.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20 mb--sm-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Neesha R." src="https://player.vimeo.com/video/492150225" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“It tastes like real ingredients because it IS real ingredients!”</i><span className="d-block">- <strong className="fw--bold">Neesha R.</strong></span></p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default VanillaProtein
