import productData from "../../static/data/product";
import ProductBox from "./ProductBox";
import React, {useEffect, useState} from "react";

const ProductBoxCollectionContainer = ({collection, productId, variantId, type, item, handlePopup, handleProduct, handleShopify}) => {

    const [shopifyVariantId, setShopifyVariantId] = useState('')

    useEffect(() => {
        if(item.variants[0].id.indexOf("Shopify__ProductVariant__") !== -1) {
            setShopifyVariantId(item.variants[0].id.split("Shopify__ProductVariant__")[1])
        }
    },[variantId])

    return (
        <>
            {
                (shopifyVariantId === productId && productData[shopifyVariantId].collection === collection && variantId !== shopifyVariantId) &&
                <div className="col-12 col-sm-6 col-lg-4 margin-responsive">
                    <ProductBox
                        handlePopup={handlePopup}
                        handleProduct={handleProduct}
                        type={productData[shopifyVariantId].type}
                        item={item}
                        handleShopify={handleShopify}
                        product={productData[shopifyVariantId]}/>
                </div>
            }
        </>
    )
}
export default ProductBoxCollectionContainer
