import React from "react";
import { slideUp, slideToggle} from "../utilities/slide";

const AccordionItem = ({ title, children, counter, icon, modifierClasses, open, img, handleActiveEl, number }) => {

    const toggleAccordion = (e) => {
        e.preventDefault();

        if(img) {
            handleActiveEl(img)
        }
        if(number) {
            handleActiveEl(number)
        }

        let accordionItem = e.target.closest('.accordion')
        accordionItem.classList.toggle('open')
        slideToggle(accordionItem.querySelector('.accordion__content'), accordionItem)

        for (let sibling of accordionItem.parentNode.children) {
            if (sibling !== accordionItem) {
                sibling.classList.remove('open');
                slideUp(sibling.querySelector('.accordion__content'), sibling);
            }
        }
    }

    return (
        <div
            tabIndex={0}
            role='button'
            onClick={toggleAccordion}
            onKeyDown={toggleAccordion}
            className={`accordion ${ icon ? 'accordion--' + icon : ''} ${open ? 'open' : ''} ${ modifierClasses ? modifierClasses : '' }`}>
            <div
                className="accordion__header">
                { counter && <span className={"accordion-counter"}/>}
                {img && <img src={`/images/supplements/whole-sleep/icon-${img}.png`} className="mr--10" alt="" />}
                <h6 className="h6--supplements m--0"><strong className="fw--bold">{title}</strong></h6>
            </div>
            <div className="accordion__content" style={{ display: (open) ? 'block' : 'none'}}>{children}</div>
        </div>
    )
}
export default AccordionItem
