import React from "react";

const Tabs = ({active, handleActive}) => {

    const changeActive = (e) => {
        handleActive(e.target.getAttribute('id'))
    }

    return (
        <div className="container">
            <div className="tabs tabs-supplements">
                <button id="about" onClick={changeActive} className={`tab ${ active === 'about' ? 'active' : '' }`}>about</button>
                <button id="nutrition" onClick={changeActive} className={`tab ${ active === 'nutrition' ? 'active' : '' }`}>nutrition</button>
                <button id="quality" onClick={changeActive} className={`tab ${ active === 'quality' ? 'active' : '' }`}>why buy</button>
                <button id="faq" onClick={changeActive} className={`tab ${ active === 'faq' ? 'active' : '' }`}>FAQ</button>
            </div>
        </div>
    )
}
export default Tabs
