import React from "react";

import FaqItem from "./FaqItem";

import faqsData from "../../static/data/faqs";

const Faqs = ({color, productName}) => {

    const faq = faqsData

    return (
        <div className={`faqs ${color ? `faqs--${color}` : ''}`}>

            {
                Object.keys(faq[productName]).map((item, index) => (
                    <FaqItem
                        key={index}
                        item={faq[productName][item]}/>
                ))
            }
        </div>
    )
}
export default Faqs
