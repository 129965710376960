const samplePopupData = {
    'Popup 1' : [
        {
            id: 1,
            src: '/images/sample-pages/popup1-sample1.jpg',
            width: '595',
            height: '840',
            video: false
        },
        {
            id: 2,
            src: '/images/sample-pages/popup1-sample2.jpg',
            width: '594',
            height: '840',
            video: false
        },
        {
            id: 3,
            src: '/images/sample-pages/popup1-sample3.jpg',
            width: '595',
            height: '843',
            video: false
        },
    ],
    'Popup 2' : [
        {
            id: 1,
            src: '/images/sample-pages/popup2-sample1.jpg',
            width: '595',
            height: '840',
            video: false
        },
        {
            id: 2,
            src: '/images/sample-pages/popup2-sample2.jpg',
            width: '594',
            height: '841',
            video: false
        },
        {
            id: 3,
            src: '/images/sample-pages/popup2-sample3.jpg',
            width: '595',
            height: '842',
            video: false
        },
    ],
    'Popup 3' : [
        {
            id: 1,
            src: '/images/sample-pages/popup3-sample1.jpg',
            width: '593',
            height: '843',
            video: false
        },
    ],
    'Popup 4' : [
        {
            id: 1,
            src: 'https://player.vimeo.com/video/312990415',
            width: '384',
            height: '216',
            video: true
        },
    ],
}

export default samplePopupData;
