import React from "react"
import { Helmet } from "react-helmet"
import {scrollTo} from "../../utilities/scrollTo";

import Faqs from "../Faqs";
import ScrollToTabs from "../ScrollToTabs";
import DigitalProductBuyBox from "../DigitalProductBuyBox";

const BodyFuelSystem = ({product}) => {
    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Body Fuel System - Get Lean and Stay Lean"/>
                <meta property="og:description" content="This 6-week easy eating system and meal plan (gluten and dairy free) is a complete blueprint to making foods work like a team in your body so you can burn more fat, feel more energized and create a healthy lifestyle."/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/bfs-fb.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Body Fuel System - Get Lean and Stay Lean." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Body Fuel System - Get Lean and Stay Lean"/>
                <meta name="twitter:description" content="This 6-week easy eating system and meal plan (gluten and dairy free) is a complete blueprint to making foods work like a team in your body so you can burn more fat, feel more energized and create a healthy lifestyle."/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/bfs-fb.jpg"/>
                <meta name="twitter:image:alt" content="Image of Body Fuel System - Get Lean and Stay Lean." />
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            {/*sales hero*/}
            <section className="hero pt--xs-30 bg--section">
                <div className="container txt--center">

                    <h1 className="txt--pink txt--uppercase h1--lg">Get lean and <span className="d-inline-block">stay lean</span></h1>
                    <h2 className="txt--40 txt--sm-36 txt--xs-32 txt--xxs-28 txt--xxxs-26 fw--regular mb--30">With my 3 Easy-Eating Styles and all of my Fat-Burning Recipes!</h2>

                    <div className="container container--sm">
                        <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--30">
                            <iframe title="Hero Video." src="https://player.vimeo.com/video/249962643"/>
                        </div>
                    </div>

                    <a className="btn btn--md mb--10 fw--medium" onClick={scrollTo} href="#order-now">start now</a>
                </div>
            </section>

            <ScrollToTabs supplements={false}/>

            {/*allow your body to look as good on the outside as you feel on the inside*/}
            <section className="container pb--20 arrow-down">
                <div className="row bg--bfs-betty pt--30 pt--md-0">
                    <div className="col-12 col-md-9 col-lg-7 offset-lg-4">
                        <h3 className="txt--pink txt--left mb--30"><strong>Allow Your Body To Look As Good On The <br className="d-none d-md-block"/>Outside As You Feel On The Inside…</strong></h3>

                        <ul className="mb--40">
                            <li><strong>BOOST your metabolism</strong> to its OPTIMAL state</li>
                            <li>Speed up FAT LOSS – <strong>naturally</strong></li>
                            <li><strong>ELIMINATE</strong> the need to count, track or obsess over calories, points or numbers</li>
                            <li><strong>LOOK and FEEL YOUR PERSONAL BEST…</strong> and stay that way</li>
                        </ul>

                        <div className="row h--sm-250 align-items-center">
                            <div className="col-8 offset-4 col-md-12 offset-md-0">
                                <h3 className="txt--left txt--sm-36 txt--md-40 mb--40"><strong>All While Eating Foods That Taste Great!</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gutters-responsive">
                    <div className="col-6 col-md-3 mb--30 mb--sm-15">
                        <div className="img-card">
                            <img className="shadow-0 lazy" src="/images/bfs/food-1.jpg" alt="" width="370" height="370"/>
                            <div className="img-card__title img-card__title--lg">Honey Salmon in Foil</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb--30 mb--sm-15">
                        <div className="img-card">
                            <img className="shadow-0 lazy" src="/images/bfs/food-2.jpg" alt="" width="370" height="370"/>
                            <div className="img-card__title img-card__title--lg">Apple Cherry Green Smoothie</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb--30 mb--sm-15">
                        <div className="img-card">
                            <img className="shadow-0 lazy" src="/images/bfs/food-3.jpg" alt="" width="370" height="370"/>
                            <div className="img-card__title img-card__title--lg">Sweet Potato Turkey Stack</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb--30 mb--sm-15">
                        <div className="img-card">
                            <img className="shadow-0 lazy" src="/images/bfs/food-4.jpg" alt="" width="370" height="370"/>
                            <div className="img-card__title img-card__title--lg">Black Bean Avocado Salad</div>
                        </div>
                    </div>
                </div>
            </section>

            {/*It’s Time To Ditch Your Diet*/}
            <section className="bg--section arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--40">It’s Time To Ditch Your Diet And Switch To <br className="d-none d-md-block"/> Eating Right For Your LIFE.</h2>

                    <img src="/images/bfs/enjoy-food.png" alt="" width="418" height="401" className="d-none d-md-block no-shadow pull--right ml--40 lazy mb--40"/>

                        <p><strong>Has this ever been you?</strong></p>
                        <p>You start a new diet, but after the first two or three weeks, you’re not seeing the results you were hoping for.</p>
                        <p>Turns out, it’s actually kind of hard to follow the program because you can’t eat your favorite foods without feeling guilty. And, it takes a lot of time to figure out how to do everything you’re supposed to do, and you’re already really busy.</p>
                        <p>You’re still struggling with having enough energy, and you’re wondering if you’re ever going to see your body respond. So you start to blame yourself.</p>
                        <p>You tell yourself that you don’t have enough discipline. You think that your metabolism is broken.  You feel like a failure. And worst of all, you start to lose confidence in YOURSELF.</p>
                        <p><strong>You’re busy, sometimes overwhelmed, overworked, tired, and you just don’t have TIME to constantly be a slave to some ineffective demanding diet that doesn’t get results.</strong></p>
                        <img src="/images/bfs/enjoy-food.png" alt="" width="418" height="401" className="d-block d-md-none no-shadow pull--right lazy mb--40"/>
                </div>
            </section>

            {/*testimonials*/}
            <section id="results" className="arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--60">But what if there was another option? Something that fits your lifestyle, rather than expecting YOU to fit with it?</h2>
                </div>
                <div className="container container--md">
                    <div className="row">
                        <div className="col-12 col-sm-6 mb--30">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-1.jpg" alt="Testimonial of Bree A." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Eating this way has given me freedom."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I used to feel like I wasn’t in control.</p>
                                        <p>Constantly struggling with my weight, my appearance and my erratic energy.</p>
                                        <p>I was uncomfortable being seen in a bathing suit and felt like something was wrong with me.</p>
                                        <p>Eating the way I do now has me looking and feeling younger in my 40's than I did in my 20's, and has empowered me to make best friends with my body and know exactly how to eat, in any situation.</p>
                                        <p>Eating this way has given me freedom!</p>
                                        <p className="txt--pink"><strong>- Bree A (me!)</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-2.jpg?v=1.0.1" alt="Testimonial of Alicia A." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"For the first time in my life, I can honestly say I'm happy with my body."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>This inspiration you’ve given me has led me to lose 24 1/2 inches and 20 pounds.</p>
                                        <p>For the first time in my life (I’m 23) I can honestly say I’m happy with my body.</p>
                                        <p className="txt--pink"><strong>- Alicia A.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-3.jpg?v=1.0.1" alt="Testimonial of Eric C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I've gone from 213lbs to 174lbs and I feel awesome!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I used to weight 213lbs and felt like crap everyday.</p>
                                        <p>My knee surgeries had me sitting on the couch all drugged up, losing muscle mass and getting fat.</p>
                                        <p>Come Halloween, fairly snug shirt was exposing my belly rolls and for the first time in my life, I was insecure about my body.</p>
                                        <p>I have been following Betty Rocker's recipes and anywhere workouts for a little under a year now.</p>
                                        <p>Since November, I've gone from 213lbs to 174lbs and I feel awesome!</p>
                                        <p>I feel like I'm 18 years old in high school again, fending women off me with a stick!</p>
                                        <p className="txt--pink"><strong>- Eric C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-4.jpg?v=1.0.1" alt="Testimonial of Katlyn C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Sorry I'm not sorry for Betty Rocking!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I started the Body Fuel System right after Thanksgiving to guide me through the end of the semester and finals.</p>
                                        <p>When I was done, I used the recipes and principles to guide my meals through my days of work and working out....and oh yeah, endless holiday food!</p>
                                        <p className="txt--pink"><strong>- Katlyn C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-5.jpg?v=1.0.1" alt="Testimonial of Lou H." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"5 weeks in and he has lost 22lbs and I have lost 13lbs and so stoked!!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>We decided to make changes in our lives and my hubby found you on the internet.</p>
                                        <p>We immediately bought the Body Fuel System and got started right away.</p>
                                        <p>5 weeks in and he has lost 22lbs and I have lost 13lbs and so stoked!</p>
                                        <p>Proud in so many ways so again, a big thank you for your motivation and dedication.</p>
                                        <p>Betty....you rocked our LIVES!</p>
                                        <p className="txt--pink"><strong>- Lou H.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-6.jpg?v=1.0.1" alt="Testimonial of Jenna T." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"The Body Fuel System was everything I needed to get over my weight plateau."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I’ve always hated cooking with a passion!</p>
                                        <p>Not to mention never had any good ideas for meals.</p>
                                        <p>So when it came to wanting to cut fat and tone up I was lost on where to start</p>
                                        <p>The Body Fuel System was the perfect solution for me!</p>
                                        <p>Not only was this plan easy to follow but the meals were delicious!</p>
                                        <p>Everything tasted amazing!!</p>
                                        <p>It was exactly what I needed to get over my weight plateau.</p>
                                        <p>If you are looking for some new and healthy ideas for meals I highly suggest the Body Fuel System!</p>
                                        <p className="txt--pink"><strong>- Jenna T.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="txt--center txt--15 lh--auto"><em>* Individual results may vary and may not be typical or representative of what you might expect. <br className="d-none d-lg-block"/>To learn more about our community’s experiences with our programs look at our Testimonial Support page.</em></p>

                </div>
            </section>

            <section className="bg--section">
                <div className="container">
                    <h2 className="h2--sm mb--40">Have You Ever <br/><span className="txt--pink">Thought Any of the Following?</span></h2>

                    <div className="row">
                        <div className="col-12 col-md-5 txt--center">
                            <img src="/images/bfs/woman-before-fridge.jpg" alt="" width="506" height="409" className="lazy shadow-3 mb--30"/>
                        </div>
                        <div className="col-12 col-md-7">
                            <ul>
                                <li>If I could just control my cravings I can tighten everything up…</li>
                                <li>I just need to force myself to do 1 hour of exercise a day…</li>
                                <li>If I can JUST stick to my daily calorie count, the extra weight will come off.</li>
                                <li>I shouldn’t eat bread or drink wine because low carb and low fat is the only way I can get lean…</li>
                                <li>My metabolism is to blame because I’m getting older…</li>
                                <li>Other people have an easier time than me because of their genetics…</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container container--md txt--center">
                <h2 className="h2--sm mb--40">Now I can’t read your mind, but I know that you’re <br className="d-none d-lg-block"/> probably nodding your head to at least a few of those.</h2>
                <p className="mb--40">And I can also tell you that <span className="txt--pink"><strong>I know</strong></span> because not so long ago I thought all of the things on that list were true - and that not following them was going to make me fat and keep me from losing the fat around my abs, hips and arms… even though following these “rules” was not creating any significant changes for me either.</p>

                <div className="box box--pink radius-5 arrow-down arrow-down--pink mb--60">
                    <h4 className="mb--30">In fact, before I figured out this simple strategy I’m going to share with you, <br className="d-none d-lg-block"/>I was lost in a sea of “Triets”…</h4>
                    <p>(that’s the “Try-Every-Diet” Diet… maybe you’ve heard of it…)</p>
                    <p>My Triet lifestyle was ridiculous, and it kept me locked into a roller coaster cycle of constant highs and lows that made me feel like I was never really in control.</p>
                </div>

                <img src="/images/bfs/triet-circle.png" alt="" width="658" height="431" className="lazy no-shadow mb--20"/>
            </section>

            <section className="container pt--0">
                <h3 className="txt--pink mb--40"><strong>In trying to follow the “rules” that would give me the body I had always wanted, <br className="d-none d-xl-block"/>I was instead stuck in a constant cycle of failure… and I couldn’t stop!</strong></h3>

                <div className="container container--sm">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="testimonial__img img-w-xs mx--auto">
                                <img src="/images/bfs/testimonial-sm.jpg" alt="" width="340" height="340" className="lazy no-shadow"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <p><strong>That was – until I combined Science, Food and a System I’ve refined for the past 9 years – and went from a flabby, frustrated “Triet” Slave to the Fit, Confident and Happy person you see now…</strong></p>
                            <p>…and have gone on to share my System with thousands of others who have already used it to get the results they wanted!</p>

                            <img src="/images/bfs/arrow-round.png" alt="" width="153" height="203" className="lazy arrow-img"/>
                        </div>
                    </div>
                </div>
            </section>

            {/*testimonials*/}
            <section className="bg--section pt--70i">
                <div className="container container--md">
                    <div className="row">
                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-7.jpg?v=1.0.1" alt="Testimonial of Dalida I." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I am happy with what I put in my mouth because it shows in my body!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I just wanted to email you to thank you again – I’ve been eating clean and training well and not depriving myself, and I legit see my (six) packs coming in!</p>
                                        <p>And some of my jeans fit!</p>
                                        <p>I have a lot to thank you for.</p>
                                        <p>I make your shakes and your other dishes, and I am happy with what I put in my mouth because it shows in my body.</p>
                                        <p className="txt--pink"><strong>- Dalida I.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-8.jpg?v=1.0.1" alt="Testimonial of Casey D." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"People at work are jealous of the food I get to eat every day."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I documented my first 90 days, and the proof is in the first 2 pictures.</p>
                                        <p>This is all doing the same workout routine, just changing what I put IN my body.</p>
                                        <p>It really is amazing what a difference your diet will make.</p>
                                        <p>I have spent years working out, and only saw minimal gains.</p>
                                        <p>Once I started working with Betty Rocker's system, everything changed.</p>
                                        <p>Not only did I start packing on the muscle, I felt better and more awake all day.</p>
                                        <p>I was able to start removing most of my protein shakes and replace them with real food.</p>
                                        <p>Now how I eat is second nature, and everything about the Body Fuel System makes it not only really healthy, but taste really good also.</p>
                                        <p>People at my work know my diet and are jealous by the food I get to eat every day.</p>
                                        <p>It's amazing how much more progress and results I received when I started following her program.</p>
                                        <p className="txt--pink"><strong>- Casey D.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-9.jpg" alt="Testimonial of Rocio C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Love my new curves!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Before and after 4 weeks of the Betty Rocker’s Body Fuel System!</p>
                                        <p>My husband did it too!</p>
                                        <p className="txt--pink"><strong>- Rocio C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-10.jpg?v=1.0.1" alt="Testimonial of Rocio C's husband." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I am so proud of him for committing to a healthy lifestyle!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>This is my husband – he really adopted the healthy lifestyle with me, and dropped from 17.1-14.3% body fat by lifting and following the Body Fuel System by Betty Rocker!</p>
                                        <p>He is the most amazing man and I adore him at any weight but I am so proud of him for committing to a healthy lifestyle!</p>
                                        <p className="txt--pink"><strong>- Rocio C's husband</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-11.jpg?v=1.0.1" alt="Testimonial of Jasmine F." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"The green smoothies in this program have changed my life!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I had a preconceived notion that if I wanted to lose weight, my food needed to be plain and boring, because if not I was consuming too many calories.</p>
                                        <p>Working with the Body Fuel System taught me that calories aren't the most important thing, instead, my focus should be on getting the proper nutrients that my body needs.</p>
                                        <p>I had more energy and push harder in my vigorous workouts.</p>
                                        <p>The green smoothies in the program have made it possible for me to have clean and healthy "fast food" on the go.</p>
                                        <p>They have changed my life!</p>
                                        <p className="txt--pink"><strong>- Jasmine F.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-12.jpg" alt="Testimonial of Roslyn R." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I couldn't bust the plateau before."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I work out quite a lot, but it wasn’t until I did Betty Rocker’s Body Fuel System that I had changes in my middle area.</p>
                                        <p>Seven months post-baby, and happy with this!</p>
                                        <p className="txt--pink"><strong>- Roslyn R.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="txt--center txt--15 lh--auto"><em>* Individual results may vary and may not be typical or representative of what you might expect. <br className="d-none d-lg-block"/>To learn more about our community’s experiences with our programs look at our Testimonial Support page.</em></p>

                </div>
            </section>

            {/*3 Eating Styles*/}
            <section className="bg--eating-style bg--section arrow-down border-top-10 border-pink pt--0 js-section" id="how-it-works">
                <div className="container">
                    <div className="row gutters-original bg--eating-style-mobile pt--sm-30 pt--50">
                        <div className="col-7">
                            <h2 className="h2--sm txt--pink txt--left mb--40">Enter the 3 Eating Styles of <br className="d-none d-lg-block"/> the Body Fuel System™…</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <p><strong>The key to sustainable health lies in the 3 Eating Types I created that give us strategies to use for any situation we’re in.</strong></p>
                            <p>When you combine the knowledge about <strong>how to combine the right foods with the eating style strategies,</strong> you can navigate your healthy eating with ease, get the results you’re after, have amazing energy, and feel great about your food choices.</p>
                            <p><strong>These 3 eating styles are the essence of the Body Fuel System.</strong></p>
                            <p>In your meal plans, you’ll find that each week has a special section for your Eating Type, and is labeled depending on what kind of a week you’re having. You can use each week 3 different ways, leaving you with <strong>multiple options and a great foundation for a healthy, flexible lifestyle that doesn’t lock you into a rigid system that’s hard to stick to.</strong></p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container arrow-down">
                <h2 className="h2--sm mb--10">So Which Kind of Eater Are You?</h2>
                <p className="txt--center mb--40">(most people identify strongly with one of the eating types, but find they could be all 3 at different times)</p>

                <div className="row gutters-responsive">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 mb--15">
                        <div className="card card--padding card--height bg--minimal">
                            <h2 className="txt--uppercase txt--white txt--left mb--20">Minimal</h2>
                            <p className="txt--white">You need <strong>SHORTCUTS</strong> to save you time and EATING MADE EASY that gets results - even if you are super busy, don’t cook much or are traveling.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 mb--15">
                        <div className="card card--padding card--height bg--black">
                            <h2 className="txt--uppercase txt--white txt--left mb--20">Hybrid</h2>
                            <p className="txt--white">You need a <strong>FLEXIBLE</strong>, easy-to-use guide that helps you stay healthy and get results on a busy schedule. You can do some light prep, but have the flexibility to take the healthy shortcuts in the guide.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 mb--15">
                        <div className="card card--padding card--height bg--batch">
                            <h2 className="txt--uppercase txt--white txt--left mb--20">Batch</h2>
                            <p className="txt--white">You need an <strong>EFFICIENT</strong>, simple map laid out to follow so you can conveniently prep your food for the week in one main session and save time all week.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="bg--section">
                <div className="container container--sm">
                    <h2 className="h2--sm mb--20">The Body Fuel System™ uses my simple, proven Eating Types to make it easy to stay on track</h2>
                    <h3 className="font-lato">It also gives you healthy, fat-burning recipes and foods <br className="d-none d-lg-block"/> you can eat to look and feel your BEST every day…</h3>
                </div>
            </section>

            <section className="bg--pink arrow-down arrow-down--pink py--sm-30 py--40">
                <h2 className="h2--sm txt--white">Recipes Like…</h2>
            </section>

            <section className="bg--section arrow-down">
                <div className="container pt--30">
                    <div className="row gutters-responsive">
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-1.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>5-minute Protein Pancakes <span className="fw--regular">(Week 1)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-2.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Tofu Taco Lettuce Cups <span className="fw--regular">(Week 1)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-3.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Sauteed Shrimp <span className="d-block fw--regular">(Week 2)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-4.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Asian Lettuce Wraps <span className="fw--regular">(Week 2)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-5.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Breakfast Egg Muffins  <span className="fw--regular">(Week 3)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-6.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Chicken Salad in a Jar <span className="fw--regular">(Week 3)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-7.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Captain America Green Smoothie <span className="fw--regular">(Week 4)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-8.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Pesto Shrimp Bowl <span className="d-inline-block fw--regular">(Week 4)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-9.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Ginger Pineapple Green Smoothie <span className="fw--regular">(Week 5)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-10.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Turkey Burgers <span className="d-block fw--regular">(Week 5)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-11.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Blueberry Protein Muffins <span className="fw--regular">(Week 6)</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
                            <div className="img-card">
                                <img className="shadow-0 lazy" src="/images/bfs/recipe-12.jpg" alt="" width="270" height="270"/>
                                <div className="img-card__title img-card__title--lg">
                                    <span>Sweet Potato Breakfast Bowl <span className="fw--regular">(Week 6)</span></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 txt--center">
                        <img src="/images/bfs/week-3-plan.png" alt="" width="570" height="534" className="lazy no-shadow mb--20"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <h2 className="h2--sm txt--left txt--pink mb--40">Body Fuel System™ Eating Guide</h2>
                        <p>This is your essential and easy to read guide to nutrition. You’ll learn how to navigate eating in all situations, and become a fit, healthy, confident eater!</p>
                        <p><strong>This guide will empower you to make healthy eating your LIFESTYLE, once and for all!</strong></p>
                    </div>
                </div>
            </section>

            <section className="bg--pink arrow-down arrow-down--pink py--sm-30 py--40">
                <h2 className="h2--sm txt--white">You'll Learn…</h2>
            </section>

            <section className="container container--md arrow-down">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <ul>
                            <li>How to customize the System for your life</li>
                            <li>How to get lean and fit without counting calories or exercising every single day</li>
                            <li>The BEST beverages to drink</li>
                            <li>The SECRETS behind common belly inflammations that lead to hidden weight gain, and how to keep your body safe!</li>
                            <li>How to kick your metabolism into high gear - even if it has seemed sluggish in the past</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6">
                        <ul>
                            <li>How to eat when you’re traveling and order while dining out so you don’t get off track</li>
                            <li>How to deal with food cravings</li>
                            <li>How to SAVE MONEY at the grocery store so you can have more to spend on the new clothes you’ll be buying</li>
                            <li>How to DETOX your system so you can enjoy clearer skin, better energy and more efficient fat burning!</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="bg--section pb--0">
                <div className="container txt--center">
                    <h2 className="h2--sm mb--40">Your Body Fuel System Meal Plans</h2>
                    <img src="/images/bfs/book-meals.png" alt="" width="1260" height="309" className="lazy no-shadow"/>
                </div>
            </section>

            {/*what is included*/}
            <section className="bg--section arrow-down js-section" id="what-is-included">
                <div className="container container--md">
                    <h2 className="h2--sm mb--40">Here’s Whats Included:</h2>
                    <div className="card card--tag-sm mb--30 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--pink tag--sm">#1</div>
                            <h4><strong>3 Eating Style Guides</strong> (minimal, hybrid and batch) <strong>for each week</strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row align-items-center">
                                <div className="col-md-6 mb--sm-30 txt--center">
                                    <img className="no-shadow lazy" src="/images/bfs/included-1.png" alt="" width="414" height="395"/>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li><strong>Eat healthy no matter your schedule or interest in cooking</strong> with our Eating Type System.</li>
                                        <li>Use <strong>Minimal</strong> if you're new to cooking, super busy, super short on time, traveling or don’t want to cook much</li>
                                        <li>Use <strong>Hybrid</strong> if you like to cook but short on time, like to have a few things cooked but enjoy the flexibility of cooking on the fly</li>
                                        <li>Use <strong>Batch</strong> if you’re comfortable cooking and want to save time by prepping your food in bulk, ready to grab and go!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card--tag-sm mb--30 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--pink tag--sm">#2</div>
                            <h4><strong>Weekly Done-For You Grocery Lists (by Eating Type)</strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row align-items-center">
                                <div className="col-md-6 txt--center mb--sm-30">
                                    <img className="no-shadow lazy" src="/images/bfs/included-2.png" alt="" width="397" height="384"/>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li>You’ll have <strong>6 Complete Grocery Lists</strong> (customized for Minimal, Hybrid and Batch) so you can save time AND money every week.</li>
                                        <li>Each list corresponds directly with the Recipes, Daily Menus and BONUS Guides to <strong>take ALL the hard work out of planning your week</strong> – no matter how much cooking you’re doing.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card--tag-sm mb--30 mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--pink tag--sm">#3</div>
                            <h4><strong>Done-For You Weekly Menus</strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row">
                                <div className="col-md-6 txt--center mb--sm-30">
                                    <img className="no-shadow lazy" src="/images/bfs/included-3.png" alt="" width="422" height="383"/>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li><strong>You’ll get 6 Weeks of Done-For You Daily Menus so you have an ACTION PLAN for each day of the week.</strong> This guide can help you make good choices even when you travel or eat out if you stick close to the suggested guidelines.</li>
                                        <li><strong>Customize it to YOUR schedule,</strong> it has room to take days off or accommodate the unexpected. Take ALL the confusion and frustration out of wondering what to eat and when so you can spend more time enjoying your life!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card--tag-sm mb--xs-20">
                        <div className="card__head card__head--gray">
                            <div className="tag tag--pink tag--sm">#4</div>
                            <h4><strong>Easy, Healthy Recipes</strong></h4>
                        </div>
                        <div className="card__content">
                            <div className="row align-items-center">
                                <div className="col-md-6 txt--center mb--sm-30">
                                    <img className="no-shadow lazy" src="/images/bfs/included-4.png" alt="" width="485" height="479"/>
                                </div>
                                <div className="col-md-6">
                                    <p>You’ll get 6 weeks of Delicious, Easy to Prepare Fat-Burning Recipes that are:</p>

                                    <ul>
                                        <li><strong>Gluten Free</strong></li>
                                        <li><strong>Dairy Free</strong></li>
                                        <li><strong>Nutrient Balanced</strong></li>
                                        <li><strong>Low Glycemic Index</strong></li>
                                        <li><strong>Include Vegetarian Options</strong></li>
                                    </ul>

                                    <p>Included follow-along recipes AND complete Weekly Food Prep Guide for speed and efficiency.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="container p--relative pb--0">
                <h2 className="h2--sm mb--40">WARNING: <span className="txt--pink">THIS System IS NOT for Everyone!</span></h2>

                <div className="row">
                    <div className="col-12 col-lg-11 mb--30">
                        <h3 className="txt--left mb--20">Who is this for?</h3>
                        <p>Well first, let me tell you who this ISN’T for.</p>
                        <p>If you’re trying to “bulk up” this isn’t the plan for you. This also isn’t the plan for you if you’re trying to lose 20-30 lbs in a week. People who use this system have easily lost that amount (and more), but they don’t do it overnight, or in a week.</p>

                        <h3 className="txt--left mb--20">Will this program work for you?</h3>
                        <p>You tell me, will you work for IT? Are you willing to eat real foods, prepare simple meals, either one at a time or in batches? Are you willing to read a few chapters about Nutrition so you can give your body the actual sustenance it craves to do its functions?</p>
                        <p><strong>Because if you can do these things, this will 1000% work for you.</strong></p>
                    </div>
                    <div className="col-12 col-lg-7">

                        <h3 className="txt--left mb--20">How quickly will you see RESULTS? </h3>
                        <p>Many of my readers send me a letter during their first week telling me they’re sleeping better, their bellies are shrinking, they have lost their cravings and so many more wonderful effects.</p>
                        <p>But the biggest reason this works is because there is NO ONE SIZE FITS ALL approach to YOUR unique body. This is a blueprint to understanding the language your body speaks so you can move the needle in the direction you want it to go. It’s on its own schedule. Following this program will create change – it’s up to you how fast that happens.</p>

                        <h3 className="txt--left mb--20">This is about creating a LIFESTYLE</h3>
                        <p className="mb--50">A lifestyle built on harnessing the abundance of nutrients that CAN and WILL go to work for you to keep your body a lean, mean machine all day long, every time you eat them.</p>
                    </div>
                    <div className="col-12 col-lg-5 txt--center">
                        <img src="/images/bfs/betty-back.png" alt="" width="580" height="614" className="lazy no-shadow img--bottom-abs img--down"/>
                    </div>
                </div>

            </section>

            {/*testimonials*/}
            <section className="bg--section arrow-down">
                <div className="container container--md">

                    <h2 className="h2--sm mb--40">Take a Look at Some of These <span className="txt--pink">Inspiring Messages</span> From my Thriving Fitfam Community That You Will be a Part of…</h2>

                    <div className="row">
                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-13.jpg?v=1.0.1" alt="Testimonial of Rebecca C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I lost 6lbs in the first week!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I needed to let you know that you have changed my life.</p>
                                        <p>I never thought that a “plan” would be something I would stick to or want to stick to because they are all so BORING.</p>
                                        <p>However, yours rocks.</p>
                                        <p>My way of thinking about food now is to fuel my body and not to starve it.</p>
                                        <p>Your recipes are great and have helped make me and my husband be healthier. I lost 6 lbs in the first week!</p>
                                        <p className="txt--pink"><strong>- Rebecca C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-14.jpg?v=1.0.1" alt="Testimonial of John W." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">“Let me tell you how I am feeling lately: Energetic, stronger and even smarter…”</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>My newest, smaller-sized bike shorts are almost looking baggie on my skinnier ass!</p>
                                        <p>Not sure of what to make of that but I guess I’ll take it…</p>
                                        <p>Also I am getting a lot more respect at work and seem to be getting more than just sweet little old ladies saying hi to me at the grocery store, among other places!</p>
                                        <p>The fat is melting off my body but I don’t seem to be losing weight, just gaining a new shape or rather uncovering an old friend.</p>
                                        <p>I just said if I am halfway there, the rest will be fantastic, because I never dreamt I would ever feel as good as I do now.</p>
                                        <p>Thank you very much!</p>
                                        <p className="txt--pink"><strong>- John W.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-15.jpg?v=1.0.1" alt="Testimonial of Ashley G." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I stopped craving sugar all the time."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>The main difference I noticed immediately after starting the Body Fuel System was that I stopped craving sugar all the time.</p>
                                        <p>I still would feel a craving every now and then (especially during emotional moments) but because my body was finally adequately fueled with complex carbs and proteins and veggies on a regular basis I didn't feel that desperation for sugar like I used to.</p>
                                        <p>I read the Body Fuel System from cover to cover and what I appreciated most was not just the incredible amount of information and advice, but the positive message of self-love and acceptance that Betty Rocker was spreading.</p>
                                        <p className="txt--pink"><strong>- Ashley G.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-16.jpg?v=1.0.1" alt="Testimonial of Tekoah C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I overcame my wine craving."</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Week 4 of the Body Fuel System and I feel AMAZING!</p>
                                        <p>I definitely recommend the Body Fuel System, my body feels alive!</p>
                                        <p>Before I started I would have a glass of wine at night not thinking it was a big deal</p>
                                        <p>I worked out, I ate healthy, I CRAVED wine at night.</p>
                                        <p>But now, doing the BFS I realized I wasn't craving, I was lacking.</p>
                                        <p>Now I have lost that crave for wine at night and I can enjoy it when I choose to, and that's a big deal for me.</p>
                                        <p>Thank you Betty Rocker!</p>
                                        <p className="txt--pink"><strong>- Tekoah C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-17.jpg?v=1.0.1" alt="Testimonial of Veronica M." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"A new, healthier me!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Still a work in progress, but I’ve been following the Body Fuel System and the workouts.</p>
                                        <p>Thank you for your help, to a new healthier me.</p>
                                        <p>The next phase is all up to me. I got this!!</p>
                                        <p className="txt--pink"><strong>- Veronica M.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/bfs/testimonial-18.jpg?v=1.0.1" alt="Testimonial of Connie K." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"My doctor says my heart disease risk is now way below average!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Blood work is excellent.</p>
                                        <p>All the bad stuff went down and the good stuff went up.</p>
                                        <p>Heart disease risk way below average!</p>
                                        <p>Thank you Betty Rocker for the healthy recipes…</p>
                                        <p>Who would a thought quinoa could taste so good?</p>
                                        <p className="txt--pink"><strong>- Connie K.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="txt--center txt--15 lh--auto"><em>* Individual results may vary and may not be typical or representative of what you might expect. <br className="d-none d-lg-block"/>To learn more about our community’s experiences with our programs look at our Testimonial Support page.</em></p>

                </div>
            </section>

            {/*bonuses*/}
            <section className="container arrow-down">

                <h2 className="h2--sm mb--40"><span className="txt--pink">SPECIAL BONUS GIFTS</span> <br/> When You Start Today!</h2>

                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
                        <div className="card card--height card--border">
                            <div className="tag tag--pink tag--xs">#1</div>
                            <div className="card__content">
                                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                                    <img src="/images/bfs/bonus-1.png" alt="" width="199" height="236" className="lazy no-shadow mb--20"/>
                                </div>
                                <h4 className="fw--medium mb--20">Body Fuel System Cookbook and Recipes Guide</h4>
                                <p>All the delicious Body Fuel System recipes at your fingertips in this amazing cookbook!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
                        <div className="card card--height card--border">
                            <div className="tag tag--pink tag--xs">#2</div>
                            <div className="card__content">
                                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                                    <img src="/images/bfs/bonus-2.png" alt="" width="274" height="262" className="lazy no-shadow mb--20"/>
                                </div>
                                <h4 className="fw--medium mb--20">6-Week E-coaching Course</h4>
                                <p>This companion coaching course will help you start the program with ease, and navigate the program successfully.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
                        <div className="card card--height card--border">
                            <div className="tag tag--pink tag--xs">#3</div>
                            <div className="card__content">
                                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                                    <img src="/images/bfs/bonus-3.png" alt="" width="200" height="234" className="lazy no-shadow mb--20"/>
                                </div>
                                <h4 className="fw--medium mb--20">Healthy Travel and Eating Out Survival Guide</h4>
                                <p>An amazing reference that shares my strategies for successfully navigating traveling and dining out.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
                        <div className="card card--height card--border">
                            <div className="tag tag--pink tag--xs">#4</div>
                            <div className="card__content">
                                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                                    <img src="/images/bfs/bonus-4.png" alt="" width="200" height="238" className="lazy no-shadow mb--20"/>
                                </div>
                                <h4 className="fw--medium mb--20">Eat This Not That Guide</h4>
                                <p>Make better choices from wholesome sources, and avoid foods that seem healthy but actually aren’t with this handy quick reference guide.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
                        <div className="card card--height card--border">
                            <div className="tag tag--pink tag--xs">#5</div>
                            <div className="card__content">
                                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                                    <img src="/images/bfs/bonus-5.png" alt="" width="194" height="240" className="lazy no-shadow mb--20"/>
                                </div>
                                <h4 className="fw--medium mb--20">Resources and Healthy Shortcut Foods Guide</h4>
                                <p>Save time, save money and take easy shortcuts with this helpful guide! This bonus guide will help you use the program with success - even when you are busy and need more flexible options.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
                        <div className="card card--height card--border">
                            <div className="tag tag--pink tag--xs">#6</div>
                            <div className="card__content">
                                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                                    <img src="/images/bfs/bonus-6.png" alt="" width="192" height="232" className="lazy no-shadow mb--20"/>
                                </div>
                                <h4 className="fw--medium mb--20">Food Swaps and Substitutions Guide</h4>
                                <p>Help making your meal plans and recipes flexible. Need to swap a food out? This handy guide will help you!</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="p--0 p--relative overflow--hidden">
                <div className="bg--section py--50">
                    <div className="container">
                        <h2 className="h2--sm mb--40">You deserve to feel your best</h2>

                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <p>You deserve to know the REAL DEAL when it comes to the food you put in your mouth – in your KIDS’ mouths – in your family’s mouths, every day.</p>
                                <p>Everything I use PERSONALLY and teach my clients is inside this program, and I want you to have it.</p>
                                <p><strong>How your body looks and feels is a byproduct of how you treat yourself. If you don’t have the best information, how can you make the best choices?</strong></p>
                                <p>I am a very direct person, so I’ll be cutting through all the junk to tell you EXACTLY what you need to know about how to combine the foods that trigger your body’s own natural fat loss system, shore up your energy, bust cravings, WHEN to eat the right foods, how to be super efficient with planning and prepping – everything you need to EASILY upgrade your lifestyle to the one you deserve – FOR GOOD.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg--pink arrow-down arrow-down--pink txt--white py--30">
                    <div className="container">
                        <h2 className="h2--sm oversection-title mb--10 txt--left txt--white">Now It’s Time To Take Control.</h2>
                        <div className="row">
                            <div className="col-8 col-sm-9 col-md-10 col-lg-8">
                                <h3 className="txt--left txt--white"><strong>Join me, and the amazing community of people who are </strong><span className="d-inline d-xl-block mt--10">Getting Fit, Feeling Great, Looking Great, and Loving Life!</span></h3>
                            </div>
                        </div>
                    </div>
                </div>

                <img src="/images/bfs/betty-full.png" alt="" width="386" height="721" className="lazy no-shadow img--oversection"/>
            </section>

            <section className="container" id="order-now">
                <h2 className="h2--sm mb--10">Here’s How to Get Started!</h2>

                <img src="/images/bfs/bfs-full.png" alt="" width="1300" height="664" className="lazy no-shadow mb--40"/>

                <div className="container container--md txt--center">
                    <h3 className="mb--30"><strong>So I added up the cost of all of the components of the Body Fuel System, which came to a total of $297 including the bonuses.</strong></h3>

                    <p>I didn’t write this program so it could sit on a shelf collecting dust. I wrote it so busy people like YOU could quickly and easily get their hands on the EXACT information you need to take control of your life and start actually enjoying food, loving your body and rocking some serious fitness all over your life!</p>
                    <p className="mb--40">So <strong>I’ve knocked the price down over 70% off its total value</strong> to empower you to spend your money on what we need to make this work – the FOOD! Invest this small amount today in the Body Fuel System and arm yourself with the most valuable part of making this work, the knowledge to eat right for your LIFE!</p>

                    <DigitalProductBuyBox
                        product={product}
                        singleBox={true}
                    />

                    <img className="shadow-0 d-block mx--auto mb--30 lazy" src="/images/credit-cards.png" alt="" width="300" height="39"/>

                        <p className="txt--14 txt--xs-16 mb--10 mb--xs-20"><strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong></p>
                        <p className="txt--14 txt--xs-16"><em>You’ll receive INSTANT ACCESS to the Body Fuel System and Bonuses Immediately <br className="d-none d-md-block"/>After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.</em></p>
                </div>

                {/*guarantee*/}
                <div className="container pt--30">
                    <div className="box-guarantee">
                        <img src="/images/guarantee.png" alt="" width="239" height="245" className="lazy no-shadow"/>
                        <div className="row">
                            <div className="col-lg-8">
                                <h2 className="h2--sm txt--white mb--20 txt--smd-left">100% Money Back GUARANTEE</h2>

                                <p className="txt--white lh--auto">I know that this information and this System will have an incredibly positive impact on your life, because not only do I live it, I see it, and I read about it from the people who use it over and over.</p>
                                <p className="txt--white lh--auto">But I know you have already been on a journey getting here, and I trust that you know what’s best for yourself. At the end of the day, if this isn’t the right fit for you, or doesn’t create change in your life, don’t sweat it. You can return it within 60 days and get your money back, no questions asked.</p>

                                <p className="txt--white txt--22 fw--black"><span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="bg--section js-section py--50 pb--xs-30" id="faq">
                <div className="container">
                    <h2 className="mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                </div>
                <div className="container container--xs">

                    <Faqs productName='bfs'/>

                </div>
            </section>
        </>
    )
}
export default BodyFuelSystem
