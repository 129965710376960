import React from "react"
import {Helmet} from "react-helmet";
import {scrollTo} from "../../utilities/scrollTo";

import Faqs from "../../components/Faqs";
import ScrollToTabs from "../ScrollToTabs";
import DigitalProductBuyBox from "../DigitalProductBuyBox";

const HomeWorkoutDomination = ({product}) => {
    return (
        <>

            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Home Workout Domination - #stopdropandbettyrock"/>
                <meta property="og:description" content="This 8 Week Workout Plan is perfect if you’re looking for a versatile, time-saving workout program to do at home, at the gym, or on the road to tone and tighten your entire body, and sculpt your legs, butt, abs and arms!"/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/hwd-fb.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Home Workout Domination - #stopdropandbettyrock." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Home Workout Domination - #stopdropandbettyrock"/>
                <meta name="twitter:description" content="This 8 Week Workout Plan is perfect if you’re looking for a versatile, time-saving workout program to do at home, at the gym, or on the road to tone and tighten your entire body, and sculpt your legs, butt, abs and arms!"/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/hwd-fb.jpg"/>
                <meta name="twitter:image:alt" content="Image of Home Workout Domination - #stopdropandbettyrock." />
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            {/*sales hero*/}
            <section className="hero bg--section pt--xs-30 bg--hero-hwd">
                <div className="bg--hero-hwd-mob d-flex flex-column justify-content-center align-items-start d-lg-none">
                    <h1 className="txt--left h1--sm mb--10">Home Workout Domination</h1>
                    <h2 className="txt--left h2--sm txt--purple-light line-brush line-brush--purple-light mb--60">#stopdropandbettyrock</h2>
                </div>

                <div className="container">
                    <div className="container container--md">
                        <div className="row">
                            <div className="col-12 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
                                <h1 className="d-none d-lg-block txt--left h1--sm mb--10">Home Workout Domination</h1>
                                <h2 className="d-none d-lg-block txt--left h2--sm txt--purple-light line-brush line-brush--purple-light mb--60">#stopdropandbettyrock</h2>
                                <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--10">
                                    <iframe title="Hero Video." src="https://player.vimeo.com/video/263242981"/>
                                </div>
                                <p className="fw--black txt--center txt--purple-light txt--25 txt--xs-20 txt--xxs-18">Get in the Best Shape of Your Life <span className="d-inline-block">in 8 Weeks… From Home!</span></p>

                                <div className="txt--center md--down">
                                    <a className="btn btn--md fw--medium" onClick={scrollTo} href="#order-now">start now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ScrollToTabs supplements={false}/>

            {/*how it works*/}
            <section className="container arrow-down js-section mt--md-n20" id="how-it-works">
                <div className="container">
                    <h2 className="h2--sm txt--center mb--30">Here's How <span className="txt--purple-light">It Works:</span></h2>
                    <h4 className="txt--center mb--40">Time saving home workout plan to tone and tighten your <br className="d-none d-md-block"/>entire body, and sculpt your legs, butt, abs and arms</h4>

                    <div className="row">
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 item-md-border">
                            <div className="txt--center-md">
                                <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                    <img src="/images/hwd/works-1.png" alt="" className="no-shadow lazy" width="332" height="332"/>
                                </div>
                            </div>
                            <h3 className="txt--md-left txt--purple-light mb--20 ml--50 ml--md-0"><strong>TORCH BODY FAT</strong></h3>
                            <ul className="checkmark-purple-light list--padding">
                                <li><strong>Maximize fat burn</strong> with workouts that trigger the afterburn effect, keeping your body in a fat-burning state for up to 38 hours post workout</li>
                                <li><strong>Keep your body</strong> guessing with workouts that train your entire body with both strength and plyometrics for the ultimate conditioning workout</li>
                                <li><strong>Transform your body</strong> burning more calories at rest with workouts that add lean muscle and decrease over all body fat</li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 item-md-border">
                            <div className="txt--center-md">
                                <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                    <img src="/images/hwd/works-2.jpg" alt="" className="no-shadow lazy" width="332" height="332"/>
                                </div>
                            </div>
                            <h3 className="txt--md-left txt--purple-light mb--20 ml--50 ml--md-0"><strong>SAVE TIME+MONEY</strong></h3>
                            <ul className="checkmark-purple-light list--padding">
                                <li><strong>Do your workout anywhere</strong> – minimal equipment needed, so it’s great for travel, home workouts and in the gym</li>
                                <li><strong>Time saving plan</strong> with superset sequences to maximize the time you have</li>
                                <li><strong>Saves you money</strong> – you can use this plan to get fit anywhere without spending money on a gym or a trainer!</li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-40">
                            <div className="txt--center-md">
                                <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                                    <img src="/images/hwd/works-3.png" alt="" className="no-shadow lazy" width="332" height="332"/>
                                </div>
                            </div>
                            <h3 className="txt--md-left txt--purple-light mb--20 ml--50 ml--md-0"><strong>TONE AND STRENGTHEN</strong></h3>
                            <ul className="checkmark-purple-light list--padding">
                                <li><strong>Adding resistance will sculpt more muscle, more quickly,</strong> and give your body a sculpted shape and lean lines</li>
                                <li><strong>Fast, fun and effective</strong> workouts will challenge different muscle groups with each new training session</li>
                                <li><strong>Get a faster response</strong> with a variety of isolation strength movements and high intensity interval training</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>

            {/*testimonials*/}
            <section className="arrow-down bg--section js-section" id="results">
                <div className="container">
                    <h2 className="h2--sm mb--40">Betty Rocker <span className="d-inline-block txt--purple-light">Program Results:</span></h2>
                </div>
                <div className="container container--md">
                    <div className="row">
                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-1.jpg?v=1.0.1" alt="Testimonial of Liz Beth." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I feel so strong and healthy!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Day 1 and day 30. Excited for my progress. I feel so strong and healthy! Who flipping cares about skinny.</p>
                                        <p>Fit looks better naked!</p>
                                        <p className="txt--pink"><strong>- Liz Beth</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-2.jpg" alt="Testimonial of Theresa V." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Same weight, less inches!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>16 weeks of Home Workout Domination (I did it twice). SAME WEIGHT – less inches! I’m moving on to Lioness, and I can’t wait to see my results!</p>
                                        <p className="txt--pink"><strong>- Theresa V.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-3.jpg" alt="Testimonial of Jamie B." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I’m down 10 pounds"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I’m down 10 pounds. And I need to lose the weight but more importantly, I NEED to be healthy! A complete diet transformation and this program helped jumpstart my success!</p>
                                        <p className="txt--pink"><strong>- Jamie B.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-4.jpg" alt="Testimonial of Amy M." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I feel great and healthy and strong!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Really pleased with my progress! I feel great and healthy and strong!</p>
                                        <p className="txt--pink"><strong>- Amy M.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-5.jpg?v=1.0.1" alt="Testimonial of Miranda N." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"It’s not about weight it’s about how your body changes"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Doing resistance training and cardio combined and I have not lost one single ounce on the scale….I was feeling horribly frustrated being it has been a month so I decided to do a progress sequence and wth look at me now!! It’s not about weight, it’s about how your body changes when you eat right and exercise! Yay!!</p>
                                        <p className="txt--pink"><strong>- Miranda N.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-6.jpg?v=1.0.1" alt="Testimonial of Jennifer H." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I’ve hit the winning combination with HWD!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>3/4 of the way through HWD – I’ve lost 7.2 lbs and 8.25 inches. I’m quite happy with these results! I’ve been working out and eating well for a long time, but nothing had changed – I think I’ve hit the winning combination with Home Workout Domination!</p>
                                        <p className="txt--pink"><strong>- Jennifer H.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="txt--center txt--15 lh--auto"><em>* Individual results may vary and may not be typical or representative of what you might expect. <br className="d-none d-lg-block"/>To learn more about our community’s experiences with our programs look at our Testimonial Support page.</em></p>

                </div>
            </section>

            <section className="bg--dark-hwd js-section" id="what-is-included">
                <div className="container">
                    <h2 className="txt--white h2--sm mb--60 mb--sm-40 mt--10">Your Complete Home Workout Domination Program Includes…</h2>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center">
                            <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--md-20">
                                <iframe title="Video." src="https://player.vimeo.com/video/229374313"/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>Follow along workout videos</strong></h4>
                            <ul className="checkmark-purple-light pb--20 txt--white">
                                <li className="txt--white">Sculpt, tone, strengthen and #makefatcry with this home workout program that will get you results head to toe!</li>
                                <li className="txt--white">Workout with me! I’ll talk you through each workout with a full follow along video so we can do it together (great for ALL fitness levels).</li>
                                <li className="txt--white">Demo video for each workout so you can learn form, alignment and options to do it with equipment or bodyweight!</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center">
                            <img src="/images/hwd/hwd-2.png" alt="" width="547" height="181" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>Full body workouts</strong></h4>
                            <ul className="checkmark-purple-light">
                                <li className="txt--white">Demos for strengthening and toning your butt, legs, arms and abs to shed fat and sculpt a lean, fit physique.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center">
                            <img src="/images/hwd/hwd-3.png" alt="" width="547" height="178" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>Lower Body Workouts</strong></h4>
                            <ul className="checkmark-purple-light">
                                <li className="txt--white">Build and shape your booty, strengthen and define strong, shapely legs.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center">
                            <img src="/images/hwd/hwd-4.png" alt="" width="547" height="187" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>Upper Body and Abs Workouts</strong></h4>
                            <ul className="checkmark-purple-light">
                                <li className="txt--white">Sculpt and tone your abs, shoulders, arms and back! Little black dress, here you come!</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center row--border">
                        <div className="col-12 col-lg-6 txt--center">
                            <img src="/images/hwd/hwd-5.png" alt="" width="547" height="165" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>HIIT</strong></h4>
                            <ul className="checkmark-purple-light">
                                <li className="txt--white">High Intensity Interval Training workouts to burn extra fat and increase total body strength!</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center mb--50">
                        <div className="col-12 col-lg-6 txt--center">
                            <img src="/images/hwd/hwd-6.png" alt="" width="606" height="179" className="lazy no-shadow"/>
                        </div>
                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
                            <h4 className="txt--uppercase txt--center-md mb--20 txt--white"><strong>Yoga Workouts</strong></h4>
                            <ul className="checkmark-purple-light">
                                <li className="txt--white">Stretch and sculpt with this flexibility and toning series to add in once a week for improved circulation, posture, form and alignment.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/*bonuses*/}
            <section className="container container--md">
                <h2 className="h2--sm mb--40"><span className="txt--purple-light">Special Bonus</span> Gifts To Help You Get Even More</h2>
                <div className="card card--tag-sm mb--30 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--purple-light"><span className="d-none d-md-inline-block">Bonus</span> #1</div>
                        <h4><strong>Healthy Eating Jumpstart Guide</strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb--sm-30 txt--center">
                                <img className="no-shadow lazy" src="/images/hwd/bonus-1.png" alt="" width="347" height="279"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-purple-light">
                                    <li>Get a jump on <strong>healthy eating</strong> and speed up your <strong>results</strong> with this jumpstart guide</li>
                                    <li>Sample <strong>7 day</strong> meal plan</li>
                                    <li><strong>20 healthy recipes</strong> for breakfast, lunch, dinner and snacks!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card--tag-sm mb--30 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--purple-light"><span className="d-none d-md-inline-block">Bonus</span> #2</div>
                        <h4><strong>Workout Calendars</strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row align-items-center">
                            <div className="col-md-6 txt--center mb--sm-30">
                                <img className="no-shadow lazy" src="/images/hwd/bonus-2.png" alt="" width="447" height="229"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-purple-light">
                                    <li>Workout calendars to help you stay on track with your plan</li>
                                    <li>Short week calendar to help you stay consistent with your progress when life gets extra busy (also great for beginners)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card--tag-sm mb--30 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--purple-light"><span className="d-none d-md-inline-block">Bonus</span> #3</div>
                        <h4><strong>60 Day Coaching and Accountability E-course</strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row">
                            <div className="col-md-6 txt--center mb--sm-30">
                                <img className="no-shadow lazy" src="/images/hwd/bonus-3.png" alt="" width="367" height="231"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-purple-light">
                                    <li>Personal emails from your coach (me) to <strong>encourage and support</strong> you on your fitness journey!</li>
                                    <li>Motivating content each week covering <strong>nutrition, fitness and mindset</strong> to help you stay on track with your plan</li>
                                    <li><strong>Be supported and inspired</strong> as you work your way to great results</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card--tag-sm mb--40 mb--xs-20">
                    <div className="card__head card__head--gray">
                        <div className="tag tag--purple-light"><span className="d-none d-md-inline-block">Bonus</span> #4</div>
                        <h4><strong>Workout Trackers</strong></h4>
                    </div>
                    <div className="card__content">
                        <div className="row align-items-center">
                            <div className="col-md-6 txt--center mb--sm-30">
                                <img className="no-shadow lazy" src="/images/hwd/bonus-4.png" alt="" width="342" height="263"/>
                            </div>
                            <div className="col-md-6">
                                <ul className="checkmark-purple-light">
                                    <li><strong>Printable workout tracker</strong> to take with you on the go</li>
                                    <li>Conveniently <strong>track your progress</strong> as you workout to see how your strength increases</li>
                                    <li><strong>Motivate yourself</strong> by looking back and see how far you’ve come!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/*meet your coach*/}
            <section className="arrow-down bg--section bg--hwd-coach js-section" id="meet-your-coach">
                {/*    if changing heading here, change it on both locations desktop and responsive*/}
                <div className="bg--hwd-coach-mob mb--20 d-flex align-items-center d-lg-none">
                    <h2 className="h2--sm">Meet Your <span className="txt--purple-light">Trainer!</span></h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <h2 className="d-none d-lg-block h2--sm mb--30 txt--left">Meet Your <span className="txt--purple-light">Trainer!</span></h2>

                            <p className="fw--black"><span className="txt--purple-light">Who is Betty Rocker?</span> Betty Rocker is me! My name is Bree Argetsinger, but I’ve been called “punk rock Betty Crocker” since I was a little girl.</p>
                            <p>I’m a health coach and fitness motivator, and I work with people all over the world, helping them transform from the inside out.</p>
                            <p>My passion for fitness and health developed after treating my body very badly for many years and choosing to educate myself and do the work to develop good practices based on science, my own intuition and a deep love and compassion for other people who were struggling as well.</p>
                            <p>While working as a structural integration therapist with extreme sports athletes and racers (which I did for 8 years!), my clients started asking me for nutrition plans that were like “what I was using.” When I saw how much more quickly they got results following my combined nutrition and workout plans, I transitioned to the work I do now and began teaching this impactful information online so I could reach as many people as possible.</p>
                            <p>I have mastered many aspects of health and personal development, including becoming a C.H.E.K. (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A. certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner (realignment of the body through manual therapy).</p>
                            <p>I’ve written multiple cookbooks, and nutrition and fitness plans that have been successfully used by thousands of people. My primary focus is on empowering you with the skills and knowledge you need to make the best choices for yourself – so you can achieve the result you’re looking for while enjoying yourself along the way.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/*this is for you*/}
            <section className="arrow-down bg--hwd-for-you">
                {/*    if changing heading here, change it on both locations desktop and responsive*/}
                <div className="bg--hwd-for-you-mob mb--20 d-flex align-items-center d-lg-none">
                    <h2 className="h2--sm txt--left mb--40">This Program <span className="d-inline-block txt--purple-light">Is For You If:</span></h2>
                </div>
                <div className="container">
                    <div className="row py--20">
                        <div className="col-12 col-lg-7 offset-lg-5">
                            <h2 className="d-none d-lg-block h2--sm txt--left mb--40">This Program <span className="txt--purple-light">Is For You If:</span></h2>
                            <ul className="checkmark-purple-light">
                                <li>You’d like to <strong>sculpt and tone</strong> your legs, butt, stomach and arms in less time</li>
                                <li>You like using <strong>minimal equipment</strong> and having lots of options</li>
                                <li>You enjoy feeling <strong>confident and strong</strong></li>
                                <li>You’re <strong>committed to your health</strong> and fitness</li>
                                <li>You want a result and you are looking for <strong>the right plan</strong></li>
                                <li>You like having the option of <strong>being able to do your workout from home,</strong> anytime</li>
                                <li>You like having a workout to do that <strong>only takes 20-30 minutes</strong></li>
                                <li>You’re looking to feel better and <strong>have more energy</strong></li>
                                <li>You like <strong>variety</strong> in your program</li>
                                <li>You like a <strong>convenient workout plan</strong> to follow</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/*testimonials*/}
            <section className="bg--section arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--40">Betty Rocker <span className="d-inline-block txt--purple-light">Program Results:</span></h2>
                </div>
                <div className="container container--md">
                    <div className="row">
                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-7.jpg" alt="Testimonial of Paula W." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Tighter and firmer than before!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I told myself I didn’t want to join a gym, but I wanted a new way to exercise and eat healthier. I found you and I learned to eat better and rock out with great new workouts! In the photos, the biggest difference is not measured in pounds, but in the way my skin is tighter, muscles firmer, and my belly button is in a higher place than it was before! I’m happy!</p>
                                        <p className="txt--pink"><strong>- Paula W.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-8.jpg?v=1.0.1" alt="Testimonial of Brenda C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Gained muscle for the first time!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Here’s the first and last day before and after Home Workout Domination….who would have thought I would have gained muscle for the first time in my life at the age of 52!</p>
                                        <p className="txt--pink"><strong>- Brenda C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-9.jpg?v=1.0.1" alt="Testimonial of Jennifer C." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I love my progress!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>Not everyone is trying to lose weight – I wanted to add muscle. I love my progress! Thank you so much!</p>
                                        <p className="txt--pink"><strong>- Jennifer C.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-10.jpg" alt="Testimonial of Lorena M." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"I love HWD!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>I’m proud of how far I’ve come. I’ve tried many programs and haven’t stuck with any of them til I found Betty Rocker. I’m doing HWD and I love it.</p>
                                        <p className="txt--pink"><strong>- Lorena M.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-11.jpg?v=1.0.1" alt="Testimonial of Bonnie G." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"So happy with my progress!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>So happy with my progress. The eating plan and the workouts are amazing! I feel great mentally and physically!</p>
                                        <p className="txt--pink"><strong>- Bonnie G.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb--30 mb--xs-15">
                            <div className="testimonial testimonial--shadow">
                                <div className="testimonial__img">
                                    <img className="lazy" src="/images/hwd/testimonial-12.jpg" alt="Testimonial of Theresa V." width="400" height="400"/>
                                </div>
                                <div className="testimonial__content">
                                    <div className="testimonial__header js-testimonial-toggle">
                                        <h3 className="testimonial__title">"Second time through, amazing results!"</h3>
                                    </div>
                                    <div className="testimonial__toggeable">
                                        <p>16 weeks of Home Workout Domination (I did it twice). SAME WEIGHT – less inches! I’m moving on to Lioness, and I can’t wait to see my results!</p>
                                        <p className="txt--pink"><strong>- Theresa V.</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="txt--center txt--15 lh--auto"><em>* Individual results may vary and may not be typical or representative of what you might expect. <br className="d-none d-lg-block"/>To learn more about our community’s experiences with our programs look at our Testimonial Support page.</em></p>

                </div>
            </section>

            {/*start now*/}
            <section className="bg--gray-top arrow-down" id="order-now">
                <div className="container pt--40">
                    <h2 className="mb--10">START TODAY!</h2>
                    <h2 className="h2--sm fw--regular txt--center mb--40">Click <span className="txt--purple-light">“Start Now”</span> Button TODAY to get Home Workout
                        <br className="d-none d-lg-block"/> Domination and Rock Your Body!</h2>

                    <div className="txt--center">
                        <img src="/images/hwd/hwd-main.png" alt="" width="1069" height="581" className="lazy no-shadow mb--20"/>
                    </div>

                    <div className="container container--md txt--center border-top-1 border-light-gray pt--30">
                        <h2 className="fw--regular h2--sm h2--xs-32 h2--xxs-32 h2--xxxs-26 mb--40">
                            <span className="d-inline-block">Workout Plan <span className="txt--purple-light"><strong>($197 Value)</strong></span></span>
                            <span className="d-inline-block">+ Bonuses <span className="d-inline-block txt--purple-light"><strong>($217 Value)</strong></span></span>
                            <span className="d-inline-block">= $414 Value</span></h2>

                        <DigitalProductBuyBox
                            product={product}
                        />

                        <img className="shadow-0 d-block mx--auto mb--30 lazy" src="/images/credit-cards.png" alt="" width="300" height="39"/>

                            <p className="txt--14 txt--xs-16 mb--10 mb--xs-20"><strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong></p>
                            <p className="txt--14 txt--xs-16"><em>You’ll receive INSTANT ACCESS to the Home Workout Domination and Bonuses Immediately <br className="d-none d-md-block"/>After Purchase. This Program is 100% Digital. No Physical Products will be Shipped.</em></p>
                    </div>
                </div>
            </section>

            {/*fb testimonials*/}
            <section className="bg--section arrow-down">
                <div className="container">
                    <h2 className="h2--sm mb--30">More Success Stories</h2>
                    <div className="row align-items-center img--stack-wrapper">
                        <div className="col-md-6 mb--50 mb--xs-30">
                            <div className="img-stack mb--50 mb--xs-30">
                                <img className="lazy" src="/images/hwd/fb-testimonial-1.jpg" alt="" width="542" height="188"/>
                            </div>

                            <div className="img-stack mb--50 mb--xs-30">
                                <img className="lazy" src="/images/hwd/fb-testimonial-3.jpg" alt="" width="537" height="161"/>
                            </div>

                            <div className="img-stack">
                                <img className="lazy" src="/images/hwd/fb-testimonial-5.jpg" alt="" width="536" height="137"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb--50 mb--xs-30">
                            <div className="img-stack mb--50 mb--xs-30">
                                <img className="lazy" src="/images/hwd/fb-testimonial-2.jpg" alt="" width="535" height="236"/>
                            </div>

                            <div className="img-stack mb--50 mb--xs-30">
                                <img className="lazy" src="/images/hwd/fb-testimonial-4.jpg" alt="" width="527" height="177"/>
                            </div>

                            <div className="img-stack">
                                <img className="lazy" src="/images/hwd/fb-testimonial-6.jpg" alt="" width="538" height="120"/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/*guarantee*/}
            <div className="container pt--30 pb--50">
                <div className="box-guarantee box-guarantee--purple-light">
                    <img src="/images/guarantee.png" alt="" width="239" height="245" className="lazy no-shadow"/>
                        <div className="row">
                            <div className="col-lg-8">
                                <h2 className="h2--sm txt--white mb--20 txt--smd-left">60 – Day Money Back GUARANTEE</h2>

                                <p className="txt--white lh--auto">Take the first step toward improving your health by trying my Home Workout Domination plan for the next 60 days. If you don’t feel healthier, more energized and simply amazing, you won’t pay. If you’re not satisfied, simply contact customer service within 60 days for a full refund.</p>

                                <p className="txt--white txt--22 fw--black"><span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc</p>
                            </div>
                        </div>
                </div>
            </div>


            <section className="bg--section js-section py--50 pb--xs-30" id="faq">
                <div className="container">
                    <h2 className="mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                </div>
                <div className="container container--xs">

                    <Faqs color="purple-light" productName='hwd'/>

                </div>
            </section>
        </>
    )
}
export default HomeWorkoutDomination
