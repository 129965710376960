import React, {useEffect, useState} from "react"
import Faqs from "../../components/Faqs";
import Tabs from "../../components/Tabs"
import {Helmet} from "react-helmet";

const BerryGreenProtein = ({updateActiveTab}) => {
    const [activeTab, setActiveTab] = useState('about')

    const handleActive = (el) => {
        setActiveTab(el);
    }

    useEffect(() => {
        setActiveTab(updateActiveTab)
    },[updateActiveTab])

    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="Berry Green Protein"/>
                <meta property="og:description" content="100% organic, high protein (18g protein per serving), nutrient-dense whole food powder combining 15 superfood greens and berries into one delicious smooth strawberry vanilla flavored shake!"/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/BGP-FB.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of Berry Green Protein." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="Berry Green Protein"/>
                <meta name="twitter:description" content="100% organic, high protein (18g protein per serving), nutrient-dense whole food powder combining 15 superfood greens and berries into one delicious smooth strawberry vanilla flavored shake!"/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/BGP-FB.jpg"/>
                <meta name="twitter:image:alt" content="Berry Green Protein." />
                <meta name="twitter:card" content="summary_large_image"/>
                <title>Berry Green Protein</title>
            </Helmet>

            {/*tab menu*/}
            <section className="pt--70 pt--md-30 pb--0">

                <Tabs active={activeTab} handleActive={handleActive}/>

                {/*tab content*/}
                <div className="tab-content">
                    {/*tab content about*/}
                    { (activeTab === "about") &&
                    <div id="about">
                        {/*get your greens*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--20 h2--supplements mb--md-0 mb--xxs-10">Get your Greens and Protein in one convenient scoop!</h2>
                            <h4 className="txt--center txt--pink font--oswald txt--24 txt--xxs-20 mb--80 mb--md-20">Protein and Greens combined to support your body from the inside out...</h4>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="video bg--white video--16-9 mb--30">
                                        <iframe title="Get your greens." src="https://player.vimeo.com/video/353687353" frameBorder="0"/>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <ul className="checkmark--supplements checkmark--supplements-pink">
                                        <li>Protein helps <strong className="fw--bold">regulate</strong> our hormones, enzyme production and our body fat.</li>
                                        <li>Greens <strong className="fw--bold">boost</strong> your immune system and support a glowing complexion.</li>
                                        <li>Protein <strong className="fw--bold">helps</strong> you beat cravings because it’s even more satisfying than carbs and fat.</li>
                                        <li>Greens <strong className="fw--bold">activate</strong> a healthy digestive flow, decrease inflammation, joint pain and stiffness.</li>
                                        <li>Protein <strong className="fw--bold">supports</strong> a healthy metabolism that can efficiently burn fat and keep you energized.</li>
                                        <li>Greens help <strong className="fw--bold">improve</strong> your energy and mood.</li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        {/*15 organic superfoods*/}
                        <section className="bg--supplements-section section--padding-xl">

                            <div className="container">
                                <h2 className="mb--80 mb--md-20 h2--supplements">18 grams of Protein and 15 Organic Superfoods to <span className="txt-xs--pink">Fuel Your Workouts, Boost Your Energy</span> and <span className="txt-xs--pink">Revitalize Your Body…</span></h2>

                                <div className="row row--flex">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-north-american-pea"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">North American Pea</h6>
                                            <p className="txt--14 txt--xs-16">Highly digestible and absorbable plant protein source that contains nearly all the vitamins and minerals your body needs.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-spirulina"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Spirulina</h6>
                                            <p className="txt--14 txt--xs-16">Antioxidant and protein rich blue-green algae, packed with health supporting vitamins and minerals like calcium, niacin, potassium, magnesium, B vitamins and iron.</p>
                                        </div>

                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-spinach"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Spinach</h6>
                                            <p className="txt--14 txt--xs-16">High in calcium for strong bones, folic acid to support cells and tissue growth, iron, vitamin K, and naturally occurring nitrates to support energy and athletic performance.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-barley-grass"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Barley Grass</h6>
                                            <p className="txt--14 txt--xs-16">Used by ancient gladiators to boost strength and endurance, it’s a source of B vitamins, magnesium and enzymes that supports your immune system and metabolic processes.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-wheat-grass"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Wheat Grass</h6>
                                            <p className="txt--14 txt--xs-16">Contains 8 essential amino acids, is rich in chlorophyll, flavonoids, and powerful antioxidants like glutathione and vitamins C and E which reduce oxidative stress.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-chlorella"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Chlorella</h6>
                                            <p className="txt--14 txt--xs-16">Contains all 9 essential amino acids, including branch chain amino acids which improve aerobic performance; contains heart healthy omega-3 fatty acids, and helps detoxify the body by binding to heavy metals.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-tomatoes"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Tomatoes</h6>
                                            <p className="txt--14 txt--xs-16">Rich in vitamin C, K, potassium and folate, tomatoes are a rich source of lycopene, an antioxidant that improves heart health.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-carrots"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Carrots</h6>
                                            <p className="txt--14 txt--xs-16">Great source of beta carotene that your body converts into Vitamin A to support vision and the immune system; rich in vitamin K and potassium which aids in nerve signaling and muscle contraction.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-beets"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Beets</h6>
                                            <p className="txt--14 txt--xs-16">Packed with vitamins and antioxidants, beets also contain betaine, a key nutrient that regulates inflammation in your cardiovascular system.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-kale"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Kale</h6>
                                            <p className="txt--14 txt--xs-16">Packed with vitamin K, beta carotene, magnesium and antioxidants like quercetin and kaempferol, which may lower blood pressure.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-parsley"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Parsley</h6>
                                            <p className="txt--14 txt--xs-16">Packed with vitamin K, beta carotene, magnesium and antioxidants like quercetin and kaempferol, which may lower blood pressure.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-apples"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Apples</h6>
                                            <p className="txt--14 txt--xs-16">Polyphenols from apples have a powerful effect on preventing and even reversing the effects of oxidation and inflammation. Along with B1 and B2, B6 is also found in apples which aids in the metabolism of protein, fat and carbohydrates.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-raspberries"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Raspberries</h6>
                                            <p className="txt--14 txt--xs-16">Contain ellagic acid, a chemoprotective compound that has anti inflammatory properties, and are a great source of Vitamin C which helps lower oxidative stress.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-cranberries"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Cranberries</h6>
                                            <p className="txt--14 txt--xs-16">Contain manganese, which aids protein and amino acid digestion as well as supporting the metabolism of cholesterol and carbohydrates; also contains copper which contributes to iron absorption.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 food-blueberries"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10">Blueberries</h6>
                                            <p className="txt--14 txt--xs-16">Contain the highest antioxidant levels of all fruits and vegetables, and contain anthocyanins, which may have beneficial effects on insulin sensitivity and glucose metabolism.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*3 ways to enjoy*/}
                        <section className="bg--supplements-bgp-easy section--padding-lg">
                            <div className="container">

                                <div className="row">
                                    <div className="col-xs-12 col-md-8 col-lg-5">
                                        <div className="d-flex align-items-center justify-content-center mb--60 mb--sm-30">
                                            <img src="/images/supplements/three.png" className="mr--10 no-shadow radius-0" alt="Number Three"/>
                                            <h2 className="h2--supplements txt--left">Easy Ways to Enjoy Berry Green Protein</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-md-6 col-lg-5">
                                        <div className="d-flex mb--40">
                                            <div className="circle circle--pink">
                                                <img src="/images/supplements/shake.png" className="no-shadow radius-0" alt="Shake it Icon" width="33" height="57" />
                                            </div>

                                            <div className="pl--20">
                                                <h6 className="h6--supplements txt--uppercase mb--10"><strong className="fw--bold">shake it</strong></h6>
                                                <p>Mix your Berry Green protein with just water and enjoy a fast, smooth and delicious protein and greens infusion!</p>
                                            </div>
                                        </div>

                                        <div className="d-flex mb--40">
                                            <div className="circle circle--pink">
                                                <img src="/images/supplements/blend.png" className="no-shadow radius-0" alt="Blend it Icon" width="36" height="56"/>
                                            </div>

                                            <div className="pl--20">
                                                <h6 className="h6--supplements txt--uppercase mb--10"><strong className="fw--bold">blend it</strong></h6>
                                                <p>Add your Berry Green Protein to a smoothie to increase the variety of greens and boost your protein content!</p>
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <div className="circle circle--pink">
                                                <img src="/images/supplements/bake.png" className="no-shadow radius-0" alt="Bake it Icon" width="54" height="50" />
                                            </div>

                                            <div className="pl--20">
                                                <h6 className="h6--supplements txt--uppercase mb--10"><strong className="fw--bold">bake it</strong></h6>
                                                <p>Add Berry Green Protein to your favorite power bars and muffins to boost the nutritional value and add a delicious strawberry vanilla sweetness!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <img src="/images/supplements/berry-green-protein/bg--supplements-bgp-easy-mob.png" className="d-block d-md-none no-shadow radius-0" alt="Betty image."/>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Berry Green Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/berry-green-protein/bonus-img-desktop.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="641" height="253"/>
                                    </div>
                                  </div>
                            </div>
                        </div>

                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="no-shadow radius-0 mb--20 img--lg" width="456" height="75"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>

                        </section>

                        {/*whats inside*/}
                        <section className="bg--supplements-bgp-nutrition section--padding-xl">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-lg-7">
                                        <h2 className="mb--30 h2--supplements txt--left">What's Inside</h2>
                                        <p className="p--supplements"><span className="txt--pink">Berry Green Protein</span> is an organic, high protein, nutrient-dense functional food powder. Its protein source is a natural pea protein isolate from certified organic North American grown yellow peas. This pea protein is produced with a natural fermentation process which uses no chemical solvents. It is ideal for individuals on a dairy-free diet, or anyone who is looking for a vegetarian protein with high digestibility. It has an excellent array of amino acids, including high levels of BCAA’s (branch-chain amino acids).</p>
                                        <p className="p--supplements">We work closely with our certified organic growers to get the best phytonutrient profile in this proprietary blend of greens, vegetables and fruits. The brightly colored, non-oxidized powders are protected from direct heat, UV light and moisture during the manufacturing process. This organic blend of ingredients is harvested at an optimal time then concentrated and dried to preserve their nutritional potency.</p>
                                        <p className="p--supplements">This product contains no grains, alfalfa, corn, gluten fructose or artificial sweeteners.</p>

                                    </div>
                                </div>

                                <img src="/images/supplements/berry-green-protein/nutrition-img.png" className="mt--20 no-shadow radius-0 d-block mx--auto d-lg-none" alt="Berry Green Protein.." width="770" height="521"/>
                            </div>
                         </section>

                        {/*ingredients*/}
                        <section className="bg--supplements-section section--padding-xl">
                            <div className="container">

                                <h2 className="mb--30 h2--supplements txt--left">Ingredients</h2>

                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-lg-6 order-1">
                                        <p className="p--supplements mb--30"><img src="/images/supplements/seedling.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Seedling icon." width="70" height="61"/><span className="txt--pink"><strong className="fw--bold">Organic Greens Proprietary Blend:</strong></span> Organic Spirulina, Organic Barley Grass Juice, Organic Wheat Grass Juice, Organic Chlorella</p>
                                        <p className="p--supplements mb--30"><img src="/images/supplements/carrot.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Carrot icon." width="90" height="90"/><span className="txt--pink"><strong className="fw--bold">Organic Vegetable Proprietary Blend:</strong></span> Organic Spinach, Organic Carrot, Organic Beet, Organic Tomato, Organic Kale, Organic Parsley</p>
                                        <p className="p--supplements mb--30"><img src="/images/supplements/apple.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Apple icon." width="70" height="80"/><span className="txt--pink"><strong className="fw--bold">Organic Fruit Proprietary Blend:</strong></span> Organic Apple, Organic Blueberry, Organic Cranberry, Organic Raspberry</p>
                                        <p className="p--supplements mb--30 mb--md-0"><img src="/images/supplements/leaf.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Leaf icon." width="70" height="62"/>Organic pea protein, certified organic natural flavors**, <i>certified organic stevia leaf extract</i> (Stevia reaudiana)*.</p>
                                    </div>

                                    <div className="col-xs-12 col-lg-6 txt--center mt--md-30 order-4 order-lg-2">
                                        <img src="/images/supplements/berry-green-protein/bgp-nutrition.png" className="supplements--border no-shadow radius-0" alt="Supplements table" width="563" height="390"/>
                                    </div>

                                    <div className="col-xs-12 order-2 order-lg-3 mt--80 mt--md-30 mt--xs-20">
                                        <div className="note mb--40 mb--xs-20">
                                            <p className="p--supplements"><i>*We use an organic, higher grade stevia (Reb A) than most commercially available products that use stevia (which use a less expensive and lower grade Reb B). Additionally, our stevia is never processed with alcohol. We believe that these factors are what give our Berry Green Protein its superior taste, without the odd tinny taste many stevia-sweetened products have.</i></p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 order-3 order-lg-4">
                                        <div className="note">
                                            <p className="p--supplements"><i>**All natural flavors must be derivatives of a natural plant substance. However there is a lot of margin around how natural these products end up being, as how they are processed really makes a difference. Not only do we only use certified organic natural strawberry and vanilla flavors, we distill these flavors out of our plants using non-chemical methods that do not compromise their quality or integrity.</i></p>
                                        </div>
                                    </div>
                                   </div>
                            </div>
                        </section>

                    </div>}

                    {/*tab content nutrition*/}
                    { (activeTab === "nutrition") &&
                    <div id="nutrition">

                        {/*whats inside*/}
                        <section className="bg--supplements-bgp-nutrition section--padding-xl">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-lg-7">
                                        <h2 className="mb--30 h2--supplements txt--left">What's Inside</h2>
                                        <p className="p--supplements"><span className="txt--pink">Berry Green Protein</span> is an organic, high protein, nutrient-dense functional food powder. Its protein source is a natural pea protein isolate from certified organic North American grown yellow peas. This pea protein is produced with a natural fermentation process which uses no chemical solvents. It is ideal for individuals on a dairy-free diet, or anyone who is looking for a vegetarian protein with high digestibility. It has an excellent array of amino acids, including high levels of BCAA’s (branch-chain amino acids).</p>
                                        <p className="p--supplements">We work closely with our certified organic growers to get the best phytonutrient profile in this proprietary blend of greens, vegetables and fruits. The brightly colored, non-oxidized powders are protected from direct heat, UV light and moisture during the manufacturing process. This organic blend of ingredients is harvested at an optimal time then concentrated and dried to preserve their nutritional potency.</p>
                                        <p className="p--supplements">This product contains no grains, alfalfa, corn, gluten fructose or artificial sweeteners.</p>

                                    </div>
                                </div>

                                <img src="/images/supplements/berry-green-protein/nutrition-img.png" className="mt--20 no-shadow radius-0 d-block mx--auto d-lg-none" alt="Berry Green Protein.." width="770" height="521"/>
                            </div>
                         </section>

                         {/*ingredients*/}
                        <section className="bg--supplements-section section--padding-xl">
                            <div className="container">

                                <h2 className="mb--30 h2--supplements txt--left">Ingredients</h2>

                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-lg-6 order-1">
                                        <p className="p--supplements mb--30"><img src="/images/supplements/seedling.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Seedling icon." width="70" height="61"/><span className="txt--pink"><strong className="fw--bold">Organic Greens Proprietary Blend:</strong></span> Organic Spirulina, Organic Barley Grass Juice, Organic Wheat Grass Juice, Organic Chlorella</p>
                                        <p className="p--supplements mb--30"><img src="/images/supplements/carrot.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Carrot icon." width="90" height="90"/><span className="txt--pink"><strong className="fw--bold">Organic Vegetable Proprietary Blend:</strong></span> Organic Spinach, Organic Carrot, Organic Beet, Organic Tomato, Organic Kale, Organic Parsley</p>
                                        <p className="p--supplements mb--30"><img src="/images/supplements/apple.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Apple icon." width="70" height="80"/><span className="txt--pink"><strong className="fw--bold">Organic Fruit Proprietary Blend:</strong></span> Organic Apple, Organic Blueberry, Organic Cranberry, Organic Raspberry</p>
                                        <p className="p--supplements mb--30 mb--md-0"><img src="/images/supplements/leaf.png" className="no-shadow radius-0 img--xxxs mr--10" alt="Leaf icon." width="70" height="62"/>Organic pea protein, certified organic natural flavors**, <i>certified organic stevia leaf extract</i> (Stevia reaudiana)*.</p>
                                    </div>

                                    <div className="col-xs-12 col-lg-6 txt--center mt--md-30 order-4 order-lg-2">
                                        <img src="/images/supplements/berry-green-protein/bgp-nutrition.png" className="supplements--border no-shadow radius-0" alt="Supplements table" width="563" height="390"/>
                                    </div>

                                    <div className="col-xs-12 order-2 order-lg-3 mt--80 mt--md-30 mt--xs-20">
                                        <div className="note mb--40 mb--xs-20">
                                            <p className="p--supplements"><i>*We use an organic, higher grade stevia (Reb A) than most commercially available products that use stevia (which use a less expensive and lower grade Reb B). Additionally, our stevia is never processed with alcohol. We believe that these factors are what give our Berry Green Protein its superior taste, without the odd tinny taste many stevia-sweetened products have.</i></p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 order-3 order-lg-4">
                                        <div className="note">
                                            <p className="p--supplements"><i>**All natural flavors must be derivatives of a natural plant substance. However there is a lot of margin around how natural these products end up being, as how they are processed really makes a difference. Not only do we only use certified organic natural strawberry and vanilla flavors, we distill these flavors out of our plants using non-chemical methods that do not compromise their quality or integrity.</i></p>
                                        </div>
                                    </div>
                                   </div>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Berry Green Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/berry-green-protein/bonus-img-desktop.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="641" height="253"/>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>}

                    {/*tab content whybuy*/}
                    { (activeTab === "quality") &&
                    <div id="quality">
                        {/*quality support*/}
                        <section className="container section--padding-xl">

                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                            <div className="row">
                                <div className="col-xs-12 col-lg-6">
                                    <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                    <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                    <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <div className="video bg--white video--16-9 mb--30">
                                        <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                    </div>

                                    <div className="txt--center">
                                        <img src="/images/supplements/4-icons_2x.png" alt="Whole Betty" className="no-shadow radius-0 mb--20 img--lg" width="456" height="75"/>
                                      </div>
                                </div>
                            </div>

                            <div className="supplements--box d-flex align-items-center">
                                <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                            </div>
                        </section>

                        {/*bonus*/}
                        <div className="bg--supplements-bgp-paint py--30">
                            <div className="container">
                                <div className="row flex-wrap align-items-center">
                                    <div className="col-xs-12 col-sm-5 col-lg-4 col-md-offset-1">
                                      <h2 className="txt--left txt--pink txt--uppercase txt--xl mb--25 mb--sm-20 mt--0"><strong className="fw--bold">bonus!</strong></h2>
                                      <h4 className="fw--medium txt--white txt--md font--oswald">Berry Green Protein Recipe Guide, <span className="d-inline-block d-sm-inline">included with your first order!</span></h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-7 col-lg-7 overflow--hidden">
                                      <img src="/images/supplements/berry-green-protein/bonus-img-desktop.png" alt="Bonus Image" className="no-shadow radius-0 mobile-overflow" width="641" height="253"/>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>}

                    {/*tab content faq*/}
                    { (activeTab === "faq") &&
                    <div id="faq">
                        {/*faq*/}
                        <section className="container container--xs section--padding-xl" id="faq">
                            <h2 className="h2--supplements mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                            <Faqs productName='bgp'/>
                        </section>
                    </div>}

                </div>
            </section>

            {/*pink section*/}
            <section className="bg--pink py--30">
                <div className="container">

                    <div className="row flex-wrap align-items-center">
                        <div className="col-xs-12 col-lg-4 txt--center-md txt--right order-2 order-lg-1">
                            <img src="/images/supplements/betty.png" className="no-shadow radius-0" alt="Betty Image." width="214" height="311"/>
                        </div>

                        <div className="col-xs-12 col-lg-8 order-1 order-lg-2">
                            <h3 className="h3--supplements ls--xxl txt--white d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row txt--uppercase mb--30"><span className="no-shrink">whole body <strong className="fw--light">by</strong></span> <img src="/images/supplements/logo-white.png" className="no-shadow radius-0 img--header ml--10" alt="White logo" width="416" height="73"/></h3>
                            <p className="txt--white p--supplements txt--center-md mb--0 mb--md-20">At Whole Betty, we believe that Whole Foods should form the foundation for what you put in your body. That’s why our ingredients are mindfully sourced and our blends are consciously created so you get the maximum health benefit, every time.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/*what our rockstars are saying*/}
            <section className="bg--supplements-section section--padding-xl">
                <div className="container">
                    <h2 className="mb--80 mb--md-20 h2--supplements">What Our Rockstars are Saying About<br className="d-none d-md-block"/> Berry Green Protein…</h2>

                    <div className="row">

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Sara C." src="https://player.vimeo.com/video/359378493" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“This tastes really good with anything, you can bake with it and it even tastes good by itself with just some almond milk or even water.”</i><span className="d-block">- <strong className="fw--bold">Sara C.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Kim H." src="https://player.vimeo.com/video/359380695" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“I think the thing I like best about it that makes me recommend this protein is the brand. I really trust Betty Rocker and I really trust her products, and that what I'm putting in my body is CLEAN and that it's there to make me better, to make my workouts better and to make me healthier.”</i><span className="d-block">- <strong className="fw--bold">Kim H.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Angela D." src="https://player.vimeo.com/video/359377752" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“It is a game changer for when I'm traveling...I've tried other powders that have greens in them and I just don't like the taste, and they're hard to mix with just water - and that is not the case with Berry Green Protein powder. It mixes super easily and it tastes great!”</i><span className="d-block">- <strong className="fw--bold">Angela D.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Rebecca G." src="https://player.vimeo.com/video/359381643" frameBorder="0"/>
                            </div>

                            <div className="quote">
                                <p><i>“This is my favorite protein of all time. Two thumbs up from me - it's amazing and I can't say enough good stuff about it.”</i><span className="d-block">- <strong className="fw--bold">Rebecca G.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-md-4 clear--left col-xs-custom">
                            <img src="/images/supplements/berry-green-protein/testimonial-1.jpg" className="no-shadow radius-0 mb--20" alt="Testimonial 1." width="370" height="370"/>
                            <div className="quote">
                                <p className="p--supplements"><i>“After a tough workout, the BerryGreen protein is yummy as a shake or a blend.”</i> <span className="d-block">- <strong className="fw--bold">Kala M.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-md-4 clear--left col-xs-custom">
                            <img src="/images/supplements/berry-green-protein/testimonial-2.jpg" className="mb--20 no-shadow radius-0" alt="Testimonial 1." width="370" height="370"/>
                            <div className="quote">
                                <p><i>“Lucas (age 7) made his very own Berry Green Protein creation: apples and bananas sprinkled with Berry Green Protein. I told him I would send you a picture. His brother Liam says, 'wait, wait, wait, Betty Rocker is real?! I thought she was just in your phone.' LOL”</i> <span className="d-block">- <strong className="fw--bold">Tammy F.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-md-4 clear--left col-xs-custom">
                            <img src="/images/supplements/berry-green-protein/testimonial-3.jpg" className="no-shadow radius-0 mb--20" alt="Testimonial 1." width="370" height="370"/>
                            <div className="quote">
                                <p><i>“This product mixes extremely well. I have not had one single clump and it does well to not stick to the side of whatever I mix in. I really like that it does NOT have an earthy taste, even my husband and 2-year old have had some and liked it!”</i> <span className="d-block">- <strong className="fw--bold">Liz H.</strong></span></p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
export default BerryGreenProtein
