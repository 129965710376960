import React, {useEffect, useState} from "react";
import productData from "../../static/data/product";
import QuickBuyPopup from "./QuickBuyPopup";
import { UseShopifyDataQuery } from "../hooks/shopifyData";
import ProductBoxCollectionContainer from "./ProductBoxCollectionContainer";

const SuggestedProducts = ({shopify, currentVariantId}) => {

    const shopifyData = UseShopifyDataQuery();

    const [isOpenPopup, setOpenPopup] = useState(false)
    const [clickedProduct, setClickedProduct] = useState(false)
    const [shopifyItem, setShopifyItem] = useState('')

    const [productCollection, setProductCollection] = useState('')

    useEffect(() => {
        setProductCollection(productData[currentVariantId].collection)
    },[])

    const handlePopup = (isOpen) => {
        setOpenPopup(isOpen)
    }
    const handleShopify = (item) => {
        setShopifyItem(item)
    }
    const handleProduct = (product) => {
        setClickedProduct(product)
    }


    return (
        <>
            <section className="section--padding-lg pb--20i">
                <div className="container">
                    <h2 className="mb--80 mb--md-20 h2--supplements txt--uppercase">You may <span className="d-inline-block txt--pink">also like</span></h2>

                    <div className="row justify-content-center">
                        {
                            Object.keys(productData).map((productId) => (
                                shopifyData.map((item, index) => (
                                    <ProductBoxCollectionContainer
                                        key={index}
                                        collection={productCollection}
                                        productId={productId}
                                        variantId={currentVariantId}
                                        type={productData[currentVariantId].type}
                                        item={item}
                                        handlePopup={handlePopup}
                                        handleProduct={handleProduct}
                                        handleShopify={handleShopify}
                                    />
                                ))
                            ))
                        }
                    </div>
                </div>
            </section>
            {isOpenPopup &&
            <QuickBuyPopup
                open={isOpenPopup}
                handlePopup={handlePopup}
                shopify={shopifyItem}
                product={clickedProduct}/>}
       </>
    )
}
export default SuggestedProducts

