import React from "react"

const References = ({id}) => {
    return (
        <>
            <section className="bg--section">
                <div className="container container--xs">
                    <h5 className="txt--24 line-under"><strong className="fw--bold">References</strong></h5>

                    {/*whole sleep*/}
                    { (id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ0MjM4OTU1ODA3NDI=') &&
                    <ol className="references m--0 pl--20 pl--sm-10">
                        <li>Valerian. Hadley S, Petry JJ. Am Fam Physician 2003;67:1755-1758.</li>
                        <li>On the spasmolytic activity of valeriana extracts (author’s transl). Wagner H, Jurcic K. Planta Med 1979;37:84-86. [Article in german]</li>
                        <li>Valeriana officinalis Monograph.. Altern Med Rev 2004 Dec;9(4):438-41.</li>
                        <li>Passiflora: a review update. Dhawan K, Dhawan S, Sharma A. J Ethnopharmacol 2004 Sep;94(1):1-23.</li>
                        <li>Passion flower (Passiflora incarnata L.)-a reliable herbal sedative. Wien Krenn L. Med Wochenschr 2002;152(15-16):404-6. [Article in German]</li>
                        <li>Attenuation of laboratory-induced stress in human after acute administration of Melissa officinalis (Lemon Balm). Kennedy DO, Little W, Scholey AB. Psychosom Med 2004 Jul-Aug;66(4):607-13.</li>
                        <li>Plants and the central nervous system. Carlini EA. Pharmacol Biochem Behav 2003 Jun;75(3):501-12</li>
                        <li>Flavonoids from lemon balm (Melissa officinalis L., Lamiaceae). Patora J, Klimek B. Acta Pol Pharm 2002 Mar-Apr;59(2):139-43.</li>
                        <li>Phytochemical and biological analysis of skullcap (Scutellaria lateriflora L.): a medicinal plant with anxiolytic properties. Awad R, Arnason JT, Trudeau V, et al. Phytomedicine 2003 Nov;10(8):640-9.</li>
                        <li>L-Theanine reduces psychological and physiological stress responses. Kimura K, Ozeki M, Juneja L, Ohira H (2007). Biol Psychol 74 (1):39–45.</li>
                        <li>Sleep-inducing effects of low doses of melatonin ingested in the evening. Zhadanova IV, Wurtman RJ, Lynch HJ, et al. Clin Pharmacol Ther 1995;57:552-8.</li>
                        <li>The role of melatonin and circadian phase in age-related sleep maintenance insomnia: assessment in a clinical trial of melatonin replacement. Hughes RJ, Sack RL, Lewy AJ. Sleep 1998;21:52-68.</li>
                        <li>A double-blind trial of melatonin as a treatment for jet lag in international cabin crew. Petrie K, Dawson AG, Thompson L, et al. Biol Psychiatry 1993;33:526-30.</li>
                        <li>Textbook of Natural Medicine (2nd Ed.). Pizzorno JE, Murray MT. Churchill Livingstone, New York, 1999.</li>
                        <li>Complimentary & Alternative Medicines: Professional’s Handbook. Fetrow CW, Avila JR. Springhouse, Springhouse, PA, 1999.</li>
                        <li>Botanical Influences on Illness: A sourcebook of clinical research. Werbach MR, Murray, MT. Third Line Press, Tarzana, California, 1994.</li>
                        <li>Incorporating Herbal Medicine Into Clinical Practice. Bascom A. F.A. Davis Co., Philadelphia, 2002.</li>
                        <li>Encyclopedia of Herbal Medicine. Cheallier A. Dorling Kindersley, London, 2000.</li>
                        <li>Pharmacognosy and Pharmacobiotechnology. Robbers JE, Speedie MK, Tyler VE. Williams & Wilkins, Baltimore, 1996.</li>
                        <li>PDR for Nutritional Supplements, 1st Ed. Medical Economics/Thompson Healthcare, 2001.</li>
                        <li>PDR for Herbal Medicines, 1st Ed. Medical Economics/Thompson Healthcare, 1998.</li>
                    </ol>
                    }
                    {/*serenicalm*/}
                    { (id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ2Nzk2MjAwMzQ2MzA=') &&
                    <ol className="references m--0 pl--20 pl--sm-10">
                        <li>Kozlowska, Kasia et al. “Fear and the Defense Cascade: Clinical Implications and Management.” Harvard review of psychiatry. 2015. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4495877/</li>
                        <li>Hasler, Gregor et al. “Effect of acute psychological stress on prefrontal GABA concentration determined by proton magnetic resonance spectroscopy.” The American Journal of Psychiatry. 2010. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3107037/</li>
                        <li>“Niacin.” Linus Pauling Institute. Aug 2018. https://lpi.oregonstate.edu/mic/vitamins/niacin</li>
                        <li>Urberg M, Zemel MB. “Evidence for synergism between chromium and nicotinic acid in the control of glucose tolerance in elderly humans.” Metabolism. 1987. https://pubmed.ncbi.nlm.nih.gov/3626867/</li>
                        <li>Jaroenporn S, Yamamoto T, Itabashi A, et al. “Effects of pantothenic acid supplementation on adrenal steroid secretion from male rats.” Biol Pharm Bull. 2008. https://www.ncbi.nlm.nih.gov/pubmed/18520055</li>
                        <li>Clayton PT. “B6-responsive disorders: a model of vitamin dependency.” J Inherit Metab Dis. 2006. https://pubmed.ncbi.nlm.nih.gov/16763894/</li>
                        <li>Mikawa Y, Mizobuchi S, Egi M, Morita K. “Low serum concentrations of vitamin B6 and iron are related to panic attack and hyperventilation attack.” Acta Med Okayama. 2013. https://pubmed.ncbi.nlm.nih.gov/23603926/</li>
                        <li>Beynon, R J et al. “Measurement of the turnover of glycogen phosphorylase by GC/MS using stable isotope derivatives of pyridoxine (vitamin B6).” The Biochemical Journal. 1996. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1217530/</li>
                        <li>Zhao, Mei et al. “Marginal vitamin B-6 deficiency decreases plasma (n-3) and (n-6) PUFA concentrations in healthy men and women.” The Journal of Nutrition. 2012. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3442793/</li>
                        <li>Tsuge H, Hotta N, Hayakawa T. “Effects of vitamin B-6 on (n-3) polyunsaturated fatty acid metabolism.” J Nutr. 2000. https://pubmed.ncbi.nlm.nih.gov/10721899/</li>
                        <li>Meléndez-Hevia E. et al. “A weak link in metabolism: the metabolic capacity for glycine biosynthesis does not satisfy the need for collagen synthesis.” J Biosci. 2009. https://pubmed.ncbi.nlm.nih.gov/20093739/</li>
                        <li>Wang W, Wu Z, Dai Z, Yang Y, Wang J, Wu G. “Glycine metabolism in animals and humans: implications for nutrition and health.” Amino Acids. 2013. https://pubmed.ncbi.nlm.nih.gov/23615880/</li>
                        <li>Petrat, Frank et al. “Glycine, a simple physiological compound protecting by yet puzzling mechanism(s) against ischaemia-reperfusion injury: current knowledge.” British journal of Pharmacology. 2012. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3413844/</li>
                    </ol>
                    }
                    {/*full body collagen*/}
                    { (id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNjg2NjQ4NTQ1OTg=') &&
                    <ol className="references m--0 pl--20 pl--sm-10">
                        <li>Proksch E, et al. “Oral intake of specific bioactive collagen peptides reduces skin wrinkles and increases dermal matrix synthesis.” Skin Pharmacology and Physiology. 2014.&nbsp; https://www.ncbi.nlm.nih.gov/pubmed/24401291</li>
                        <li>Proksch, E, et al. “Oral Supplementation of Specific Collagen Peptides Has Beneficial Effects on Human Skin Physiology: A Double-Blind, Placebo-Controlled Study.” Skin Pharmacology and Physiology. Aug 2013.&nbsp; https://www.essentialnutrition.com.br/media/artigos/redberrywhey/13.pdf</li>
                        <li>Hexsel Doris, et al. “Oral supplementation with specific bioactive collagen peptides improves nail growth and reduces symptoms of brittle nails.” Journal of Cosmetology and Dermatology. Aug. 2017.&nbsp; https://www.ncbi.nlm.nih.gov/pubmed/28786550</li>
                        <li>Zdzieblik, Denise et al. “Improvement of activity-related knee joint discomfort following supplementation of specific collagen peptides.” Applied Physiology, Nutrition, and Metabolism. Jan 2017.&nbsp; https://www.ncbi.nlm.nih.gov/pubmed/28177710</li>
                        <li>König, Daniel et al. “Specific Collagen Peptides Improve Bone Mineral Density and Bone Markers in Postmenopausal Women-A Randomized Controlled Study.” Nutrients. Jan. 16 2018.&nbsp; https://www.ncbi.nlm.nih.gov/pubmed/29337906</li>
                        <li>Zdzieblik, Denise et al. “Collagen peptide supplementation in combination with resistance training improves body composition and increases muscle strength in elderly sarcopenic men: a randomised controlled trial.” The British Journal of Nutrition. 2015.&nbsp; https://www.ncbi.nlm.nih.gov/pubmed/26353786</li>
                        <li>Stewart et al. “Exceptional body composition changes attributed to collagen peptide supplementation and resistance training in older sarcopenic men.” British Journal of Nutrition. 2016.&nbsp; https://pdfs.semanticscholar.org/076e/db1b80f3943bc4f16da957a9083775628e6e.pdf</li>
                    </ol>
                    }
                    {/*vanilla protein*/}
                    { (id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMjE0NjA1NjQxNjA=') &&
                    <ol className="references m--0 pl--20 pl--sm-10">
                        <li>Drummen, Mathijs et al. “Dietary Protein and Energy Balance in  Relation to Obesity and Co-morbidities.” Frontiers in endocrinology.  August 2018 https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6087750/</li>
                        <li>Kreider RB, Campbell B. “Protein for exercise and recovery”. Physician and Sportsmedicine. June 2009. https://pubmed.ncbi.nlm.nih.gov/20048505/</li>
                        <li>Bray, George A et al. “Effect of dietary protein content on weight gain, energy expenditure, and body composition during overeating: a randomized controlled trial.” JAMA https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3777747/</li>
                        <li>Lobo, V et al. “Free radicals, antioxidants and functional foods: Impact on human health.” Pharmacognosy reviews. 2010. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3249911/</li>
                        <li>Martinec, Nenad et al. “Macro- and microelements in pumpkin seed oils: Effect of processing, crop season, and country of origin.” Food science & nutrition. April 2019. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6526651/</li>
                        <li>Chen P, Bornhorst J, Aschner M. “Manganese metabolism in humans”. Front Biosci. March 2018. Marhttps://pubmed.ncbi.nlm.nih.gov/29293455/</li>
                        <li>Tey SL, Salleh NB, Henry J, Forde CG. Effects of aspartame-, monk fruit-, stevia- and sucrose-sweetened beverages on postprandial glucose, insulin and energy intake. International Journal of Obesity. March 2017. https://pubmed.ncbi.nlm.nih.gov/27956737/</li>
                    </ol>
                    }

                </div>
            </section>
        </>

    )
}
export default References
