import React, {useEffect, useState} from "react";
import rockAndRestoreFormulaData from "../../static/data/rockAndRestoreFormula"

const RockAndRestoreFormula = () => {

    const [activeItem, setActiveItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);

    const handleItemClick = (index) => {
        setActiveItem(index)
        setPopupOpen(true)
    }

    const handleClosePopup = () => {
        setPopupOpen(false)
        setActiveItem(null)
    }

    useEffect(() => {
        checkWindowWidth();

        window.onresize = () => checkWindowWidth();
    }, [])

    const checkWindowWidth = () => {
        if(window.innerWidth < 768) {
            setPopupOpen(false)
            setActiveItem(null)
        } else {
            setPopupOpen(false)
            setActiveItem(0)
        }
    }

    return (
        <div className="row gutters-original">
            <div className="col-12 col-md-6">

                <div className="row no-gutters-col5">
                    { rockAndRestoreFormulaData.map((item, index) => (
                        <div key={index} className="col-6 col-sm-4 col-md-6 col-lg-4 d-flex justify-content-center align-items-center">
                            <a onClick={() => handleItemClick(index)} className={`btn btn--rounded ${index === activeItem ? 'active' : ''}`}><span className="btn--relative">{ item.title }</span></a>
                        </div>
                    )) }
                </div>

            </div>

            <div className={`col-12 col-md-6`}>
                <span className={popupOpen ? 'card--rock-and-restore--overlay' : ''} onClick={handleClosePopup}/>
                <div className={`card card--rock-and-restore ${ popupOpen ? 'active' : '' }`}>
                    <div className="card__head card__head--darkgray d-flex align-items-center justify-content-between">
                        <h5 className="txt--22 txt--uppercase txt--white"><strong>{(activeItem != null) && rockAndRestoreFormulaData[activeItem].title}</strong></h5>
                        <button onClick={handleClosePopup} className="card--rock-and-restore__close"><i className="far fa-times"/></button>
                    </div>

                    <div className="card__content"  dangerouslySetInnerHTML={{ __html: (activeItem != null) && rockAndRestoreFormulaData[activeItem].description }}/>
                </div>
            </div>
        </div>
    )
}
export default RockAndRestoreFormula
