import React, {useEffect, useState} from "react";
import ThemeContext from "../context/ThemeContext";
import productData from "../../static/data/product";

const DigitalProductBuyBox = ({ product, singleBox }) => {
    const [isSingle, setIsSingle] = useState('false')
    const [alreadyInCart, setAlreadyInCart] = useState(false)

    const [productId, setProductId] = useState('')
    const [discount, setDiscount] = useState(0)
    const [months, setMonths] = useState(0)
    const [saved, setSaved] = useState(0)

    useEffect(()=>{
        setIsSingle(singleBox ? singleBox : false)

        let productId;
        if(product.variants[0].id.indexOf("Shopify__ProductVariant__") !== -1) {
            productId = product.variants[0].id.split("Shopify__ProductVariant__")[1]
            setProductId(productId)
        }

        if(productData[productId].sellingPlans) {
            setDiscount(productData[productId].sellingPlans[0].price / productData[productId].sellingPlans[0].months)
            setMonths(productData[productId].sellingPlans[0].months)
            setSaved(Number(Math.round((product.variants[0].compareAtPrice - productData[productId].sellingPlans[0].price) * 100 / 100)))
        }

    },[product])

    return (
        <ThemeContext.Consumer>
            {theme => (
                <>
                    {
                        (() => {
                            if( theme.cart.length ) {
                                setAlreadyInCart(false)
                                theme.cart.map((item) => {
                                    if(item.title === product.title) {
                                        setAlreadyInCart(true)
                                    }
                                })
                            } else {
                                setAlreadyInCart(false)
                            }
                        })()
                    }
                    {isSingle ?
                        <div className="pricing-box">
                            <div className="pricing-box__description">Regular Price <span className="strikethrough">${Number(product.variants[0].compareAtPrice)}</span></div>
                            <div className="pricing-box__header"><h3>{ product.title }</h3></div>
                            <div className="pricing-box__title">Today You Pay</div>
                            <div className="pricing-box__price">${Number(product.variants[0].price)}</div>
                            <div className="pricing-box__highlight">Save <strong>${Number(Math.round((product.variants[0].compareAtPrice - product.variants[0].price) * 100 / 100 ))}</strong></div>
                            { alreadyInCart ?
                                <button disabled className="pricing-box__btn">added to cart</button> :
                                <button onClick={() => theme.addVariantToCart(product.variants[0].id, 1, product.variants[0].price, "No", product.title)} className="pricing-box__btn">
                                    { theme.buttonLoader ? <span className="button-loader"/> : 'Start Now' }
                                </button>
                            }
                        </div>
                        :
                        <div className="row mb--20">
                            <div className="col-12 col-md-6">
                                <div className="pricing-box">
                                    <div className="pricing-box__description">Regular Price <span className="strikethrough">${Number(product.variants[0].compareAtPrice)}</span></div>
                                    <div className="pricing-box__header"><h3>{ product.title }</h3></div>
                                    <div className="pricing-box__title">Today You Pay</div>
                                    <div className="pricing-box__price">${Number(product.variants[0].price)}</div>
                                    <div className="pricing-box__highlight">Save <strong>${Number(Math.round((product.variants[0].compareAtPrice - product.variants[0].price) * 100 / 100))}</strong></div>
                                    { alreadyInCart ?
                                        <button disabled className="pricing-box__btn">added to cart</button> :
                                        <button onClick={() => theme.addVariantToCart(product.variants[0].id, 1, product.variants[0].price, "No", product.title)} className="pricing-box__btn">
                                            { theme.buttonLoader ? <span className="button-loader"/> : 'Start Now' }
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="pricing-box">
                                    <div className="pricing-box__description">Regular Price <span className="strikethrough">${Number(product.variants[0].compareAtPrice)}</span></div>
                                    <div className="pricing-box__header"><h3>{product.title}</h3></div>
                                    <div className="pricing-box__title">Payment Plan</div>
                                    <div className="pricing-box__price">${discount} <small>x {months} months</small></div>
                                    <div className="pricing-box__highlight">Save <strong>${saved}</strong></div>
                                    { alreadyInCart ?
                                        <button disabled className="pricing-box__btn">added to cart</button> :
                                        <button onClick={() => theme.addVariantToCart(product.variants[0].id, 1, discount, productData[productId].sellingPlans[0].sellingPlanId, product.title)} className="pricing-box__btn">
                                            { theme.buttonLoader ? <span className="button-loader"/> : 'Start Now' }
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </>
            )}
        </ThemeContext.Consumer>
    )
}
export default DigitalProductBuyBox
