import React, {useState} from "react";
import Layout from "../components/Layout";

import BuyBox from "../components/BuyBox";
import productData from "../../static/data/product";

import BerryGreenProtein from "../components/products/berry-green-protein";
import RockAndRestore from "../components/products/rock-and-restore";
import FullBodyCollagen from "../components/products/full-body-collagen";
import VanillaProtein from "../components/products/vanilla-protein";
import SereniCalm from "../components/products/serenicalm";
import WholeSleep from "../components/products/whole-sleep";
import SevenDayMealPlan from "../components/products/7-day-meal-plan";
import ThirtyDayMealPlan from "../components/products/30-day-meal-plan";
import NinetyDayChallenge from "../components/products/90-day-challenge";
import BodyFuelSystem from "../components/products/body-fuel-system"
import HotAbsAndBooty from "../components/products/30-day-booty-abs-challenge";
import HomeWorkoutDomination from "../components/products/home-workout-domination";
import Lioness from "../components/products/lioness";
import ScrollTopButton from "../components/ScrollTopButton";
import SuggestedProducts from "../components/SuggestedProducts";
import References from "../components/References";

const ProductTemplate = ({ pageContext }) => {
    const { product } = pageContext
    const [updateActiveTab, setUpdateActiveTab] = useState('about')

    const [pageLoader, setPageLoader] = useState(false)

    const handleActiveTab = (e) => {
        setUpdateActiveTab(e.substring(1))
    }

    let shopifyVariantId;
    if(product.variants[0].id.indexOf("Shopify__ProductVariant__") !== -1) {
        shopifyVariantId = product.variants[0].id.split("Shopify__ProductVariant__")[1]
    }

    return (
    <>

        <Layout title={product.title} isLoader={pageLoader}>

            {/*product hero*/}
            { (shopifyVariantId && productData[shopifyVariantId].type === "supplements") ? (
                <BuyBox shopify={product} product={productData[shopifyVariantId]} hero={true}/>
            ) : "" }

            {/*Supplement - Berry Green Protein*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwODgyOTQ1MTQ3NTg=' ? (
                <BerryGreenProtein updateActiveTab={updateActiveTab}/>
            ) : ""}

            {/*Supplement - Rock and Restore*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3NTIwMzM3MzQ4NDg=' ? (
                <RockAndRestore updateActiveTab={updateActiveTab}/>
            ) : ""}

            {/*Supplement - Full Body Collagen*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNjg2NjQ4NTQ1OTg=' ? (
                <FullBodyCollagen updateActiveTab={updateActiveTab}/>
            ) : ""}

            {/*Supplement - Vanilla Protein*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMjE0NjA1NjQxNjA=' ? (
                <VanillaProtein updateActiveTab={updateActiveTab}/>
            ) : ""}

            {/*Supplement - SereniCalm*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ2Nzk2MjAwMzQ2MzA=' ? (
                <SereniCalm updateActiveTab={updateActiveTab}/>
            ) : ""}

            {/*Supplement - Whole Sleep*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ0MjM4OTU1ODA3NDI=' ? (
                <WholeSleep updateActiveTab={updateActiveTab}/>
            ) : ""}

            {/*Program - 7 Day Meal Plan*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2NjcxOTI0Njc2NDg=' ? (
                <SevenDayMealPlan product={product}/>
            ) : ""}

            {/*Program - 30 Day Meal Plan*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0Mjg0OTU1NTI=' ? (
                <ThirtyDayMealPlan product={product}/>
            ) : ""}

            {/*Program - 90 Day Challenge*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0NjgzNDE0NDA=' ? (
                <NinetyDayChallenge product={product}/>
            ) : ""}

            {/*Program - Body Fuel System*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0NTE0NjU5MjA=' ? (
                <BodyFuelSystem product={product}/>
            ) : ""}

            {/*Program - 30 Days Abs and Booty*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0ODY4NTUzNjA=' ? (
                <HotAbsAndBooty product={product}/>
            ) : ""}

            {/*Program - Home Workout Domination*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0NzkyNTMxODQ=' ? (
                <HomeWorkoutDomination product={product}/>
            ) : ""}

            {/*Program - Lioness*/}
            { product.shopifyId === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0ODI3NTkzNjA=' ? (
                <Lioness product={product}/>
            ) : ""}

            {/*scrollTop**/}
            <ScrollTopButton isSupplement={(productData[shopifyVariantId].type === "supplements")} handleActiveTab={handleActiveTab}/>

            {/*BuyBox*/}
            {(productData[shopifyVariantId].type === "supplements") &&
            <BuyBox shopify={product} product={productData[shopifyVariantId]} hero={false}/>}

            {/*Suggested Products*/}
            <SuggestedProducts shopifyProduct={product} currentVariantId={shopifyVariantId}/>

            {/*References*/}
            { (productData[shopifyVariantId].type === "supplements" && product.shopifyId !== 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwODgyOTQ1MTQ3NTg=') && product.shopifyId !== "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3NTIwMzM3MzQ4NDg=" &&
            <References id={product.shopifyId} />}

        </Layout>
    </>
    )
}
export default ProductTemplate
