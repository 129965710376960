import React from "react";
import {scrollTo} from "../utilities/scrollTo";

const ScrollToTabs = ({supplements}) => {

    return (
        <div className="container py--40 py--xs-20">
            <div className={`tabs tabs-supplements ${supplements ? 'tabs--up' : ''}`}>
                <a onClick={scrollTo} href="#what-is-included" className="tab">included</a>
                <a onClick={scrollTo} href="#results" className="tab">results</a>
                <a onClick={scrollTo} href="#faq" className="tab">faq</a>
                <a onClick={scrollTo} href="#order-now" className="tab">start now</a>
            </div>
        </div>
    )
}
export default ScrollToTabs
