const rockAndRestoreFormulaData = [
    {
        title: "Leucine",
        description: "<p class=\"p--supplements\">Leucine is a branch chain amino acid (BCAA) and is considered to be the most anabolic (muscle building) amino acid. Leucine-enriched essential amino acid ingestion has been shown to stimulate muscle protein synthesis by activating the mTOR signalling pathway.</p>\n" +
            "                        <p class=\"p--supplements\">Leucine is also necessary for proper nitrogen balance, which contributes to muscle recovery.</p>"
    },
    {
        title: "Isoleucine",
        description: "<p class=\"p--supplements\">Isolucein is a branched-chain amino acid (BCAA) that’s important for stabilizing and regulating blood sugar, and helping to maintain regular energy levels. It’s also needed for hemoglobin formation.</p>"
    },
    {
        title: "Valine",
        description: "<p class=\"p--supplements\">Valine is also a branch-chain amino acid (and essential amino acid) that helps prevent muscle proteins from breaking down during exercise, both protecting muscle tissue and helping to assist in muscle recovery.</p>"
    },
    {
        title: "Arginine",
        description: "<p class=\"p--supplements\">Arginine plays an important role in cell division, wound healing, removing ammonia from the body and supporting immune function as well as the release of hormones. It also helps with the production of nitric oxide which is a vasodilator (increases blood flow).</p>"
    },
    {
        title: "Histidine",
        description: "<p class=\"p--supplements\">Histidine is the direct precursor of histamine, and is needed to help grow and repair body tissues and to maintain the myelin sheaths that protect nerve cells. Histadine also helps manufacture red and white blood cells and helps to protect the body from heavy metal toxicity.</p>"
    },
    {
        title: "Lysine",
        description: "<p class=\"p--supplements\">Lysine helps the body absorb and conserve calcium, and it plays an important role in the formation of collagen. It may also help support a growth hormone response.</p>"
    },
    {
        title: "Tryptophan",
        description: "<p class=\"p--supplements\">Tryptophan is critical for nitrogen balance, which is necessary for creating an anabolic environment that allows the body to build new muscle and recover from strenuous exercise. Tryptophan is essential for the biosynthesis of niacin, which is critical for the production of the neurotransmitter serotonin.</p>"
    },
    {
        title: "Phenylalanine",
        description: "<p class=\"p--supplements\">Phenylalanine plays a key role in the biosynthesis of other amino acids, such as tyrosine, and various neurotransmitters, including dopamine, epinephrine, and norepinephrine.</p>"
    },
    {
        title: "Methionine",
        description: "<p class=\"p--supplements\">Methionine contains sulfur, and helps your liver process fat, helps ensure normal liver function, and prevents accumulation of fat in your liver. It also supports the liver in regulating glutathione levels. Glutathione is needed to help neutralize toxins in the liver. Methionine reacts with adenosine triphosphate to form S-adenosylmethionine, the principal methyl donor in the body and key contributor to the synthesis of many important compounds, including epinephrine and choline.</p>"
    },
    {
        title: "Threonine",
        description: "<p class=\"p--supplements\">Threonine is an essential amino acid that is an important component in the formation of protein, collagen, elastin, and tooth enamel. It is also important for neurotransmitter production and nervous system health.</p>"
    },
    {
        title: "Alpha-Ketoglutarate",
        description: "<p class=\"p--supplements\">Alpha-ketoglutarate is a compound involved in the generation of energy inside cells. Providing this, along with the free-form essential amino acids in Rock and Restore may help promote stamina and healthy energy levels.</p>"
    },
    {
        title: "Vitamin B6",
        description: "<p class=\"p--supplements\">Vitamin B6 is required for many reactions that convert amino acids into their end products, for example, tyrosine into dopamine. Synthesis of collagen — the main structural protein in the body — requires B6.</p>"
    }
]
export default rockAndRestoreFormulaData
