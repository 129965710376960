const serenicalm = [
    {
        id: 1,
        title: 'Headaches',
        description: 'Stress can trigger tension headaches from tight muscles held in protective and non-relaxed postures.'
    },
    {
        id: 2,
        title: 'Mood Imbalances',
        description: 'Byproducts of chronic stress hormones can act as depressants and create anxiety and other negative feeling states.'
    },
    {
        id: 3,
        title: 'Trouble Sleeping',
        description: 'Being in a heightened state of alertness can make it hard to fall asleep, and cause anxious thoughts to occur making it hard to stay asleep.'
    },
    {
        id: 4,
        title: 'Heartburn',
        description: 'Stress increases the production of stomach acid, which can cause, or worsen, heartburn.'
    },
    {
        id: 5,
        title: 'Rapid Breathing',
        description: 'The airway between the nose and the lungs constricts under stress, leaving you short of breath.'
    },
    {
        id: 6,
        title: 'Weakened immune system',
        description: 'Stress promotes inflammation, which weakens your body\'s defenses.'
    },
    {
        id: 7,
        title: 'Pounding Heart',
        description: 'Stress makes your heart beat faster to pump more blood to your extremities in case of danger.'
    },
    {
        id: 8,
        title: 'High Blood Sugar',
        description: 'Stress causes excess glucose to enter your bloodstream, which can be stored as fat and lead to insulin resistance.'
    },
    {
        id: 9,
        title: 'Risk of heart attack and stroke',
        description: 'Stress can make the heart work harder, increase blood pressure, sugar and fat levels in the blood which can increase clot risk and damage arteries.'
    },
    {
        id: 10,
        title: 'High Blood Pressure',
        description: 'In the chronic stress response, persistant epinephrine bursts can damage blood vessels and arteries, increasing blood pressure and raising the risk of a heart attack or stroke.'
    },
    {
        id: 11,
        title: 'Impaired Nutrient Absorption',
        description: 'Stress weakens gastric secretions, making it harder to break down food and absorb vitual nutrients.'
    },
    {
        id: 12,
        title: 'Increased Hunger',
        description: 'While elevated cortisol levels help replenish the body\'s energy stores that are depleted during the stress response, when chronically elevated it increases appetite (so you\'ll be hungry more frequently) and increases the storage of unused nutrients as body fat.'
    },
    {
        id: 13,
        title: 'Low Sex Drive',
        description: 'Chronic stress causes cortisol levels to rise, which can impact your libido.'
    },
    {
        id: 14,
        title: 'Stomach aches and upsets',
        description: 'Stress can affect the pace that food moves through the digestive system, leading to either diarrhea or constipation. It can cause nausea or stomach aches.'
    },
    {
        id: 15,
        title: 'Irregular periods',
        description: 'When cortisol is released, it can directly affect the balance of estrogen and progesterone, which affects the timing of the menstrual cycle.'
    },
    {
        id: 16,
        title: 'Depleted vitamins and minerals',
        description: 'Many vitamins are needed for adrenal function, but B3, B5 and B6 are needed in large amounts, so when adrenal output is maximized while under stress, these vitamins can become depleted.'
    },
    {
        id: 17,
        title: 'Tense Muscles',
        description: 'In immediate danger, your muscles tense to protect themselves from injury. They normally release again when you\'re no longer threatened, but if you\'re constantly under stress, they may not get a chance to relax.'
    }
]
export default serenicalm;
