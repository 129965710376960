import React, {useEffect, useState} from "react"
import Faqs from "../../components/Faqs";
import Tabs from "../../components/Tabs"
import {Helmet} from "react-helmet";
import AccordionItem from "../AccordionItem";

import serenicalm from "../../../static/data/serenicalm"

const SereniCalm = ({updateActiveTab}) => {
    const [activeTab, setActiveTab] = useState('about')

    const [activeNum, setActiveNum] = useState(0)

    // Popup
    const [popupYPoz, setPopupYPoz] = useState(0)
    const [openPopup, setOpenPopup] = useState(false)
    const [popupNum, setPopupNum] = useState(0)
    const [popupTitle, setPopupTitle] = useState('')
    const [popupDescription, setPopupDescription] = useState('')

    useEffect(() => {
        setActiveTab(updateActiveTab)
    },[updateActiveTab])

    const handleActive = (el) => {
        setActiveTab(el);
    }

    const handleActiveEl = (num) => {
        setActiveNum(num)
    }

    const handlePopup = (e, item) => {

        if(window.innerWidth < 992) {
            setPopupNum(item.id)
            setPopupTitle(item.title)
            setPopupDescription(item.description)

            setPopupYPoz(e.pageY + 20)
            setOpenPopup(true)
        }

    }

    const closePopup = () => {
        setOpenPopup(false)
    }

    return (
        <>
            <Helmet>
                {/*OG SHARE*/}
                <meta property="og:title" content="SereniCalm"/>
                <meta property="og:description" content="An all-natural, non-habit forming stress relief supplement to bring more serenity and calm to your body and mind."/>
                <meta property="og:url" content=""/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/serenicalm-fbshare.jpg"/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:alt" content="Image of SereniCalm." />

                {/*TWITTER CARDS*/}
                <meta name="twitter:title" content="SereniCalm"/>
                <meta name="twitter:description" content="An all-natural, non-habit forming stress relief supplement to bring more serenity and calm to your body and mind."/>
                <meta name="twitter:image" content="https://staging.shop.thebettyrocker.com/assets/images/fb-share/serenicalm-fbshare.jpg"/>
                <meta name="twitter:image:alt" content="Image of SereniCalm." />
                <meta name="twitter:card" content="summary_large_image"/>
                <title>SereniCalm</title>
            </Helmet>

            {/*tab menu*/}
            <section className="pt--70 pt--md-30 pb--0">

                <Tabs active={activeTab} handleActive={handleActive}/>

                {/*tab content*/}
                <div className="tab-content">
                    {/*tab content about*/}
                    { (activeTab === "about") &&
                    <div id="about">

                        {/*What is SereniCalm?*/}
                        <section className="container container--smd section--padding-lg">
                            <h2 className="mb--40 h2--supplements mb--xxs-10">What is <span className="txt--darkblue">SereniCalm?</span></h2>

                            <div className="row">
                                <div className="col-12 col-md-6 mb--20">
                                    <p className="p--supplements p--supplements-mb">SereniCalm® (serenity + calm) is a blend of herbs and organic compounds specifically designed to support your body in a healthy response to stress.</p>
                                    <p className="p--supplements p--supplements-mb"><strong className="fw--bold">The complimentary blend of ingredients in SereniCalm is formulated to support a calm mind</strong> by supporting adrenal gland function, cellular energy generation and neurotransmitter activity (important chemical messengers that transmit signals between neurons).</p>
                                    <p className="p--supplements p--supplements-mb">With a combination of required enzyme cofactors and calming neurotransmitters, Serenicalm can help even out the “highs and lows” that may be contributing to everyday stress.</p>
                                </div>

                                <div className="col-12 col-md-6 txt--center">
                                    <img src="/images/supplements/serenicalm/stress.jpg" className="shadow-img" alt="Stress." width="469" height="355"/>
                                </div>
                            </div>
                        </section>

                        {/*What in SereniCalm?*/}
                        <section className="bg--lightblue section--padding-lg">
                            <div className="container">
                                <h2 className="mb--60 mb--xs-30i h2--supplements mb--xxs-10">What's In <span className="txt--darkblue">SereniCalm?</span></h2>

                                <div className="row flex-wrap justify-content-center">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-gaba"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">GABA</h6>
                                            <p className="txt--14 txt--xs-16">GABA (gamma-aminobutyric acid) is a chemical messenger (neurotransmitter) that helps regulate nerve impulses in your brain and nervous system. By inhibiting neural activity, GABA facilitates sleep, reduces mental and physical stress, lowers anxiety, and creates a calm state.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-niacin"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Niacin (B3)</h6>
                                            <p className="txt--14 txt--xs-16">Niacin is a cofactor in cellular energy production. Over 400 enzymes require the coenzymes synthesized from niacin for metabolic reactions - including the breakdown of protein, carbohydrates and fat. Niacin can aid in blood sugar regulation - supporting a more balanced stress response.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-pantothenic-acid"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Pantothenic Acid (B5)</h6>
                                            <p className="txt--14 txt--xs-16">Pantothenic Acid (B5) is a precursor in the biosynthesis of coenzyme A (CoA). It is involved in the Krebs cycle and the synthesis of essential fats, cholesterol, and steroid hormones. These roles may be why B5 has long been recognized as supporting proper function of the adrenal glands.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-vitamin-b6"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Vitamin B6</h6>
                                            <p className="txt--14 txt--xs-16">Vitamin B6 is needed to maintain steady blood glucose and energy levels, which may help support maintaining equanimity in stressful situations. Individuals who experience panic attacks have been shown to have low B6 levels compared to healthy controls.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-glycine"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Glycine</h6>
                                            <p className="txt--14 txt--xs-16">Glycine is the smallest amino acid, and those who experience acute or chronic stress may have an even greater demand for it. It is a well-known inhibitory neurotransmitter which may be helpful for improving sleep quality. Improved sleep can be beneficial for supporting a healthy responses to stress.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*Why Do We Need More*/}
                        <section className="container section--padding-lg pb--0i">
                            <h2 className="mb--20 h2--supplements mb--xxs-10">Why Do We Need More <span className="txt--darkblue">Serenity And Calm</span> In Our Lives?</h2>
                            <h4 className="h4--supplements txt--center mb--50i mb--xs-30i">Feeling exhausted, run down, overwhelmed and unable to lose stubborn body fat? <br className="d-none d-md-block" />These may be signs your system is over-working.</h4>

                            <div className="row align-items-center mb--30 mb--md-15">
                                <div className="col-12 col-md-6 col-lg-5 txt--center">
                                    <img src="/images/supplements/serenicalm/stress-response-system.jpg" className="shadow-img d-none d-md-block" alt="Stress Response System." width="470" height="540" />
                                </div>

                                <div className="col-12 col-md-6 col-lg-7 mb--20">
                                    <p className="p--supplements p--supplements-mb"><strong className="fw--bold">All of the body systems are impacted by stress.</strong> It disrupts the balance and harmony that enable us to function optimally, have consistent energy, reap the rewards of our healthy eating and training, and sleep well.</p>
                                    <p className="p--supplements p--supplements-mb">The fight or flight system (also known as the sympathetic nervous system) is like the gas pedal in a car: when danger is sensed (by a part of your brain called the amygdala), the pedal gets pressed down, triggering <strong className="fw--bold">your body</strong> with a burst of energy so it can respond quickly.</p>
                                    <p className="p--supplements p--supplements-mb">Once your brain activates the sympathetic nervous system, it sends signals to your adrenals, which respond by pumping the hormone epinephrine (also known as adrenaline) into the bloodstream.</p>
                                    <img src="/images/supplements/serenicalm/stress-response-system.jpg" className="shadow-img d-block d-md-none mb--20 mx--auto" alt="Stress Response System." width="470" height="540" />
                                    <p className="p--supplements p--supplements-mb">If we continue to perceive a threat, the adrenals release cortisol, causing the body to stay “revved up” or on high alert.</p>
                                    <p className="p--supplements p--supplements-mb">Once the threat has passed, cortisol levels fall. The rest and digest system (also known as the parasympathetic nervous system) is able to dampen the stress response.</p>
                                </div>
                            </div>

                        </section>

                        <section className="container container--mmd pt--0 pb--60 pb--md-30">
                            <h4 className="h4--supplements txt--center mb--50i mb--md-20">But many people are not able to dampen the stress response.</h4>

                            <div className="row align-items-center">
                                <div className="col-12 col-md-7 col-lg-8 mb--20">
                                    <p className="p--supplements p--supplements-mb">While a life threatening emergency may not be looming, <strong className="fw--bold">chronic low-level stress may keep the stress response activated</strong>, keeping stress hormones pumping through the body.</p>
                                    <p className="p--supplements p--supplements-mb">When you press the gas pedal down in a car and keep it pressed down, the engine runs at high RPM’s.</p>
                                    <p className="p--supplements p--supplements-mb">Chronic stress is like that for your body – if you don’t have a way to trigger the parasympathetic system and put on the brakes, chronic stress just keeps that system active and the engine in overdrive – contributing to many health problems.</p>
                                </div>

                                <div className="col-12 col-md-5 col-lg-4 txt--center">
                                    <img src="/images/supplements/serenicalm/speedmeter.jpg" className="shadow-img" alt="Speedmeter." width="291" height="292" />
                                </div>
                            </div>
                        </section>

                        {/*Stress impacts*/}
                        <section className="bg--lightblue section--padding-lg">
                            <div className="container">
                                <h2 className="mb--60 h2--supplements mb--xxs-10">Stress impacts many different organs and systems of <br className="d-none d-lg-block" />your body, and has far-reaching effects.</h2>

                                <div className="row row--flex m--0 diagram diagram-accordion">
                                    <div className="diagram-col">
                                        <div className="diagram-wrapper">
                                            <img src="/images/supplements/serenicalm/diagram.png" className="no-shadow radius-0" alt="Body Diagram." width="504" height="1929" />

                                            {
                                                serenicalm.map((item, index) => (
                                                    <span key={index} onClick={(e) => handlePopup(e, item)} className={`diagram-number diagram-number--${index+1} ${ activeNum === index+1 ? 'active' : '' }`}>{index+1}</span>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className="diagram-col-xl">
                                        <div className="accordion-counter-reset">

                                            {
                                                serenicalm.map((item, index) => (
                                                    <AccordionItem
                                                        key={index}
                                                        title={item.title}
                                                        counter={true}
                                                        icon="angle"
                                                        modifierClasses="accordion--blue accordion--sm"
                                                        handleActiveEl={handleActiveEl}
                                                        number={index+1}>
                                                        <p className="diagram-item__text">{item.description}</p>
                                                    </AccordionItem>
                                                ))

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*Transform Anxiety*/}
                        <section className="section--padding-lg">
                            <div className="container">
                                <h2 className="h2--supplements txt--center mb--20"><strong className="fw--bold">Transform Anxiety And Stress Into <span className="txt--darkblue">Serenity And Calm</span></strong></h2>
                                <h4 className="h4--supplements txt--center mb--30i">You can support your body’s response to stress with <br className="d-none d-lg-block"/>mindful practices and quality supplementation.</h4>

                                <div className="row">
                                    <div className="col-12 col-md-4 txt--center">
                                        <img src="/images/supplements/serenicalm/transform-mind.png" width="382" height="262" alt="Image of woman reading a book." className="mb--20 no-shadow radius-0"/>
                                        <h4 className="h4--supplements txt--center txt--uppercase txt--darkblue mb--30i"><strong className="fw--bold">Mind</strong></h4>
                                    </div>

                                    <div className="col-12 col-md-4 txt--center">
                                        <img src="/images/supplements/serenicalm/transform-body.png" width="382" height="262" alt="Image of people excercising." className="mb--20 no-shadow radius-0"/>
                                        <h4 className="h4--supplements txt--center txt--uppercase txt--darkblue mb--30i"><strong className="fw--bold">Body</strong></h4>
                                    </div>

                                    <div className="col-12 col-md-4 txt--center">
                                        <img src="/images/supplements/serenicalm/transform-spirit.png" width="382" height="262" alt="Image of woman sitting on a beach." className="mb--20 no-shadow radius-0"/>
                                        <h4 className="h4--supplements txt--center txt--uppercase txt--darkblue mb--30i"><strong className="fw--bold">Spirit</strong></h4>
                                    </div>
                                </div>
                            </div>

                            <div className="container container--mmd mb--40">
                                <p className="p--supplements">SereniCalm acts on the pathways in your body that support your stress response, and can greatly support your entire system.</p>
                                <p className="p--supplements">But by also tuning into healthy lifestyle practices that support you mind, body and spirit, you can reduce the impacts of chronic stress in today’s busy life.</p>
                                <p className="p--supplements">Cultivating mindfulness practices, supporting your energy needs with quality nutrients from whole foods, exercising regularly and spending time connecting with nature, your community and your spirit are all ways to reduce stress and bring more peace into your day to day experience.</p>
                                <p className="p--supplements"><strong className="fw--bold">Take advantage of our comprehensive resource to reducing stress and anxiety in your life through reflection activities and lifestyle practices with the Stress Less Guide - included with your first purchase of SereniCalm.</strong></p>
                            </div>
                        </section>

                        {/*Bonus*/}
                        <section className="bg--supplements-sc-bonus py--30">
                            <div className="container container--smd">
                                <div className="row no-gutters-xxxs m--0 flex-wrap align-items-center">
                                    <div className="col-xs-12 col-md-6 col-lg-4 txt--center">
                                        <img src="/images/supplements/serenicalm/stress-less-guide.png" width="304" height="366" alt="Bonus Image." className="no-shadow radius-0"/>
                                    </div>

                                    <div className="col-md-6 col-lg-8 mb--30">
                                        <h2 className="mb--10">Bonus!</h2>
                                        <h3 className="mb--10"><strong className="fw--bold">Stress Less Guide</strong></h3>
                                        <h4>How to bring more serenity and calm into your <span className="d-inline-block">everyday life</span></h4>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*quality support*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/serenicalm/servings-serenicalm.png" alt="Servings." className="no-shadow radius-0 mb--20" width="342" height="64"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                    </div>}

                    {/*tab content nutrition*/}
                    { (activeTab === "nutrition") &&
                    <div id="nutrition">
                        {/*facts*/}
                        <section className="container section--padding-xl pb--0i">
                            <div className="row">

                                <div className="col-xs-12 col-md-5 txt--center">
                                    <img src="/images/supplements/serenicalm/nutrition-table-serenicalm.jpg" className="supplements--border supplements--border-blue no-shadow radius-0 mb--50" alt="Nutrition table" width="452" height="480"/>
                                    <img src="/images/supplements/serenicalm/nutrition-bottle-serenicalm.png" className="no-shadow radius-0 mb--50" alt="SereniCalm." width="345" height="483"/>
                                </div>

                                <div className="col-xs-12 col-md-7">
                                    <h6 className="h6--supplements mb--20"><strong className="fw--bold"><span className="txt--darkblue">1.</span> 1 capsule per serving; 90 Servings (on front panel)</strong></h6>
                                    <p className="p--supplements p--supplements-mb">With 90 servings per bottle, you’ll have a 3 month’s supply of stress-reducing supplementation.</p>
                                    <p className="p--supplements mb--20">We offer SereniCalm® in capsule form because capsules are much easier for the body to absorb and digest than tablets, which often pass through the system whole. We carefully formulate these capsules using natural, plant-based binding and flowing agents (see “other ingredients”) to ensure you get the exact dose and potency promised of each ingredient in every serving.</p>

                                    <h6 className="h6--supplements mb--20"><strong className="fw--bold"><span className="txt--darkblue">2.</span> B Vitamins: B3 (Niacin), B6 (Pydidoxine HCI), B5 (Pantothenic Acid)</strong></h6>
                                    <p className="p--supplements mb--20">The adrenal glands rely on all of the B vitamins, but use vitamins B3, B5 and B6 in greater quantities in support of the stress response. In your Stress Less Guide, you’ll find references for foods high in all the B-vitamins so you can support yourself nutritionally as well.</p>

                                    <h6 className="h6--supplements mb--20"><strong className="fw--bold"><span className="txt--darkblue">3.</span> GABA - benefits and contraindications</strong></h6>
                                    <p className="p--supplements p--supplements-mb">GABA (gamma-aminobutyric acid) is a chemical messenger (neurotransmitter) that helps regulate nerve impulses in your brain and nervous system. By inhibiting neural activity, GABA facilitates sleep, reduces mental and physical stress, lowers anxiety, and creates a calm state.</p>
                                    <p className="p--supplements mb--20"><strong className="fw--bold">Due to the inclusion of GABA, this product is not recommended for use with alcohol, or for pregnant or lactating women, as well as young children, unless under the guidance of a health care practitioner.</strong></p>

                                    <h6 className="h6--supplements mb--20"><strong className="fw--bold"><span className="txt--darkblue">4.</span> Other Ingredients</strong></h6>
                                    <p className="p--supplements p--supplements-mb">Cellulose is the basic structural component of plant cell walls and is an insoluble fiber that supports gut health. We use it to encapsulate the ingredients as it provides an even release of the ingredients once ingested.</p>
                                    <p className="p--supplements p--supplements-mb">Microcrystalline cellulose is an insoluble plant fiber used in tiny amounts for structure and longevity of your product so it remains potent and useful.</p>
                                    <p className="p--supplements mb--0 mb--xs-20">Microcrystalline cellulose is an insoluble plant fiber used in tiny amounts for structure and longevity of your product so it remains potent and useful.</p>
                                </div>

                            </div>
                        </section>

                        {/*What in SereniCalm?*/}
                        <section className="bg--lightblue section--padding-lg">
                            <div className="container">
                                <h2 className="mb--60 mb--xs-30i h2--supplements mb--xxs-10">What's In <span className="txt--darkblue">SereniCalm?</span></h2>

                                <div className="row flex-wrap justify-content-center">
                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-gaba"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">GABA</h6>
                                            <p className="txt--14 txt--xs-16">GABA (gamma-aminobutyric acid) is a chemical messenger (neurotransmitter) that helps regulate nerve impulses in your brain and nervous system. By inhibiting neural activity, GABA facilitates sleep, reduces mental and physical stress, lowers anxiety, and creates a calm state.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-niacin"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Niacin (B3)</h6>
                                            <p className="txt--14 txt--xs-16">Niacin is a cofactor in cellular energy production. Over 400 enzymes require the coenzymes synthesized from niacin for metabolic reactions - including the breakdown of protein, carbohydrates and fat. Niacin can aid in blood sugar regulation - supporting a more balanced stress response.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-pantothenic-acid"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Pantothenic Acid (B5)</h6>
                                            <p className="txt--14 txt--xs-16">Pantothenic Acid (B5) is a precursor in the biosynthesis of coenzyme A (CoA). It is involved in the Krebs cycle and the synthesis of essential fats, cholesterol, and steroid hormones. These roles may be why B5 has long been recognized as supporting proper function of the adrenal glands.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-vitamin-b6"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Vitamin B6</h6>
                                            <p className="txt--14 txt--xs-16">Vitamin B6 is needed to maintain steady blood glucose and energy levels, which may help support maintaining equanimity in stressful situations. Individuals who experience panic attacks have been shown to have low B6 levels compared to healthy controls.</p>
                                        </div>
                                    </div>

                                    <div className="col--custom txt--center">
                                        <div className="mb--20 ingredients-glycine"/>
                                        <div className="col--content">
                                            <h6 className="h6--supplements mb--10 txt--darkblue">Glycine</h6>
                                            <p className="txt--14 txt--xs-16">Glycine is the smallest amino acid, and those who experience acute or chronic stress may have an even greater demand for it. It is a well-known inhibitory neurotransmitter which may be helpful for improving sleep quality. Improved sleep can be beneficial for supporting a healthy responses to stress.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*Bonus*/}
                        <section className="bg--supplements-sc-bonus py--30">
                            <div className="container container--smd">
                                <div className="row no-gutters-xxxs m--0 flex-wrap align-items-center">
                                    <div className="col-xs-12 col-md-6 col-lg-4 txt--center">
                                        <img src="/images/supplements/serenicalm/stress-less-guide.png" width="304" height="366" alt="Bonus Image." className="no-shadow radius-0"/>
                                    </div>

                                    <div className="col-md-6 col-lg-8 mb--30">
                                        <h2 className="mb--10">Bonus!</h2>
                                        <h3 className="mb--10"><strong className="fw--bold">Stress Less Guide</strong></h3>
                                        <h4>How to bring more serenity and calm into your <span className="d-inline-block">everyday life</span></h4>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*quality support*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/serenicalm/servings-serenicalm.png" alt="Servings." className="no-shadow radius-0 mb--20" width="342" height="64"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                    </div>}

                    {/*tab content quality*/}
                    { (activeTab === "quality") &&
                    <div id="quality">

                        {/*quality support*/}
                        <section className="container section--padding-xl">
                            <h2 className="mb--80 mb--md-40 h2--supplements">Quality Support for Your Whole Body, <span className="d-inline-block">by Whole Betty</span></h2>

                                <div className="row">
                                    <div className="col-xs-12 col-lg-6">
                                        <img src="/images/supplements/whole-betty-logo.png" alt="Whole Betty" className="center-md-block no-shadow radius-0 mb--30" width="427" height="68" />
                                        <p className="p--supplements">I was inspired to create the Whole Betty product line when I found myself at a loss to find the level of quality supplements I was looking for to support my own health and fitness journey.</p>
                                        <p className="p--supplements mb--20">I was determined to create whole food based products free of additives, artificial ingredients and fillers - that tasted great, were affordable, and that I looked forward to using. Join me behind the scenes inside the facility where our products are lovingly made, and find out why I use these products myself daily, share them with my friends and family, and most importantly with YOU!</p>
                                    </div>
                                    <div className="col-xs-12 col-lg-6">
                                        <div className="video bg--white video--16-9 mb--30">
                                            <iframe title="Quality support." src="https://player.vimeo.com/video/359659090" frameBorder="0"/>
                                        </div>

                                        <div className="txt--center">
                                            <img src="/images/supplements/serenicalm/servings-serenicalm.png" alt="Servings." className="no-shadow radius-0 mb--20" width="342" height="64"/>
                                          </div>
                                    </div>
                                </div>

                                <div className="supplements--box d-flex align-items-center">
                                    <img src="/images/supplements/unicorn-icon-sm.png" className="mr--xs-10 mr--20 no-shadow radius-0" alt="Unicorn." width="80" height="87"/>
                                    <p>This Unicorn Seal represents that magic that exists when we  give ourselves the love we deserve by eating, moving and thinking in ways that support our body, the people around us, and the world we live in.</p>
                                </div>
                        </section>

                        {/*Bonus*/}
                        <section className="bg--supplements-sc-bonus py--30">
                            <div className="container container--smd">
                                <div className="row no-gutters-xxxs m--0 flex-wrap align-items-center">
                                    <div className="col-xs-12 col-md-6 col-lg-4 txt--center">
                                        <img src="/images/supplements/serenicalm/stress-less-guide.png" width="304" height="366" alt="Bonus Image." className="no-shadow radius-0"/>
                                    </div>

                                    <div className="col-md-6 col-lg-8 mb--30">
                                        <h2 className="mb--10">Bonus!</h2>
                                        <h3 className="mb--10"><strong className="fw--bold">Stress Less Guide</strong></h3>
                                        <h4>How to bring more serenity and calm into your <span className="d-inline-block">everyday life</span></h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>}

                    {/*tab content faq*/}
                    { (activeTab === "faq") &&
                    <div id="faq">

                        {/*faq*/}
                        <section className="section--padding-xl bg--supplements-section" id="faq">
                            <div className="container container--xs">
                                <h2 className="h2--supplements mb--40">Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span></h2>
                                <Faqs productName='sc'/>
                            </div>
                        </section>

                        {/*Bonus*/}
                        <section className="bg--supplements-sc-bonus py--30">
                            <div className="container container--smd">
                                <div className="row no-gutters-xxxs m--0 flex-wrap align-items-center">
                                    <div className="col-xs-12 col-md-6 col-lg-4 txt--center">
                                        <img src="/images/supplements/serenicalm/stress-less-guide.png" width="304" height="366" alt="Bonus Image." className="no-shadow radius-0"/>
                                    </div>

                                    <div className="col-md-6 col-lg-8 mb--30">
                                        <h2 className="mb--10">Bonus!</h2>
                                        <h3 className="mb--10"><strong className="fw--bold">Stress Less Guide</strong></h3>
                                        <h4>How to bring more serenity and calm into your <span className="d-inline-block">everyday life</span></h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>}
                </div>
            </section>

            {/*what our rockstars are saying*/}
            <section className="bg--supplements-section section--padding-xl">
                <div className="container">
                    <h2 className="mb--80 mb--md-20 h2--supplements">What Our Rockstars are Saying About<br className="d-none d-md-block"/> <span className="d-inline-block txt--darkblue">SereniCalm…</span></h2>

                    <div className="row">

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Tricia O." src="https://player.vimeo.com/video/456715752" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“With Serenicalm, I'm able to get a full 6-8 hours of sleep, and I feel like I'm able to handle my stress more.”</i><span className="d-block">- <strong className="fw--bold">Tricia O.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Patricia B." src="https://player.vimeo.com/video/456727116" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“I feel so much lighter, like a burden has been lifted from me.”</i><span className="d-block">- <strong className="fw--bold">Patricia B.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Martha W." src="https://player.vimeo.com/video/456712243" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“When I take it in the morning I notice a big difference in how much I get triggered.”</i><span className="d-block">- <strong className="fw--bold">Martha W.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Kristen B." src="https://player.vimeo.com/video/456711536" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“The very first time I took it it was so helpful. The very first night I slept so much better - I woke up feeling so much less stressed the next day.”</i><span className="d-block">- <strong className="fw--bold">Kristen B.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Dusty O." src="https://player.vimeo.com/video/456710960" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“The benefit for me is I have less tension in my neck and shoulders and I sleep better.”</i><span className="d-block">- <strong className="fw--bold">Dusty O.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Tara M." src="https://player.vimeo.com/video/456713881" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“I don't have the tendency to get as ramped up when I'm stressed as I normally do, and I'm falling asleep and sleeping better than I used to.”</i><span className="d-block">- <strong className="fw--bold">Tara M.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Mary O." src="https://player.vimeo.com/video/457034193" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“I have experienced significant benefits since taking Serenicalm, including improving the quality of my sleep, and it was the best I have felt in my pre-menstrual days than I have in a long, long time”</i><span className="d-block">- <strong className="fw--bold">Mary O.</strong></span></p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 mb--20 mb--xs-0">
                            <div className="video bg--white video--16-9 mb--20">
                                <iframe title="Karen W." src="https://player.vimeo.com/video/457032417" frameBorder="0"/>
                            </div>

                            <div className="quote quote--blue">
                                <p><i>“Just within 2 days of using the Serenicalm, I noticed I felt great, I didn't feel nervous, I didn't feel anxious. It was so subtle - there was no feeling like you're on something. I just feel great.”</i><span className="d-block">- <strong className="fw--bold">Karen W.</strong></span></p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className={`diagram-popup ${ openPopup ? 'active' : ''}`} style={{ top: popupYPoz + 'px'}}>
                <button onClick={closePopup} className="diagram-close">
                    <img src="/images/supplements/serenicalm/icon-close.png" alt="Close popup." width="13" height="12" />
                </button>
                <div>
                    <h5 className="txt--darkblue mb--20"><strong>{popupNum}. {popupTitle}</strong></h5>
                    <p className="diagram-item__text">{popupDescription}</p>
                </div>
            </div>
        </>
    )
}
export default SereniCalm
